import {
  Box,
  FormControl,
  FormControlLabel,
  InputBase,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import "../../../styles/editWorkflow.scss";
import Spark from "../../../utils/icon/Spark";
import RightArrow from "../../../utils/icon/RightArrow";
import Clock from "../../../utils/icon/Clock";
import Select from "react-select";

const ManualTrigger = ({ Trigger, setIsExpanded }) => {
  const handleCloseDrawer = () => {
    setIsExpanded(false);
  };

  const [value, setValue] = useState("Manual");
  const [sendMessage, setSendMessage] = useState("");
  const [day, setDay] = useState({});
  const [hours, setHours] = useState({});
  const [minutes, setMinutes] = useState({});
  useEffect(() => {
    setValue(Trigger);
  });

  const days = [
    {
      label: "Sunday",
      value: "Sunday",
    },
    {
      label: "Monday",
      value: "Monday",
    },
    {
      label: "Tuesday",
      value: "Tuesday",
    },
    {
      label: "Wedneasday",
      value: "Wedneasday",
    },
    {
      label: "Thrusday",
      value: "Thrusday",
    },
    {
      label: "Friday",
      value: "Friday",
    },
    {
      label: "Saturday",
      value: "Saturday",
    },
  ];

  const hour = () => {
    const hours = [];
    for (let i = 1; i < 25; i++) {
      hours.push({
        label: i,
        value: i,
      });
    }
    return hours;
  };

  const minute = () => {
    const minutes = [];
    for (let i = 1; i < 61; i++) {
      minutes.push({
        label: i,
        value: i,
      });
    }

    return minutes;
  };

  const customStyles = {
    control: (base, { isDisabled }) => {
      return {
        ...base,
        background: "#FFFFFF",
        color: "#000",
        borderRadius: "8px",
        boxShadow: "none !important",
        minHeight: "44px",
        border: "1px solid #0D333F54",
        cursor: isDisabled ? "not-allowed" : "default",
      };
    },
    menu: (base) => ({
      ...base,
      background: "#FFFFFF",
      boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
      borderRadius: "8px",
      marginTop: 0,
      overflow: "auto",
      maxHeight: "280px",
    }),
    menuList: (base) => ({
      ...base,
      padding: "0",
      color: "#030F1C",
      fontWeight: "500",
      fontSize: "14px",
      lineHeight: "22px",
      whiteSpace: "nowrap",
      overflow: "auto",
      maxHeight: "280px",
      "&::-webkit-scrollbar": {
        width: " 4px",
      },
      "&::-webkit-scrollbar-thumb ": {
        backgroundColor: " #cccccc",
      },
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "22px",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#273167",
      "&:hover": {
        color: "#273167",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      padding: "12px 16px",
      backgroundColor: state.isSelected ? "#F2F4F7" : "#FEFEFF",
      color: state.isSelected ? "#0075FF" : "#000",
      borderBottom: "1px solid #EAECF0",
      borderRadius: "8px 8px 0px 0px",
      "&:hover": {
        backgroundColor: state.isSelected ? "#F2F4F7" : "#F2F4F7",
        color: state.isSelected ? "#0075FF" : "#005ECC",
      },
    }),
    placeholder: (base) => ({
      ...base,
      fontSize: "1em",
      color: "#000",
      fontWeight: 400,
    }),
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const ManualTrigger = () => {
    return (
      <Box className="manual-trigger">
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
          padding="10px"
        >
          <Stack flexDirection="row" alignItems="center">
            <Spark />
            <Stack flexDirection={"column"} marginLeft="10px">
              <Typography variant="subtitle">Manual Trigger</Typography>
              <Typography variant="subtitle" color="#777777">
                Manually start a workflow with custom inputs
              </Typography>
            </Stack>
          </Stack>
          <RightArrow />
        </Stack>
        {value === "Manual" && (
          <>
            <hr></hr>
            <Box padding="10px">
              <Stack flexDirection={"column"}>
                <Typography variant="subtitle">Add Input</Typography>
                <Typography variant="subtitle" color="#777777">
                  Configure the input variables for this workflow
                </Typography>
              </Stack>
              <InputBase
                className="manual-input-base"
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={sendMessage}
                onChange={(e) => setSendMessage(e.target.value)}
              />
            </Box>
          </>
        )}
      </Box>
    );
  };

  // const ScheduleTrigger = () => {
  //   return (
  //     <Box className="manual-trigger">
  //       <Stack
  //         flexDirection="row"
  //         justifyContent="space-between"
  //         alignItems="center"
  //         padding="10px"
  //       >
  //         <Stack flexDirection="row" alignItems="center">
  //           <Clock />
  //           <Stack flexDirection={"column"} marginLeft="10px">
  //             <Typography variant="subtitle">Schedule Trigger</Typography>
  //             <Typography variant="subtitle" color="#777777">
  //               Run the workflow on a schedule
  //             </Typography>
  //           </Stack>
  //         </Stack>
  //         <RightArrow />
  //       </Stack>
  //       {value === "Schedule" && (
  //         <>
  //           <hr></hr>
  //           <Box padding="10px">
  //             <Stack flexDirection={"column"}>
  //               <Typography variant="subtitle">Schedule Trigger</Typography>
  //               <Typography variant="subtitle" color="#777777">
  //                 The Schedule for this workflow to run
  //               </Typography>
  //             </Stack>
  //             <Box display="flex" alignItems="center" margin="10px 0">
  //               <Typography variant="subtitle" margin="10px">
  //                 Every
  //               </Typography>
  //               <Select
  //                 sx={{ width: "100% !important" }}
  //                 value={day}
  //                 styles={customStyles}
  //                 name="days"
  //                 options={days}
  //                 className="basic-multi-select assistant-chat-select"
  //                 onChange={(e) => {
  //                   setDay(e);
  //                 }}
  //                 classNamePrefix="select"
  //                 placeholder={"Select Assitant..."}
  //                 components={{
  //                   IndicatorSeparator: () => null,
  //                 }}
  //               />
  //               <Typography variant="subtitle" margin="10px">
  //                 To
  //               </Typography>
  //               <Select
  //                 sx={{ width: "100% !important" }}
  //                 value={hours}
  //                 styles={customStyles}
  //                 name="hour"
  //                 options={hour()}
  //                 className="basic-multi-select assistant-chat-select"
  //                 onChange={(e) => {
  //                   setHours(e);
  //                 }}
  //                 classNamePrefix="select"
  //                 placeholder={"Select Assitant..."}
  //                 components={{
  //                   IndicatorSeparator: () => null,
  //                 }}
  //               />
  //               <Typography variant="subtitle" margin="10px">
  //                 :
  //               </Typography>
  //               <Select
  //                 sx={{ width: "100% !important" }}
  //                 value={minutes}
  //                 styles={customStyles}
  //                 name="minute"
  //                 options={minute()}
  //                 className="basic-multi-select assistant-chat-select"
  //                 onChange={(e) => {
  //                   setMinutes(e);
  //                 }}
  //                 classNamePrefix="select"
  //                 placeholder={"Select Assitant..."}
  //                 components={{
  //                   IndicatorSeparator: () => null,
  //                 }}
  //               />
  //             </Box>
  //           </Box>
  //         </>
  //       )}
  //     </Box>
  //   );
  // };

  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #D8D7D7",
        borderRadius: "12px",
        padding: "20px",
        height: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6" fontWeight="600" color="#036185">
            Change Trigger
          </Typography>
          <Typography variant="subtitle" color="#777777">
            Learn how to use: Run the workflow on a schedule
          </Typography>
        </Box>

        <Box
          className="edit-wf-close-btn"
          onClick={handleCloseDrawer}
          sx={{ cursor: "pointer" }}
        >
          <RiCloseLine fill="#000" />
        </Box>
      </Stack>
      <hr></hr>

      <Typography variant="h6" className="Workflow-Template-text mt-4">
        General
      </Typography>

      <FormControl>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          className="manual-trigger-details"
          value={value}
          onChange={handleChange}
        >
          <Box display="flex">
            <FormControlLabel value="manual" control={<Radio />} />
            {ManualTrigger()}
          </Box>
          {/* <Box display="flex">
            <FormControlLabel value="schedule" control={<Radio />} />
            {ScheduleTrigger()}
          </Box> */}
        </RadioGroup>
      </FormControl>
    </Paper>
  );
};

export default ManualTrigger;
