import React from "react";
import Skeleton from "react-loading-skeleton";
import "../../../styles/skeleton.css";

const SkeletonCard = () => {
  return (
    <>
      <section style={{ width: "100%" }}>
        <div className="skeleton-list">
          {Array(6)
            .fill()
            .map((item, index) => (
              <div
                key={index}
                style={{ width: "100%", display: "flex" }}
                className={`sleleton-chat ${
                  index % 2 === 0 ? "left" : "right"
                }`}
              >
                <div
                  className={`card-skeleton ${
                    index % 2 === 0 ? "left" : "right"
                  }`}
                  key={index}
                >
                  <Skeleton height={180} width={`80%`} count={1} />
                </div>
              </div>
            ))}
        </div>
      </section>
    </>
  );
};
export default SkeletonCard;
