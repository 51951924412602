import React from "react";
import KBselection from "./SelectKb";
import { Button, DialogActions, DialogContent } from "@mui/material";
import CustomizedDialog from "../Dialog";

const AssistantSelectKB = ({ open, handleClose, formik }) => {
  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Knowledge Base selection"}
        open={open}
        handleClose={handleClose}
      >
        <DialogContent>
          <KBselection id={1} formik={formik} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="primary-button"
            onClick={handleClose}
          >
            Save
          </Button>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default AssistantSelectKB;
