import { Box, Button, Card, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import RightIcon from "../../utils/icon/RightIcon";
import { useNavigate } from "react-router-dom";
import "../../styles/billings.scss";
import AOS from "aos";
import "aos/dist/aos.css";
import { RxCross2 } from "react-icons/rx";

const Pricingcard = ({
  subPlan,
  value,
  location,
  formik,
  setUpgradePlanDialog,
  setPlanId,
  plan_id,
  pageName,
}) => {
  const Navigate = useNavigate();

  useEffect(() => {
    AOS.init();
  }, []);

  const handleSubscription = (item) => {
    Navigate("/subscription", {
      state: {
        id: item?.id,
      },
    });
  };

  const handleSubscriptionbilling = (item) => {
    setUpgradePlanDialog(true);
    setPlanId(item.id);
  };

  const handleCardChange = (id) => {
    formik?.setFieldValue("planId", id);
  };

  return subPlan[value]?.map((item, i) => {
    return (
      <Grid
        item
        xs={12}
        sm={12}
        md={6}
        lg={4}
        key={i}
        display={"flex"}
        alignItems="center"
        justifyContent="center"
      >
        <Card
          className={`pricing-card ${
            location !== "/subscription" && plan_id === item?.id
              ? "selected-plan-billing"
              : ""
          } ${
            location === "/subscription" && plan_id === item?.id
              ? "selected-plan-billing-subscription"
              : ""
          } ${
            pageName === "upgradesubscription" && item.id === "free"
              ? "disable-btn-subscription-page"
              : ""
          }`}
          onClick={() => {
            handleCardChange(item?.id);
          }}
        >
          <Box display={"flex"} paddingBottom="20px">
            <img
              src={item?.Icon}
              className="work-card-img"
              alt="Input Processing"
              data-aos="zoom-in"
              data-aos-duration="600"
            />
            <Box paddingLeft="10px">
              <Typography
                variant="subtitle1"
                className="pricing-card-title"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                {item.product}
              </Typography>
              {/* <Typography
                variant="subtitle1"
                className="pricing-card-subtitle"
                data-aos="fade-up"
                data-aos-duration="600"
              >
                {item.description}
              </Typography> */}
            </Box>
          </Box>

          <Typography
            variant="subtitle1"
            className="pricing-card-subtitle pricing-card-main-subtitle"
          >
            {item.description}
          </Typography>
          <Typography
            fontWeight="500"
            fontSize="20px !important"
            className="pricing-card-subtitle"
          >
            <span className="pricing-card-price">${item?.amount}</span> /
            {value === 0 ? "Monthly" : "Annually"}
          </Typography>
          <Typography
            variant="subtitle1"
            fontSize="18px"
            fontWeight="700"
            color="#0D333F"
            className="included"
          >
            What’s included
          </Typography>
          <ul style={{ listStyle: "none", padding: "0" }}>
            {item?.Features?.map((feature, i) => {
              return (
                <span
                  className="pricing-card-svg"
                  style={{ display: "flex", padding: "6px 0" }}
                  key={i}
                >
                  {feature.limit === "No" ? (
                    <RxCross2
                      onMouseOver={({ target }) =>
                        (target.style.color = "white")
                      }
                      className="no-arrow-pricing-card"
                      size={24}
                      style={{
                        minWidth: "27px !important",
                        minHeight: "27px",
                        background: "#CA9688",
                        borderRadius: "50%",
                        padding: " 4px",
                        color: " white",
                      }}
                    />
                  ) : (
                    <RightIcon
                      size={36}
                      style={{ minWidth: "27px !important", minHeight: "27px" }}
                    />
                  )}
                  <li
                    className="pricing-card-subtitle"
                    style={{
                      paddingLeft: "15px",
                      // fontSize: "18px",
                    }}
                  >
                    {feature.limit !== "Yes" &&
                      feature.limit !== "No" &&
                      feature.limit}{" "}
                    {feature.name}
                  </li>
                </span>
              );
            })}
          </ul>
          {!location && (
            <Button
              className={` pricing-card-Btn ${
                plan_id === item.id ? "selected-plan-billing" : ""
              } ${
                pageName === "upgradesubscription" && item.id === "free"
                  ? "disable-btn-subscription-page"
                  : ""
              }`}
              disabled={
                plan_id === item.id ||
                (pageName === "upgradesubscription" && item.id === "free")
              }
              onClick={() => {
                pageName === "pricing"
                  ? handleSubscription(item)
                  : handleSubscriptionbilling(item);
              }}
            >
              {pageName === "pricing"
                ? "Get Started"
                : plan_id === item.id
                ? "Currunt Plan"
                : "Change Plan"}
            </Button>
          )}
        </Card>
      </Grid>
    );
  });
};

export default Pricingcard;
