import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  DialogContent,
  DialogActions,
  ToggleButtonGroup,
  ToggleButton,
} from "@mui/material";
import "../../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { COUNTRIES } from "../../../../constants/country.constant";
import {
  createWorkspaceThunk,
  getOneWorkspaceThunk,
  updateWorkspaceThunk,
} from "../../../../redux/slices/tenant.slice";
import CustomizedDialog from "../../Dialog";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const CreateWorkspace = (
  {
    fetchAllWorkspace,
    editOpen,
    setEditOpen,
    viewOpen,
    setViewOpen,
    isEdit,
    setIsEdit,
  },
  props
) => {
  const [initialValues, setInitialValues] = useState({
    branch_name: "",
    address1: "",
    address2: "",
    city: "",
    zipcode: "",
    state: "",
    country: "",
    description: "",
    // kb: [],
    status: true,
  });

  useEffect(() => {
    if (viewOpen) {
      getWorkspaceValues();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // fetchAllKb();
    if (viewOpen) {
      getWorkspaceValues();
    }
    // eslint-disable-next-line
  }, [viewOpen]);

  // const fetchAllKb = async () => {
  //     try {
  //         let response = await dispatch(fetchAllKbThunk());
  //         if (response.payload && response.payload.status) {
  //             setKb(response.payload.data);
  //         } else {
  //             await dispatch(
  //                 setSnackbar({
  //                     open: true,
  //                     severity: "error",
  //                     message: response.payload.message || "Internal server error",
  //                 })
  //             );
  //         }
  //     } catch (error) {
  //         await dispatch(
  //             setSnackbar({
  //                 open: true,
  //                 severity: "error",
  //                 message: error || "Internal server error",
  //             })
  //         );
  //     }
  // };

  const ScrollBars = {
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  };

  const getWorkspaceValues = async () => {
    let response = await dispatch(getOneWorkspaceThunk(viewOpen));
    const { payload } = response;
    if (payload && payload?.status) {
      const data = {
        id: payload?.data?.id,
        branch_name: payload?.data?.branch_name,
        address1: payload?.data?.address1,
        address2: payload?.data?.address2,
        country: payload?.data?.country,
        state: payload?.data?.state,
        city: payload?.data?.city,
        zipcode: payload?.data?.zipcode,
        description: payload?.data?.description,
        status: payload?.data?.status,
        // kb: payload?.data?.kb,
      };
      setInitialValues(data);
      formik.setValues(data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      branch_name: yup.string().trim().required("Name is required"),
      description: yup.string().optional(),
      address1: yup.string().required("Address1 is required"),
      address2: yup.string().optional(),
      zipcode: yup
        .string()
        .min(4, "Minimum 4 digits are required")
        .max(6, "Maximum 6 digits are required")
        .required("Pin code is required"),
      city: yup.string().required("City is required"),
      state: yup.string().required("State is required"),
      country: yup.string().required("Country is required"),
      // kb: yup.array().required("Knowledge Base is required"),
      status: yup.boolean().required("Status is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        let response = await dispatch(
          isEdit ? updateWorkspaceThunk(values) : createWorkspaceThunk(values)
        );
        if (response.payload && response.payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: isEdit
                ? "Workspace Successfully Updated"
                : "Workspace Successfully Added",
            })
          );
          setEditOpen(false);
          setIsEdit(false);
          setViewOpen(false);
          fetchAllWorkspace();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: response.payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });
  const handleCloseDrawer = () => {
    formik.resetForm();
    setEditOpen(false);
    setViewOpen(false);
  };
  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={viewOpen ? "Workspace Detail" : "Create Workspace"}
        open={editOpen || viewOpen}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent sx={ScrollBars}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Name"
                    size="small"
                    name="branch_name"
                    disabled={!isEdit && viewOpen}
                    {...formik.getFieldProps("branch_name")}
                  />
                  {formik.touched.branch_name && formik.errors.branch_name && (
                    <div className="form-error-message">
                      {formik.errors.branch_name}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Address1
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Address1"
                    size="small"
                    name="address1"
                    disabled={!isEdit && viewOpen}
                    {...formik.getFieldProps("address1")}
                  />
                  {formik.touched.address1 && formik.errors.address1 && (
                    <div className="form-error-message">
                      {formik.errors.address1}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Address2
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Address2"
                    size="small"
                    name="address2"
                    disabled={!isEdit && viewOpen}
                    {...formik.getFieldProps("address2")}
                  />
                  {formik.touched.address2 && formik.errors.address2 && (
                    <div className="form-error-message">
                      {formik.errors.address2}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    City
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter City"
                    size="small"
                    name="city"
                    disabled={!isEdit && viewOpen}
                    {...formik.getFieldProps("city")}
                  />
                  {formik.touched.city && formik.errors.city && (
                    <div className="form-error-message">
                      {formik.errors.city}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    State
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter State"
                    size="small"
                    name="state"
                    disabled={!isEdit && viewOpen}
                    {...formik.getFieldProps("state")}
                  />
                  {formik.touched.state && formik.errors.state && (
                    <div className="form-error-message">
                      {formik.errors.state}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Pin Code
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Pin Code"
                    size="small"
                    name="zipcode"
                    disabled={!isEdit && viewOpen}
                    {...formik.getFieldProps("zipcode")}
                  />
                  {formik.touched.zipcode && formik.errors.zipcode && (
                    <div className="form-error-message">
                      {formik.errors.zipcode}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Country
                  </Typography>
                  <Autocomplete
                    id="country-select"
                    fullWidth
                    options={COUNTRIES}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    disabled={!isEdit && viewOpen}
                    value={
                      COUNTRIES.find(
                        (country) => country.label === formik.values.country
                      ) || null
                    }
                    onChange={(event, newValue) => {
                      formik.setFieldValue(
                        "country",
                        newValue ? newValue.label : ""
                      ); // set the country value
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        <img
                          loading="lazy"
                          width="20"
                          srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                          src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                          alt=""
                        />
                        {option.label} ({option.code}) {/*+{option.phone}*/}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password", // disable autocomplete and autofill
                        }}
                      />
                    )}
                  />
                  {formik.touched.country && formik.errors.country && (
                    <div className="form-error-message">
                      {formik.errors.country}
                    </div>
                  )}
                </Box>
              </Grid>
              {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box variant="div" component="div" sx={BootstrapInput}>
                                    <Typography variant="body1" component="label">
                                        Knowledge Base
                                    </Typography>
                                    <Select
                                        isMulti
                                        name="kb"
                                        options={kb.map((option) => ({
                                            value: option.kbId,
                                            label: option.kbName,
                                        }))}
                                        className="basic-multi-select"
                                        value={kb
                                            .filter((option) =>
                                                formik.values.kb.includes(option.kbId)
                                            )
                                            .map((option) => ({
                                                value: option.kbId,
                                                label: option.kbName,
                                            }))}
                                        onChange={(selectedOptions) => {
                                            formik.setFieldValue(
                                                "kb",
                                                selectedOptions
                                                    ? selectedOptions.map((option) => option.value)
                                                    : []
                                            );
                                        }}
                                        classNamePrefix="select"
                                        placeholder={"Select Knowledge Base(s)..."}
                                        components={{
                                            IndicatorSeparator: null,
                                        }}
                                    />
                                    {formik.touched.user_role && formik.errors.user_role && (
                                        <div className="form-error-message">
                                            {formik.errors.user_role}
                                        </div>
                                    )}
                                </Box>
                            </Grid> */}
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Description"
                    size="small"
                    name="description"
                    {...formik.getFieldProps("description")}
                    disabled={!isEdit && viewOpen}
                    rows={2}
                    multiline
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12}>
                <Box variant="div" component="div">
                  <ToggleButtonGroup
                    className="active-button"
                    value={formik.values.status}
                    name="status"
                    exclusive
                    disabled={!isEdit && viewOpen}
                    onChange={(event, flag) => {
                      formik.setFieldValue("status", flag);
                    }}
                    aria-label="text alignment"
                    // {...formik.getFieldProps('flag')}
                  >
                    <ToggleButton
                      value={true}
                      name="status"
                      aria-label="left aligned"
                    >
                      Active
                    </ToggleButton>
                    <ToggleButton
                      value={false}
                      name="status"
                      aria-label="centered"
                    >
                      Inactive
                    </ToggleButton>
                  </ToggleButtonGroup>
                  {formik.touched.status && formik.errors.status && (
                    <div className="form-error-message">
                      {formik.errors.status}
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {(!viewOpen || isEdit) && (
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateWorkspace;
