import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { publicRoutes, authRoutes, privateRoutes } from "./routerLists";
import PageNotFound from "./PageNotFound";
import { Box, Toolbar } from "@mui/material";
import Dashboard from "../components/common/dashboard";
import { setSnackbar } from "../redux/slices/common.slice";
import { useDispatch, useSelector } from "react-redux";
import SnackbarComponent from "../components/common/SnackbarComponent";
import RouteProtection from "./RouteProtection";
import NewNavbar from "../components/layout/newNavbar";
import NewFooter from "../components/layout/newFooter";
const RootRouter = () => {
  const common = useSelector((state) => state.common);
  const dispatch = useDispatch();
  let snackbar = common?.snackbar;
  const handleSnackbarClose = async () => {
    await dispatch(
      setSnackbar({
        open: false,
        severity: "",
        message: "",
      })
    );
  };

  return (
    <>
      <Routes>
        {/*------- Public Routes -------*/}
        {publicRoutes.map(({ component, path }, key) => (
          <Route
            path={path}
            element={
              <>
                <NewNavbar />
                <Box component="main" sx={{ pt: 3, pb: 0 }}>
                  <Toolbar />
                  {component}
                </Box>
                <NewFooter />
              </>
            }
            key={key}
          />
        ))}
        <Route path="/*" element={<Navigate to="/404" />} />
        <Route path="/404" element={<PageNotFound />} />

        {/*------- Auth Routes -------*/}
        {authRoutes.map(({ component, path }, key) => (
          <Route path={path} element={<>{component}</>} key={key} />
        ))}

        {/*------- Dashboard Routes -------*/}
        {privateRoutes.map(({ component, path }, key) => (
          <Route
            path={path}
            element={
              <RouteProtection>
                <Dashboard>{component}</Dashboard>
              </RouteProtection>
            }
            key={key}
          />
        ))}
      </Routes>
      <SnackbarComponent
        open={snackbar?.open}
        onClose={handleSnackbarClose}
        severity={snackbar?.severity}
        message={snackbar?.message}
      />
    </>
  );
};
export default RootRouter;
