import {
  Box,
  Grid,
  Tab,
  Tabs,
  Typography,
  Button,
  DialogActions,
  CircularProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Enterprice from "../../../../assets/icons/enterprise.svg";
import ProSub from "../../../../assets/icons/proSub.svg";
import BasicSub from "../../../../assets/icons/BasicSub.svg";
import { useLocation, useNavigate } from "react-router-dom";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import {
  getSubscriptionPlanThunk,
  upgradeSubscriptionPlanThunk,
} from "../../../../redux/slices/subscription.slice";
import "../../../../styles/billings.scss";
import CustomizedDialog from "../../Dialog";
import Pricingcard from "../../Pricingcard";
import { FaArrowLeft } from "react-icons/fa6";
import { PAGE_URL } from "../../../../constants/pageUrl.constant";

const SubscriptionBilling = () => {
  const [subPlan, setSubPlan] = useState([]);
  const [value, setValue] = useState(0);
  const [upgradePlanDialog, setUpgradePlanDialog] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const location = useLocation();
  const [planId, setPlanId] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  const getData = (payload, timeframe) => {
    return payload?.data
      .map((item) => {
        return [
          ...item?.plans?.filter((ele) => ele?.interval === timeframe),
        ].map((ele) => ({
          ...ele,
          planId: item?.id,
          description: item?.description,
          Icon:
            ele?.product === "Enterprise"
              ? Enterprice
              : ele?.product === "Basic"
              ? BasicSub
              : ProSub,
              Features: ele?.functions,
        
        }));
      })
      .flat();
  };
  const fetchSubscriptionPlan = async () => {
    let response = await dispatch(getSubscriptionPlanThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      const monthlyData = getData(payload, "month");
      const yearlyData = getData(payload, "year");
      setSubPlan([monthlyData, yearlyData]);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const handleUpgradePlan = async () => {
    setUploadLoading(true);
    let response = await dispatch(
      upgradeSubscriptionPlanThunk({
        plan_id: planId,
      })
    );
    if (response?.payload?.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Updated successfully",
        })
      );
      setUpgradePlanDialog(false);
      if (response?.payload?.link) {
        window.location.href = response?.payload?.link;
      } else {
        navigate(PAGE_URL.BILLINGS_AND_PLANS);
      }
      //   handleFileUploadClose();
      //   fetchChatbotDetail();
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response?.payload?.message || "Internal server error",
        })
      );
    setUploadLoading(false);
  };

  useEffect(() => {
    fetchSubscriptionPlan();
    // eslint-disable-next-line
  }, []);

  const handleFileUploadClose = async () => {
    setUpgradePlanDialog(false);
  };

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      width={"100%"}
      margin={"auto"}
    >
      <Box sx={{ width: "100%" }}>
        <Box>
          <Button
            onClick={() => {
              navigate(PAGE_URL.BILLINGS_AND_PLANS);
            }}
            style={{ margin: "15px" }}
          >
            <FaArrowLeft size={20} color="#036185" />
          </Button>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            justifyContent="center"
            className="tab_buttons"
          >
            <Tab label="Monthly" className="monthly_plan" {...a11yProps(0)} />
            <Tab label="Annually" className="annual_plan" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Pricingcard
              pageName={"upgradesubscription"}
              value={value}
              subPlan={subPlan}
              setUpgradePlanDialog={setUpgradePlanDialog}
              setPlanId={setPlanId}
              // plan_id={planId}
              plan_id={location?.state.plan_id}
            />
            {/* {PricingCard(value)} */}
          </Grid>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="center"
          >
            <Pricingcard
              pageName={"upgradesubscription"}
              value={value}
              subPlan={subPlan}
              setUpgradePlanDialog={setUpgradePlanDialog}
              setPlanId={setPlanId}
              plan_id={location?.state.plan_id}
            />
            {/* {PricingCard(value)} */}
          </Grid>
        </CustomTabPanel>

        {upgradePlanDialog && (
          <CustomizedDialog
            className="modal-scroll"
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            title={"Change subscription"}
            open={upgradePlanDialog}
            handleClose={() => {
              handleFileUploadClose();
            }}
          >
            <Box padding={"20px 20px 0 20px"}>
              Are you absolutely certain you want to change your plan? <br /> If
              you choose to proceed, the new plan will take effect immediately,
              and any unused amount will be deducted from the selected plan.
            </Box>
            <DialogActions>
              <Box
                className="modal-footers"
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  cursor: "pointer",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={handleFileUploadClose}
                  sx={{ margin: "15px !important" }}
                  className="secondary-button"
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleUpgradePlan}
                  className="primary-button"
                  variant="contained"
                  disabled={uploadLoading}
                >
                  {uploadLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Yes, change it
                </Button>
              </Box>
            </DialogActions>
          </CustomizedDialog>
        )}
      </Box>
    </Grid>
  );
};

export default SubscriptionBilling;
