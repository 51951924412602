import {
  Box,
  Button,
  IconButton,
  Paper,
  InputBase,
  Stack,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import SearchIcon from "@mui/icons-material/Search";
import DataGridTable from "../../dataGrid";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { getAllConfigureAction } from "../../../../redux/slices/tenant.slice";
import CreateConfigureAction from "./CreateConfigureAction";
import "../../../../styles/settings.scss";
import DeleteConfigureAction from "./DeleteConfigureAction";
import moment from "moment";
import Pencil from "../../../../assets/icons/pencil.svg";
import EyeIcon from "../../../../assets/icons/eye.svg";
import DeleteIcon from "../../../../assets/icons/deleteRed.svg";

const ConfigureAction = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [drawerState, setDrawerState] = useState("create");
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isDelete, setIsDelete] = useState(false);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [configureAction, setConfigureAction] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();
  const fetchAllConfigureAction = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllConfigureAction({
        page_number: paginationModel.page + 1,
        page_size: paginationModel.pageSize,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setConfigureAction(payload?.data);
      setTotalRow(payload?.total_object);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  useEffect(
    () => {
      fetchAllConfigureAction();
    }, // eslint-disable-next-line
    [paginationModel]
  );

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="View">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("view");
                setSelectedRowData(params.row);
              }}
            >
              <img
                alt="View Details"
                src={EyeIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Edit">
            <IconButton
              sx={{ color: "#000000", padding: "0px" }}
              onClick={() => {
                setIsOpenDrawer(true);
                setDrawerState("edit");
                setSelectedRowData(params.row);
              }}
            >
              <img
                alt="Edit Details"
                src={Pencil}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setIsDelete(true);
                setSelectedRowData(params.row);
              }}
            >
              <img
                alt="Delete Details"
                src={DeleteIcon}
                width={24}
                height={24}
                className="actionIcon"
                cursor={"pointer"}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  const columns = [
    { field: "name", headerName: "Name", flex: 1, minWidth: 150 },
    {
      field: "description",
      headerName: "Description",
      minWidth: 350,
    },
    {
      field: "action",
      headerName: "Configure Action",
      minWidth: 300,
    },
    {
      field: "created_at",
      headerName: "Created At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
    },
    {
      field: "moreaction",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];

  return (
    <>
      <Box className="content-header justify-content-end kb-list-header mb-4">
        <Box variant="div" component="div" className="content-header-right">
          <Paper
            elevation={0}
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: "50px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              setIsOpenDrawer(true);
            }}
          >
            CONFIGURE ACTIONS
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={
            searchTerm
              ? configureAction.filter((item) =>
                  item.name.toLowerCase().includes(searchTerm.toLowerCase())
                )
              : configureAction
          }
          columns={columns}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalRow={totalRow}
        />
      </Box>
      {isOpenDrawer && (
        <CreateConfigureAction
          isOpenDrawer={isOpenDrawer}
          drawerState={drawerState}
          setDrawerState={setDrawerState}
          setIsOpenDrawer={setIsOpenDrawer}
          fetchAllConfigureAction={fetchAllConfigureAction}
          selectedRowData={selectedRowData}
          setSelectedRowData={setSelectedRowData}
        />
      )}

      {isDelete && (
        <DeleteConfigureAction
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selectedRowData}
          isLoading={isLoading}
          fetchAllConfigureAction={fetchAllConfigureAction}
        />
      )}
    </>
  );
};

export default ConfigureAction;
