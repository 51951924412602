import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Stack,
  Paper,
  InputBase,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import DataGridTable from "../dataGrid";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { getAllThreadThunk } from "../../../redux/slices/assistants.slice";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import DeleteThread from "./DeleteThread";
import chatIcon from "../../../assets/icons/chat.svg";
import deleteIcon from "../../../assets/icons/delete.svg";
import { CiSearch } from "react-icons/ci";

const AssitantChatHistory = () => {
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [totalRow, setTotalRow] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [chatList, setChatList] = useState([]);
  const dispatch = useDispatch();

  const fetchAllChatHistory = async () => {
    setIsLoading(true);
    let response = await dispatch(
      getAllThreadThunk({
        page_number: paginationModel?.page + 1,
        page_size: paginationModel?.pageSize,
        search: searchTerm,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      setChatList(payload?.data ?? []);
      setTotalRow(payload?.total_object);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setIsLoading(false);
  };

  const Navigate = useNavigate();
  useEffect(
    () => {
      fetchAllChatHistory();
    }, // eslint-disable-next-line
    [paginationModel, searchTerm]
  );

  function ActionCell(params) {
    return (
      <>
        <Stack direction="row" spacing={0} alignItems="center">
          <Tooltip title="Open chat">
            <IconButton
              onClick={() => {
                Navigate(PAGE_URL.ASSITANT_CHAT, {
                  state: {
                    thread_name: params?.row?.name,
                    assistant_id: params?.row?.assistant_name?.id ?? null,
                    thread_id: params?.row?.id,
                  },
                });
              }}
              sx={{ color: "#000000" }}
            >
              <img alt="chat-Icon" src={chatIcon} width={24} height={24} />
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete">
            <IconButton
              sx={{ color: "#000000" }}
              onClick={() => {
                setIsDelete(true);
                setSelectedRowData(params.row);
              }}
            >
              <img
                alt="delete-Icon"
                cursor={"pointer"}
                className="actionIcon"
                src={deleteIcon}
                width={24}
                height={24}
              />
            </IconButton>
          </Tooltip>
        </Stack>
      </>
    );
  }

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }
  function assistantName(params) {
    return params?.value?.name ?? "No Assistant";
  }

  const columns = [
    { field: "name", headerName: "Name", flex: 1, minWidth: 450 },
    {
      field: "assistant_name",
      headerName: "Assistant Name",
      flex: 1,
      renderCell: assistantName,
      minWidth: 200,
    },
    {
      field: "created_at",
      headerName: "Created At",
      renderCell: DateCell,
      minWidth: 150,
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      renderCell: DateCell,
      minWidth: 150,
    },
    {
      field: "actions",
      headerName: "Action",
      flex: 1,
      renderCell: ActionCell,
      minWidth: 120,
    },
  ];

  return (
    <>
      <Box className="content-header kb-list-header mb-4">
        <div></div>
        <Box variant="div" component="div" className="content-header-right">
          <Paper
            elevation={0}
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              maxWidth: "199px",
              borderRadius: "50px",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <CiSearch color="#9F9F9F" />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              Navigate(PAGE_URL.ASSITANT_CHAT);
            }}
          >
            New Chat
          </Button>
        </Box>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <DataGridTable
          data={chatList}
          columns={columns}
          setPaginationModel={setPaginationModel}
          isLoading={isLoading}
          paginationModel={paginationModel}
          totalRow={totalRow}
        />
      </Box>
      {isDelete && (
        <DeleteThread
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selectedRowData}
          isLoading={isLoading}
          fetchAllChatHistory={fetchAllChatHistory}
        />
      )}
    </>
  );
};

export default AssitantChatHistory;
