import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Menu,
  MenuItem,
  CircularProgress,
  Box,
  Stack,
} from "@mui/material";
import { GridSearchIcon } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { RiAddCircleLine } from "react-icons/ri";
import CreateIntegration from "./createIntegration";
import {
  fetchAllConfigurationThunk,
  removeIntegrationThunk,
} from "../../../../redux/slices/knowledgeBase.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import { CONFIG_IMG } from "../../../../constants/common.constant";
import { FaEllipsisVertical } from "react-icons/fa6";
import "../../../../styles/settings.scss";
import DeleteConfirmation from "../../DeleteConfirmation";
import { DiDatabase } from "react-icons/di";

const IntegrationList = () => {
  const [editOpen, setEditOpen] = useState(false);
  const [configs, setConfigs] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleteId, setDeleteId] = React.useState();
  const [selectedId, setSelectedId] = React.useState();
  const open = Boolean(anchorEl);
  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId();
  };

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const fetchAllConfiguration = async () => {
    setIsLoading(true);
    let response = await dispatch(fetchAllConfigurationThunk());
    if (response.payload.status) {
      setConfigs(response.payload.data);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response.payload.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };
  useEffect(() => {
    fetchAllConfiguration();
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("code");

    if (accessToken) {
      // Call your method here with the access token
      setEditOpen(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleDeleteConfig = async () => {
    let response = await dispatch(removeIntegrationThunk({ id: deleteId }));
    if (response.payload && response.payload.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Successfully Removed",
        })
      );
      fetchAllConfiguration();
      setDeleteId();
      handleClose();
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response.payload.message || "Internal server error",
        })
      );
    }
  };

  return (
    <>
      <Box className="content-header kb-list-header mb-4">
        <div></div>
        <Box variant="div" component="div" className="content-header-right">
          <Paper
            elevation={0}
            // component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "auto",
              borderRadius: "50px",
              justifyContent: "space-between",
            }}
          >
            <InputBase
              sx={{ pl: 1 }}
              placeholder="Search"
              inputProps={{ "aria-label": "search" }}
              // value={searchTerm}
              // onChange={(e) => setSearchTerm(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <GridSearchIcon />
            </IconButton>
          </Paper>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            endIcon={<RiAddCircleLine />}
            disableRipple
            onClick={() => {
              setEditOpen(true);
            }}
          >
            Create Integration
          </Button>
        </Box>
      </Box>
      <Grid container spacing={3}>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              mx: "auto",
            }}
          >
            <CircularProgress />
          </Box>
        ) : configs.length > 0 ? (
          configs.map((config) => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
              <Card
                className="h-100"
                sx={{
                  boxShadow: "0 0 10px rgba(0, 0, 0, 0.1) !important",
                  borderRadius: "8px",
                }}
              >
                <CardActions
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <IconButton
                    sx={{ p: 0, m: 0 }}
                    aria-label="delete"
                    onClick={(e) => {
                      handleClick(e, config.id);
                    }}
                  >
                    <FaEllipsisVertical size={16} />
                  </IconButton>
                  <Menu
                    id="demo-positioned-menu"
                    aria-labelledby="demo-positioned-button"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    className="option-menu"
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "left",
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setDeleteId(selectedId);
                      }}
                    >
                      Delete
                    </MenuItem>
                  </Menu>
                </CardActions>
                <CardMedia
                  component="img"
                  image={CONFIG_IMG[config.type]}
                  alt="Notion Logo"
                  sx={{ p: 1, objectFit: "contain", height: "100px" }}
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "16px",
                    }}
                    component="div"
                  >
                    {config.name}
                  </Typography>
                  <Typography
                    variant="p"
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      fontSize: "12px",
                    }}
                  >
                    {config.description}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <Box
            className="no-data-found"
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: 1,
            }}
          >
            <Stack spacing={0} justifyContent="center" alignItems="center">
              <DiDatabase size={50} />
              <span>No data found</span>
            </Stack>
          </Box>
        )}
      </Grid>
      {editOpen && (
        <CreateIntegration
          editOpen={editOpen}
          setEditOpen={setEditOpen}
          fetchAllConfiguration={fetchAllConfiguration}
        />
      )}
      {deleteId && (
        <DeleteConfirmation
          open={deleteId}
          onClose={() => setDeleteId()}
          onDelete={handleDeleteConfig}
        />
      )}
    </>
  );
};

export default IntegrationList;
