import React from 'react';
import {
    Paper,
    Typography,
    Avatar,
    InputBase,
    IconButton,
    Box,
} from '@mui/material';
import { IoCloseSharp } from "react-icons/io5";
import SendIcon from '@mui/icons-material/Send';

const ChatBot = ({ chatbotDetail, handleChatBotToggle }) => {
    const Message = ({ text, sender }) => {
        const isSender = sender;
        const messageStyle = {
            padding: '8px',
            margin: '8px',
            borderRadius: '5px',
            maxWidth: '70%',
            background: isSender ? chatbotDetail?.color_schema?.user_message_background_color : chatbotDetail?.color_schema?.chatbot_message_background_color, // Adjust the background color as needed
            color: isSender ? chatbotDetail?.color_schema?.user_message_text_color : chatbotDetail?.color_schema?.chatbot_message_text_color, // Adjust the text color as needed
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "22px",
            float: isSender ? 'right' : 'left'
        };
        return (
            <div className='clearfix' style={{ width: '100%' }}>
                <div style={messageStyle}>
                    <p>{text}</p>
                </div>
            </div>
        );
    };
    // const Dmessages = [
    //     { id: 1, text: 'Hello', sender: 'user' },
    //     { id: 2, text: 'Hello :)', sender: 'assistant' },
    // ];
    return (
        <Box className='chatPreview_wrapper'>
            <Paper elevation={0} sx={{ width: '300px', height: '460px', borderRadius: '8px' }}>
                <Box sx={{ p: 1.5, display: 'flex', justifyContent: 'space-between' }}>
                    <Box className="chatbot-header" sx={{ m: 0 }}>
                        <div className="chatbot-welcome">
                            <Avatar src={chatbotDetail.logo} alt="ChatBot Avatar" /><Typography variant='p' sx={{ mt: 1, ml: 1 }}>{chatbotDetail.title}</Typography>
                        </div>
                    </Box>
                    <Box variant="div" component="div" sx={{ justifyContent: 'space-between', my: 'auto' }} >
                        <IoCloseSharp size={24} style={{ cursor: 'pointer' }} onClick={handleChatBotToggle} />
                        {/* <SlOptionsVertical size="1rem" /> */}
                    </Box>
                </Box>
                <Paper elevation={0} sx={{ backgroundColor: chatbotDetail?.color_schema?.chatbot_background_color, px: 1, m: 0 }}>
                    <Box sx={{ height: '335px' }}>
                        <Message key={1} text={chatbotDetail.initial_message} sender={false} />
                        <Message key={2} text={"Hello :)"} sender={true} />
                    </Box>
                    <Paper elevation={0} component="form">
                        <InputBase
                            fullWidth
                            disabled={true}
                            sx={{ flex: 1, pl: 1 }}
                            // onChange={(e) => setSendMessage(e.target.value)}
                            placeholder="Ask Your Question"
                            inputProps={{ 'aria-label': 'search' }}
                        />
                        <IconButton type="button" sx={{ p: '10px' }} aria-label="search" >
                            <SendIcon sx={{ color: chatbotDetail?.color_schema?.chatbot_button_background_color }} />
                        </IconButton>
                    </Paper>
                    <Typography variant="caption" display="block" sx={{ textAlign: 'center', py: 0.5 }}>
                        Built with PrismView
                    </Typography>
                </Paper>
                {/* </div> */}
            </Paper >
        </Box>
    );
};

export default ChatBot;
