import React, { useState, useCallback, useMemo, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
  Typography,
  DialogContent,
  DialogActions,
  Box,
} from "@mui/material";
import "../../styles/dashboard.scss";
import { FaTrashCan } from "react-icons/fa6";
import { useDropzone } from "react-dropzone";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  createKnowledgeBaseWithFileThunk,
  fetchAllConfigurationThunk,
  getOneKnowledgeBaseThunk,
  removeKbFileThunk,
  updateKnowledgeBaseWithFileThunk,
} from "../../redux/slices/knowledgeBase.slice";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/common.slice";
import { CONFIG_IMG } from "../../constants/common.constant";
import DriveStructure from "./DriveStructure";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import CustomizedDialog from "./Dialog";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const ScrollBars = {
  "&::-webkit-scrollbar": {
    width: " 4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cccccc",
  },
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "50px 30px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgba(39, 49, 103, 0.1)",
  borderStyle: "dashed",
  backgroundColor: "rgba(39, 49, 103, 0.1)",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const CreateKb = ({
  fetchAllKnowledgeBase,
  isOpenDrawer,
  setDrawerState,
  setSelectedRowData,
  setIsOpenDrawer,
  drawerState,
  selectedRowData,
}) => {
  const [kbFile, setKbFile] = useState([]);
  const [configs, setConfigs] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState(false);
  const [integrationId, setIntegrationId] = useState();
  const [selectedFileFolder, setSelectedFileFolder] = useState({});
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    flag: "1",
  });

  const onDrop = useCallback((acceptedFiles) => {
    setKbFile((prev) => {
      const localFiles = [...prev, ...acceptedFiles].filter(
        (item) => item.path
      );
      const apiFiles = [...prev, ...acceptedFiles].filter(
        (item) => item.fileurl
      );

      // Removing duplicates from local files
      const uniqueLocalFiles = [
        ...new Map(localFiles.map((item) => [item["path"], item])).values(),
      ];

      // Merging unique local files with API files
      const updatedData = [...uniqueLocalFiles, ...apiFiles];

      return updatedData;
    });
  }, []);

  const fetchAllConfiguration = async () => {
    let response = await dispatch(fetchAllConfigurationThunk());
    const { payload } = response;
    if (payload?.status) {
      setConfigs(payload?.data);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept: {
        "application/pdf": [".pdf"],
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          [".docx"],
        "application/msword": [".doc"],
        "text/csv": [".csv"],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
          ".xlsx",
        ],
      },
    });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const getKbValues = async () => {
    let response = await dispatch(getOneKnowledgeBaseThunk(selectedRowData));
    const { payload } = response;
    if (payload && payload?.status) {
      const data = {
        name: payload?.data.name,
        description: payload?.data.description,
        flag: payload?.data.status,
      };
      setInitialValues(data);
      formik.setValues(data);
      setKbFile(payload?.data.files);
      setSelectedFileFolder([]);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const handleRemoveFile = useCallback((index) => {
    setKbFile((prevFiles) => prevFiles.filter((file, i) => i !== index));
  }, []);

  useEffect(() => {
    if (drawerState !== "create") {
      getKbValues();
    }
    fetchAllConfiguration();

    // eslint-disable-next-line
  }, [drawerState, isOpenDrawer]);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup.string().trim().required("Name is required"),
      description: yup.string().optional(),
      flag: yup.string().default(1),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        values.file = kbFile;
        values.file_ids = Object.fromEntries(
          Object.entries(selectedFileFolder).map(([key, value]) => [
            key,
            value.map((item) => (item.id ? item.id : item.file_id)),
          ])
        );
        if (integrationId) values.configuration_id = integrationId.id;
        // let response = await dispatch(createKnowledgeBaseWithFileThunk(values));
        let response = await dispatch(
          drawerState === "edit"
            ? updateKnowledgeBaseWithFileThunk({
                values: values,
                id: selectedRowData.kbId,
              })
            : createKnowledgeBaseWithFileThunk(values)
        );
        const { payload } = response;
        if (payload && payload?.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Knowledge Base Successfully Added",
            })
          );
          setIsOpenDrawer(false);
          fetchAllKnowledgeBase();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
        console.error("Error during login:", error);
      }
      setIsLoading(false);
    },
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
    setDrawerState("create");
    setSelectedRowData(null);
    setKbFile([]);
  };

  const handleRemoveFileFolder = (idToRemove) => {
    setSelectedFileFolder((prevState) => {
      const updatedState = {};

      Object.keys(prevState).forEach((key) => {
        updatedState[key] = prevState[key].filter(
          (item) => item.id !== idToRemove && item.file_id !== idToRemove
        );
      });

      return updatedState;
    });
  };

  const handleSelectedIntegration = (e) => {
    setSelectedIntegration(configs?.find((obj) => obj.id === e.target.value));
    setIntegrationId(configs?.find((obj) => obj.id === e.target.value));
  };

  const fetchRemoveKbFile = async (fileUrl) => {
    let response = await dispatch(
      removeKbFileThunk({
        id: selectedRowData.kbId,
        file_url: fileUrl,
      })
    );
    const { payload } = response;
    if (payload?.status) {
      getKbValues();
      fetchAllKnowledgeBase();
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={
          drawerState === "view"
            ? "Knowledge Base Detail"
            : drawerState === "create"
            ? "Create Knowledge Base"
            : "Edit Knowledge Base"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent sx={ScrollBars}>
          <form>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ paddingTop: "10px !important" }}
              >
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Name"
                    size="small"
                    name="name"
                    disabled={drawerState === "view"}
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="form-error-message">
                      {formik.errors.name}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ paddingTop: "10px !important" }}
              >
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Description"
                    size="small"
                    name="description"
                    disabled={drawerState === "view"}
                    {...formik.getFieldProps("description")}
                    rows={4}
                    multiline
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                sx={{ paddingTop: "10px !important" }}
              >
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Integration
                  </Typography>
                  {drawerState !== "view" && (
                    <TextField
                      fullWidth
                      onChange={handleSelectedIntegration}
                      select
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="Enter Integration"
                      size="small"
                      name="integration"
                      disabled={drawerState === "view"}
                      /*{...formik.getFieldProps('description')}*/
                      rows={4}
                    >
                      {configs?.map((config) => (
                        <MenuItem value={config.id}>
                          <img
                            src={CONFIG_IMG[config.type]}
                            alt={config.type}
                            style={{
                              width: "15px",
                              marginRight: "5px",
                            }}
                          />
                          {config.name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                  {Object.keys(selectedFileFolder).length > 0 ? (
                    <Box
                      variant="div"
                      component="div"
                      sx={{
                        mt: 1,
                        maxHeight: "150px",
                        overflowY: "auto",
                      }}
                    >
                      {Object.values(selectedFileFolder)
                        .flat()
                        .map((file, index) => (
                          <Stack
                            key={index}
                            sx={{ mt: 1 }}
                            direction="row"
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <img
                              src={CONFIG_IMG[file.integration_type]}
                              alt={file.integration_type}
                              style={{
                                width: "15px",
                                marginRight: "10px",
                              }}
                            />
                            {file.type ===
                              "application/vnd.google-apps.folder" ||
                            file.folder ? (
                              <FolderIcon style={{ width: "20px" }} />
                            ) : (
                              <InsertDriveFileIcon style={{ width: "20px" }} />
                            )}
                            <Typography variant="p" sx={{ flex: 1, ml: 1 }}>
                              {file.folder_name ? `${file.folder_name} /` : ""}{" "}
                              {file?.file ||
                                file?.page_name?.content ||
                                file?.name}
                            </Typography>
                            <Typography variant="p" sx={{ mr: 2 }}>
                              {file.type ===
                                "application/vnd.google-apps.folder" ||
                              file.folder
                                ? "Folder"
                                : "File"}
                            </Typography>
                            <Box
                              className="icon-wrap"
                              sx={{ cursor: "pointer" }}
                              onClick={() =>
                                handleRemoveFileFolder(file.id || file.file_id)
                              }
                            >
                              <FaTrashCan size={20} color="#FF0000" />
                            </Box>
                          </Stack>
                        ))}
                    </Box>
                  ) : (
                    <Typography variant="p" fontSize={"11px"}>
                      No Integration Connected
                    </Typography>
                  )}
                  {/* {formik.touched.description && formik.errors.description && (
                                    <div className="form-error-message">{formik.errors.description}</div>
                                )} */}
                </Box>
              </Grid>
              <Grid item xs={12} sx={{ paddingTop: "10px !important" }}>
                <Box
                  disabled={true}
                  variant="div"
                  component="div"
                  sx={BootstrapInput}
                >
                  <Typography variant="body1" component="label">
                    Upload Files
                  </Typography>
                  {drawerState !== "view" && (
                    <div {...getRootProps({ style })}>
                      <input
                        id="fileInput"
                        type="file"
                        multiple
                        {...getInputProps()}
                      />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                      <p>Maximum File Size: 15MB</p>
                    </div>
                  )}
                  {kbFile.length > 0 ? (
                    <Box
                      variant="div"
                      component="div"
                      sx={{
                        mt: 1,
                        maxHeight: "150px",
                        overflowY: "auto",
                      }}
                    >
                      {kbFile.map((file, index) => (
                        <Stack
                          key={index}
                          sx={{ mt: 1 }}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          {file.type !== "Upload file" &&
                          file.type !== "application/pdf" &&
                          file.type !== "text/csv" ? (
                            <img
                              src={CONFIG_IMG[file.type]}
                              alt={file.type}
                              style={{
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          ) : (
                            <div
                              style={{
                                width: "15px",
                                height: "15px",
                              }}
                            />
                          )}
                          <Typography variant="p" sx={{ flex: 1, ml: 1 }}>
                            {file.name || file.filename}
                          </Typography>
                          <Typography variant="p" sx={{ mr: 2 }}>
                            {(
                              file.size / 1024 / 1024 ||
                              file.filesize / 1024 / 1024
                            ).toFixed(2)}{" "}
                            Mb
                          </Typography>
                          {drawerState !== "view" && (
                            <Box
                              className="icon-wrap"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                file?.fileurl
                                  ? fetchRemoveKbFile(file?.fileurl)
                                  : handleRemoveFile(index);
                              }}
                            >
                              {" "}
                              <FaTrashCan size={20} color="#FF0000" />{" "}
                            </Box>
                          )}
                        </Stack>
                      ))}
                    </Box>
                  ) : (
                    <Typography variant="p" fontSize={"11px"}>
                      No File uploaded
                    </Typography>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {drawerState !== "view" && (
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </DialogActions>
      </CustomizedDialog>
      {selectedIntegration && (
        <DriveStructure
          selectedIntegration={selectedIntegration}
          setSelectedIntegration={setSelectedIntegration}
          selectedFileFolder={selectedFileFolder}
          setSelectedFileFolder={setSelectedFileFolder}
        />
      )}
    </>
  );
};

export default CreateKb;
