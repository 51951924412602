import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Download from "../../../../utils/icon/Download";
import RightArrow from "../../../../utils/icon/RightArrow";
import { useLocation } from "react-router-dom";
import SkeletonCard from "../Skeleton";
import { HandleDownload } from "../utils/DownloadExcel";
import ChildAccordian from "./ChildAccordian";
import "../../../../styles/runworkflow.scss";

const RunWorkflow = () => {
  const [workflowHistoryList, setWorkflowHistoryList] = useState([]);
  const [isFinished, setIsFinied] = useState();
  const location = useLocation();
  console.log(location);
  
  const socket = new WebSocket(
    `${process.env.REACT_APP_WEBSOCKET_BASE_URL}/workflow/${location.state.workflow_id}`
  );

  console.log("Sokcet url");
  console.log(socket);
  
  

  const SetupSocketConnection = () => {
    socket.onopen = () => {
      console.log("WebSocket connected");
    };

    socket.onmessage = (event) => {
      let socketdata = JSON.parse(event.data);

      setWorkflowHistoryList((prevData) => {
        const updatedData = [...prevData, socketdata];

        const uniqueData = [];
        const seenIds = new Set();

        updatedData.forEach((item) => {
          // Handle the case where the payload key is present
          const entry = item.payload || item;
          if (!seenIds.has(entry.action_id)) {
            seenIds.add(entry.action_id);
            uniqueData.push(entry);
          }
        });

        const filterdData = uniqueData.filter((status) => status.action_id);

        return filterdData;
      });

      setIsFinied(() => {
        const status = [];
        const socketdatastatus = [socketdata];
        socketdatastatus?.forEach((data) => {
          status.push(data?.payload?.status);
        });
        return status.join(", ");
      });
    };

    socket.onclose = (event) => {
      if (event?.wasClean) {
        console.log("WebSocket closed cleanly");
      } else {
        console.error("WebSocket connection died");
      }
    };

    socket.onerror = (error) => {
      console.error(`WebSocket encountered an error: ${error.message}`);
    };
  };

  useEffect(() => {
    SetupSocketConnection();
    // Cleanup the WebSocket connection when the component is unmounted

    return () => {
      // socket.close();
      console.log("close effect");
    };
    // eslint-disable-next-line
  }, [location.state.workflow_id]);

  return (
    <>
      <Paper
        sx={{
          border: "1px solid #C1C1C1",
          borderRadius: "8px",

          width: "100%",
        }}
        elevation={0}
      >
        {" "}
        <Stack
          padding={"15px"}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack flexDirection="column">
            <Stack direction="row" alignItems="center">
              <Typography variant="h6" className="title-result-workflow">
                Results
              </Typography>
              <Button className="workflow-finished-btn">
                Workflow{" "}
                {`${isFinished !== "finished" ? "Running" : "Finished"}`}
              </Button>
            </Stack>
            <Box sx={{ paddingBottom: "20px" }}>
              <Typography variant="subtitle" className="workflow-schedule">
                Learn how to use: Run the workflow on a schedule
              </Typography>
            </Box>
          </Stack>

          <Button
            variant="contained"
            className="primary-button mt-2 workflow-finished-btn"
            disabled={isFinished !== "finished"}
            sx={{
              padding: "16px",
            }}
            onClick={() => HandleDownload(workflowHistoryList)}
          >
            <Download />
          </Button>
        </Stack>
        {workflowHistoryList.length ? (
          <Box
            display="flex"
            flexDirection="column"
            gap={"15px"}
            padding={"10px"}
          >
            {workflowHistoryList?.map((item, index) => (
              <>
                <div
                  key={`${index}-${item}`}
                  style={{
                    display: "flex",
                    position: "relative",
                    gap: "15px",
                  }}
                >
                  {index !== workflowHistoryList.length - 1 && (
                    <div className="circle-line-mian-div">
                      <div className="line-div" />
                    </div>
                  )}
                  <div className="outer-circle">
                    <div className="inner-circle"></div>
                  </div>
                  <Accordion
                    key={`${item}-${index}`}
                    className="main-accordian"
                  >
                    <AccordionSummary
                      expandIcon={<RightArrow />}
                      aria-controls={`panel${index + 1}-content`}
                      id={`panel${index + 1}-header`}
                      className="main-accordian-summury"
                    >
                      <Typography
                        variant="subtitle"
                        sx={{ margin: "0px", fontSize: "18px" }}
                      >
                        {item?.action_response
                          ? item.action_name
                          : item[0]?.term ?? item.action_name}
                      </Typography>
                    </AccordionSummary>
                    <ChildAccordian item={item} index={index} />
                  </Accordion>
                </div>
              </>
            ))}
          </Box>
        ) : (
          <Stack
            variant="outlined"
            className="center"
            sx={{
              p: 2,
              minHeight: 300,
              whiteSpace: "pre-wrap",
            }}
          >
            <SkeletonCard />
          </Stack>
        )}
      </Paper>
    </>
  );
};

export default RunWorkflow;
