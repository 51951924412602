import {
  Box,
  IconButton,
  Paper,
  Stack,
  Tooltip,
  Select,
  MenuItem,
  Typography,
  Chip,
  CircularProgress,
  Divider,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import TablePagination from "@mui/material/TablePagination";
import DeleteIcon from "../../assets/icons/deleteRed.svg";
import Pencil from "../../assets/icons/pencil.svg";
import { MdRemoveRedEye } from "react-icons/md";
import DateRangePicker from "./dateRangePicker";
import "../../styles/reports.scss";
import { setSnackbar } from "../../redux/slices/common.slice";
import {
  getAllTenantReport,
  getAllUsersThunk,
} from "../../redux/slices/tenant.slice";
import { useDispatch } from "react-redux";
import moment from "moment";
import CreateReport from "./createReport";
import DeleteConfigureAction from "./settings/ConfigureAction/DeleteConfigureAction";
import { REPORT_OPTION, CLASSIFICATION_OPTION, INJURY_OPTION } from "../../constants/report.constant";

const TableStyle = {
  "& .MuiTable-root": {
    "& .MuiTableHead-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          backgroundColor: "#64bcd2",
          color: "#FFFFFF",
          fontSize: "16px",
          "&:last-child": {
            borderBottom: "none",
          },
          "& .MuiTableSortLabel-root": {
            "& svg": {
              fill: "#FFFFFF",
            },
          },
        },
      },
    },

    "& .MuiTableBody-root": {
      "& .MuiTableRow-root": {
        "& .MuiTableCell-root": {
          padding: "12px",
          borderColor: "rgba(224, 224, 224, 1)",
          "& .public-status": {
            backgroundColor: "#6EC531",
          },
          "& .private-status": {
            backgroundColor: "#273167",
          },
          "&:focus": {
            outline: "none !important",
          },
        },
        "&:hover": {
          backgroundColor: "rgb(255 115 100 / 5%)",
        },
      },
    },
  },

  "& .MuiTableContainer-root": {
    "&::-webkit-scrollbar": {
      width: "4px",
      height: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  },
};

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "14px",
    pr: 3,
    width: 160,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};

const Reports = () => {
  const [reportTypeFilter, setReportTypeFilter] = useState("all");
  const [userTypeFilter, setUserTypeFilter] = useState("all");
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 10,
  });
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [dateRange, setDateRange] = useState(["", ""]); // Initial date range
  const [reportStatus, setReportStatus] = useState("PENDING") ; 
  const [isLoading, setIsLoading] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [totalRow, setTotalRow] = useState(0);
  const [userData, setUserData] = useState([]);
  const [reportLayout, setReportLayout] = useState(false);
  const [drawerState, setDrawerState] = useState("edit");
  const [selecteduserData, setSelectedUserData] = useState({});
  const [isDelete, setIsDelete] = useState(false);
  const [imageToken, setImageToken] = useState(null);

  // Report option
  const ReportOption = REPORT_OPTION

  // Classfication option
  const ClassficationOption = CLASSIFICATION_OPTION

  // Injury option
  const InjuryOption = INJURY_OPTION

  const dispatch = useDispatch();

  const fetchAllReport = async () => {
    setIsLoading(true);
    let send_payload = {
      page_number: paginationModel.page + 1,
      page_size: paginationModel.pageSize,
      type: reportTypeFilter == "all" ? undefined : reportTypeFilter,
      user_id: userTypeFilter == "all" ? undefined : userTypeFilter,
      status: reportStatus == "All"?undefined:reportStatus
    };

    if (dateRange?.length > 0) {
      send_payload["start"] =
        dateRange[0] == ""
          ? undefined
          : moment(dateRange[0]).format("YYYY-MM-DD");
      send_payload["end"] =
        dateRange[1] == ""
          ? undefined
          : moment(dateRange[1]).format("YYYY-MM-DD");
    }
    let response = await dispatch(getAllTenantReport(send_payload));
    const { payload } = response;
    if (payload?.status) {
      setReportData(payload?.data);
      setTotalRow(payload?.total_object);
      setImageToken(payload?.token);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  const fetchAllUser = async () => {
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 1000,
        status: "all",
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = [{ label: "All User", value: "all" }];

      payload?.data?.map((element) => {
        temp.push({
          label: `${element?.first_name} ${element?.last_name}`,
          value: element?.id,
        });
        setUserData(temp);
      });
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  useEffect(() => {
    fetchAllUser();
  }, []);

  useEffect(() => {
    fetchAllReport();
  }, [
    paginationModel,
    reportTypeFilter,
    userTypeFilter,
    dateRange,
    rowsPerPage,
    page,
    reportStatus
  ]);

  function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);

    const OtherDetails = [
      { label: "Report title", value: row?.title },
      { label: "Report descritption", value: row?.description },
      { label: "Injury descritpion", value: row?.injury_description },
      {
        label: "Classify of case",
        value:
          row?.classify_of_case == null
            ? "-"
            : ClassficationOption[row?.classify_of_case],
      },
      {
        label: "Illness",
        value: row?.illness == null ? "-" : InjuryOption[row?.illness],
      },
      {
        label:"Away from work days", 
        value: row?.number_of_days_injured?.away_work_days || 0
      },
      {
        label:"On job transfer or restriction", 
        value: row?.number_of_days_injured?.job_transfer_days || 0
      }
    ];

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell className="table-report-data-cell">
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>

          <TableCell
            component="th"
            scope="row"
            className="table-report-data-cell"
          >
            {row?.project?.projectid}
          </TableCell>

          <TableCell align="teft" className="table-report-data-cell">
            {row?.user?.user_role != null ? row?.user?.user_role : "-"}
          </TableCell>

          <TableCell align="teft" className="table-report-data-cell">
            <Chip label={row?.report_type} color="primary" variant="outlined" />
          </TableCell>

          <TableCell
            align="left"
            className="table-report-data-cell"
          >{`${row?.user?.first_name} ${row?.user?.last_name}`}</TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {moment(row?.report_date).format("DD-MM-YYYY")}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {moment(row?.updated_at).format("DD-MM-YYYY")}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {row?.image_info?.analysis?.where_this_event_occured == null
              ? "-"
              : row?.image_info?.analysis?.where_this_event_occured}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            {row?.status == "PENDING" ? (
              <>
                <Chip
                  label={row?.status}
                  style={{ backgroundColor: "#ff4336" }}
                />
              </>
            ) : (
              <>
                <Chip
                  label={row?.status}
                  style={{ backgroundColor: "green" }}
                />
              </>
            )}
          </TableCell>

          <TableCell align="left" className="table-report-data-cell">
            <Stack direction="row" spacing={2} alignItems="center">
              <Tooltip title="View">
                <IconButton
                  sx={{ padding: "0px" }}
                  onClick={() => {
                    setReportLayout(true);
                    setDrawerState("view");
                    setSelectedUserData(row);
                  }}
                >
                  <MdRemoveRedEye
                    size={24}
                    cursor={"pointer"}
                    color="#036185"
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit">
                <IconButton
                  sx={{ color: "#000000", padding: "0px" }}
                  onClick={() => {
                    setReportLayout(true);
                    setDrawerState("edit");
                    setSelectedUserData(row);
                  }}
                >
                  <img
                    alt="Edit Details"
                    src={Pencil}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  sx={{ color: "#000000", padding: "0" }}
                  onClick={() => {
                    setIsDelete(true);
                    setSelectedUserData(row);
                  }}
                >
                  <img
                    alt="Delete Details"
                    src={DeleteIcon}
                    width={24}
                    height={24}
                    className="actionIcon"
                    cursor={"pointer"}
                  />
                </IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell
            style={{ paddingBottom: 0, paddingTop: 0 }}
            colSpan={10}
            className="form-other-information-cell"
          >
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box display="flex">
                <Box sx={{ margin: 1 }} className="other-info-div">
                  <Typography variant="h6" gutterBottom component="div" style={{fontWeight: 700}}>
                    Other info
                  </Typography>
                  <hr></hr>
                  <ul style={{ width: "700px", paddingLeft: "0px" }}>
                    {OtherDetails.map((details) => (
                      <>
                        <li className="other-info-list" style={{ marginTop: 4 }}>
                          <Typography
                            variant="subtitle1"
                            className="other-info-list-title"
                            width={"100%"}
                            fontWeight={400}
                          >
                            {details.label}
                          </Typography>{" "}
                          <Typography
                            variant="subtitle1"
                            width={"100%"}
                            marginLeft={"5px"}
                            className="other-info-list-description"
                          >
                            {details.value}
                          </Typography>{" "}
                        </li><hr className="other-info-list-divider"></hr>
                      </>
                    ))}
                  </ul>
                </Box>
                <Box
                  component="img"
                  alt="Descriptive Alt Text" // Replace with your alt text for accessibility
                  src={`${row?.image[0]}?${imageToken}`} // Replace with your image URL
                  sx={{
                    width: 300, // Set the width of the image box
                    height: 300, // Set the height of the image box
                    objectFit: "cover", // This makes the image cover the box without distortion
                    margin: 1, 
                    borderRadius: 2
                  }}
                />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  return (
    <>
      <Box className="mb-4">
        <Typography variant="h2" component="h2"></Typography>

        <Box variant="div" component="div" style={{ display: "flex" }}>
          <DateRangePicker
            dateRange={dateRange}
            onDateRangeChange={handleDateRangeChange}
            machineFilter={null} // Adjust according to your use case
            disableClearable={false} // or true depending on your requirement
          />

          <div style={{ marginLeft: "auto" }}>
            <Select
              sx={customStyles}
              name="status"
              className="basic-multi-select"
              style={{ width: "fit-content", marginLeft: 15 }}
              onChange={(event) => {
                setReportStatus(event.target.value);
              }}
              value={reportStatus}
              classNamePrefix="select"
              components={{
                IndicatorSeparator: () => null,
              }}
            >
                <MenuItem value={"All"}>All</MenuItem>
                <MenuItem value={"PENDING"}>Pending</MenuItem>
                <MenuItem value={"COMPLETED"}>Completed</MenuItem>
            </Select>

            <Select
              sx={customStyles}
              name="status"
              className="basic-multi-select"
              style={{ width: "fit-content", marginLeft: 15 }}
              onChange={(event) => {
                setReportTypeFilter(event.target.value);
              }}
              value={reportTypeFilter}
              classNamePrefix="select"
              components={{
                IndicatorSeparator: () => null,
              }}
              size="small"
            >
              {ReportOption.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>

            <Select
              sx={customStyles}
              name="status"
              className="basic-multi-select"
              style={{ width: "fit-content", marginLeft: 15 }}
              onChange={(event) => {
                setUserTypeFilter(event.target.value);
              }}
              value={userTypeFilter}
              classNamePrefix="select"
              components={{
                IndicatorSeparator: () => null,
              }}
            >
              {userData.map((item) => (
                <MenuItem value={item.value}>{item.label}</MenuItem>
              ))}
            </Select>
          </div>
        </Box>
      </Box>

      <Box sx={{ flexGrow: 1 }}>
        <TableContainer component={Paper} sx={TableStyle}>
          <Table aria-label="collapsible table" sx={TableStyle}>
            <TableHead>
              <TableRow>
                <TableCell width={20} />
                <TableCell width={150}>Project ID</TableCell>
                <TableCell align="left" width={200}>
                  Job title
                </TableCell>
                <TableCell align="left" width={200}>
                  Report Type
                </TableCell>
                <TableCell align="left" width={200}>
                  Report By
                </TableCell>
                <TableCell align="left" width={200}>
                  Injury Date
                </TableCell>
                <TableCell align="left" width={200}>
                  Updated At
                </TableCell>
                <TableCell align="left" width={300}>
                  Where event occured
                </TableCell>
                <TableCell align="left" width={300}>
                  Report Status
                </TableCell>
                <TableCell align="left" width={300}>
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading ? (
                <>
                  <TableRow
                    style={{ paddingTop: "12px !important", paddingBottom: 12 }}
                  >
                    <TableCell
                      style={{ borderBottom: 0 }}
                      colSpan={10}
                      align="center"
                    >
                      <CircularProgress />
                    </TableCell>
                  </TableRow>
                </>
              ) : (
                <>
                  {reportData?.length === 0 ? (
                    <>
                      <TableRow>
                        <TableCell colSpan={10} style={{ paddingTop: 10 }}>
                          <Typography variant="body2" align="center">
                            No data available
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  ) : (
                    <>
                      {reportData
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => (
                          <Row key={row.name} row={row} />
                        ))}
                    </>
                  )}
                </>
              )}
            </TableBody>
          </Table>
          {!isLoading && (
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={totalRow}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          )}
        </TableContainer>
      </Box>
      {reportLayout && (
        <CreateReport
          isLayout={reportLayout}
          setLayout={setReportLayout}
          drawerState={drawerState}
          values={selecteduserData}
          fetchAllReport={fetchAllReport}
        />
      )}

      {isDelete && (
        <DeleteConfigureAction
          isDelete={isDelete}
          setIsDelete={setIsDelete}
          selectedRowData={selecteduserData}
          fetchAllKnowledgeBase={fetchAllReport}
          kbmodule={"Report"}
          isLoading={isLoading}
        />
      )}
    </>
  );
};

export default Reports;
