import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getSubscriptionPlanThunk = createAsyncThunk(
  "SubscriptionPlan",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_SUBSCRIPTION_PLAN}`
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getCurruntSubscriptionPlanThunk = createAsyncThunk(
  "CurruntSubscriptionPlan",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_CURRUNT_SUBSCRIPTION_PLAN}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const userSignupThunk = createAsyncThunk("userSignup", async (page) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_URL.SIGNUP_USER}`,
      page
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const userSignupOtpVerificationThunk = createAsyncThunk(
  "userSignup",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SIGNUP_USER_OTP_VERIFICATION}`,
        page
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const upgradeSubscriptionPlanThunk = createAsyncThunk(
  "upgradeSubscriptionPlan",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SUBSCRIPTION_PLAN_UPGRADE}`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getHistorySubscriptionPlanThunk = createAsyncThunk(
  "HistorySubscriptionPlan",
  async (page) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_HISTORY_SUBSCRIPTION_PLAN}` +
          page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const cancelSubscriptionThunk = createAsyncThunk(
  "cancelSubscription",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CANCEL_SUBSCRIPTION_PLAN}`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Assistants slice
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default subscriptionSlice.reducer;
