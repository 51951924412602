import React from "react";

const Spark = () => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.1667 2.33333L3.5 16.3333H14L12.8333 25.6667L24.5 11.6667H14L15.1667 2.33333Z"
        stroke="black"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Spark;
