import React from "react";
import {
  Box,
  Button,
  Paper,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { removeConfigureActionThunk } from "../../../../redux/slices/tenant.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import CustomizedDialog from "../../Dialog";
import { removeProjecThunk, removekbThunk } from "../../../../redux/slices/knowledgeBase.slice";
import { removeReportThunk } from "../../../../redux/slices/knowledgeBase.slice";

const DeleteConfigureAction = ({
  isDelete,
  setIsDelete,
  selectedRowData,
  isLoading,
  fetchAllConfigureAction,
  kbmodule,
  fetchAllKnowledgeBase,
}) => {
  console.log(selectedRowData);
  const dispatch = useDispatch();

  const handleKBDelete = async (id) => {
    let response = await dispatch(removekbThunk(id));
    const { payload } = response;
    if (payload && payload?.status) {
      setIsDelete(false);
      fetchAllKnowledgeBase();
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "KB deleted successfully",
        })
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const handleDelete = async (id) => {
    let response = await dispatch(removeConfigureActionThunk(id));
    const { payload } = response;
    if (payload && payload?.status) {
      setIsDelete(false);
      fetchAllConfigureAction();
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Action deleted successfully",
        })
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const handleProjectDelete = async (row) => {
    let response = await dispatch(removeProjecThunk(row));
    const { payload } = response;
    if (payload && payload?.status) {
      setIsDelete(false);
      fetchAllKnowledgeBase();
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Project deleted successfully",
        })
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  }

  const handleReportDelete = async (row) => {
    let response = await dispatch(removeReportThunk(row));
    const { payload } = response;
    if (payload && payload?.status) {
      setIsDelete(false);
      fetchAllKnowledgeBase();
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Report deleted successfully",
        })
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  }
  return (
    <CustomizedDialog
      className="modal-scroll"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={
        kbmodule ? "Delete Confirmation" : "Are You Sure You Want To Delete ?"
      }
      open={isDelete}
      handleClose={() => {
        setIsDelete(false);
      }}
    >
      <DialogContent>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Paper
            sx={{
              overflowX: "auto",
              whiteSpace: "pre-wrap",
              boxShadow: "none",
            }}
          >
            {`You want to delete ${
              kbmodule === "kbmodule"
                ? selectedRowData.kbName
                : kbmodule == "Report"?`${selectedRowData?.title} report`:selectedRowData.name
            } ?`}
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          className="modal-footers"
          sx={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ marginRight: "15px" }}
            variant="contained"
            className="primary-button"
            disabled={isLoading}
            onClick={() =>
              kbmodule === "kbmodule"
                ? handleKBDelete(selectedRowData)
                : kbmodule == "project"?handleProjectDelete(selectedRowData):kbmodule == "Report"?handleReportDelete(selectedRowData):handleDelete(selectedRowData)
            }
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            className="secondary-button"
            disabled={isLoading}
            onClick={() => setIsDelete(false)}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </CustomizedDialog>
  );
};

export default DeleteConfigureAction;
