import {
  Button,
  Grid,
  Paper,
  Stack,
  Typography,
  Box,
  CircularProgress,
  DialogActions,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getHistorySubscriptionPlanThunk,
  getCurruntSubscriptionPlanThunk,
  cancelSubscriptionThunk,
} from "../../../../redux/slices/subscription.slice";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { PAGE_URL } from "../../../../constants/pageUrl.constant";
import { useNavigate } from "react-router-dom";
import "../../../../styles/billings.scss";
import DataGridTable from "../../dataGrid";
import moment from "moment";
import CustomizedDialog from "../../Dialog";

const BilingsAndPlans = () => {
  const [subPlanHistory, setSubPlanHistory] = useState([]);
  const [curruntSubPlan, setCurruntSubPlan] = useState([]);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 10,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [totalRow, setTotalRow] = useState(0);
  const [cancelSubscription, setCancelSubscription] = useState(false);

  const dispatch = useDispatch();
  const Navigate = useNavigate();

  const fetchSubscriptionPlanHistory = async () => {
    let response = await dispatch(
      getHistorySubscriptionPlanThunk(
        `?page_number=${paginationModel.page + 1}&page_size=${
          paginationModel.pageSize
        }`
      )
    );
    const { payload } = response;
    if (payload && payload?.status) {
      const data = payload?.data.map((item) => ({
        ...item,
        name: item?.subscription?.plan_name ?? "Free",
        id: item.id,
        amount_paid: `$${item?.amount_paid ?? "0"}`,
        startDate: item?.djstripe_created,
      }));
      setSubPlanHistory(data);
      setTotalRow(payload?.total_object);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchCurruntSubscriptionPlan = async () => {
    setIsLoading(true);
    let response = await dispatch(getCurruntSubscriptionPlanThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      setCurruntSubPlan(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setIsLoading(false);
  };

  const fetchCancelSubscriptionPlan = async () => {
    setIsLoading(true);
    let response = await dispatch(cancelSubscriptionThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: payload?.message || "Internal server error",
        })
      );
      setCancelSubscription(false);
      fetchCurruntSubscriptionPlan();
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchSubscriptionPlanHistory();
    fetchCurruntSubscriptionPlan();

    // eslint-disable-next-line
  }, []);

  function DateCell(params) {
    return moment(params.value).format("MM-DD-yyyy");
  }

  const columns = [
    { field: "name", headerName: "Name", flex: 1, minWidth: 150 },
    { field: "amount_paid", headerName: "Price ($)", minWidth: 300 },
    { field: "billing_reason", headerName: "Billing Reason", minWidth: 300 },
    {
      field: "djstripe_created",
      headerName: "Started At",
      flex: 1,
      renderCell: DateCell,
      minWidth: 120,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 120,
    },
  ];

  const LatestUpgrade = Number(
    curruntSubPlan?.downgrade_subscription_plan?.length - 1
  );

  return (
    <div>
      {!isLoading ? (
        <Paper className="margin-0" sx={{ padding: "15px", margin: "15px" }}>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            className="modal-footers payment-column"
          >
            <Typography variant="h6" margin={"10px 0"} fontSize={"16px"}>
              Manage your plan and payments
            </Typography>
            {curruntSubPlan.product__name !== "Free" && (
              <Button
                sx={{
                  background: "#fff",
                  color: "#273167 !important",
                  border: "1px solid #273167 !important",
                  width: "fit-content",
                  padding: "10px 30px",
                  margin: "5px",
                  fontWeight: "700",
                  borderRadius: "4px !important",
                }}
                onClick={() => {
                  setCancelSubscription(true);
                }}
                className="cancel-sub mt-2"
              >
                Cancel Plan
              </Button>
            )}
          </Stack>
          <hr></hr>
          <Stack
            flexDirection={"row"}
            justifyContent={"space-between"}
            className="modal-footers"
          >
            <Typography
              className="media-font-h3"
              variant="h3"
              margin={"10px 0"}
            >
              Current Plan : {curruntSubPlan?.product__name}
            </Typography>
            <Button
              sx={{
                color: "#fff",
                width: "fit-content",
                padding: " 10px 30px",
                margin: "5px",
                fontWeight: "700",
                borderRadius: "4px !important",
              }}
              className="primary-button mt-2 change-plan-media"
              onClick={() => {
                Navigate(PAGE_URL.SUBSCRIPTION_BILLINGS, {
                  state: {
                    plan_id: curruntSubPlan.id,
                  },
                });
              }}
            >
              Change Plan
            </Button>
          </Stack>
          <Grid container spacing={6}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack
                sx={{
                  padding: "20px",
                  borderRadius: "20px",
                  border: "1px solid lightgrey",
                }}
              >
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h6"
                    color="lightgray"
                    className="media-font-h3"
                  >
                    {curruntSubPlan.product__description ??
                      curruntSubPlan.product__name}
                  </Typography>
                  <Stack
                    className="active-font"
                    sx={{
                      background: " #c2e2ed",
                      padding: "5px 13px",
                      borderRadius: " 20px",
                      color: "#135652",
                      flexDirection: "row",
                      alignItems: "center",
                      textTransform: "capitalize",
                    }}
                  >
                    <span
                      style={{
                        height: "15px",
                        width: "15px",
                        borderRadius: " 50%",
                        display: "inline-block",
                        marginRight: "5px",
                      }}
                      className={
                        curruntSubPlan.product__name === "Free" ||
                        curruntSubPlan.status === "active"
                          ? "active_status"
                          : "inactive_status"
                      }
                    ></span>
                    {curruntSubPlan.status ?? curruntSubPlan.product__name}
                  </Stack>
                </Stack>
                <Stack className="currunt-plan-media">
                  <div
                    className="pricing-card-price-transaction"
                    style={{ textTransform: "capitalize" }}
                  >
                    ${curruntSubPlan.amount}/{curruntSubPlan.interval}
                  </div>

                  <div>
                    (Started At{" "}
                    {moment(curruntSubPlan.plan_start_date).format(
                      "MM-DD-yyyy HH:mm:ss"
                    )}
                    )
                  </div>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack
                sx={{
                  padding: "20px",
                  borderRadius: "20px",
                  border: "1px solid lightgrey",
                }}
              >
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h6"
                    color="lightgray"
                    className="media-font-h3"
                  >
                    Renew At
                  </Typography>
                </Stack>
                <span
                  className="pricing-card-price-transaction"
                  style={{ fontWeight: "600" }}
                >
                  {" "}
                  {moment(curruntSubPlan.plan_end_date).format(
                    "MM-DD-yyyy HH:mm:ss"
                  )}
                </span>{" "}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Stack
                sx={{
                  padding: "20px",
                  borderRadius: "20px",
                  border: "1px solid lightgrey",
                }}
              >
                <Stack
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography
                    variant="h6"
                    color="lightgray"
                    className="media-font-h3"
                  >
                    Remaining AI Credit
                  </Typography>
                </Stack>
                <span
                  className="pricing-card-price-transaction"
                  style={{ fontWeight: "600" }}
                >
                  {curruntSubPlan?.usage?.subscription_plan_ai_credit -
                    curruntSubPlan?.usage?.used_ai_credit}
                </span>{" "}
              </Stack>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            {curruntSubPlan?.cancel_at_period_end &&
              Object.keys(curruntSubPlan?.downgrade_subscription_plan[0])
                ?.length === 0 &&
              curruntSubPlan.product__name !== "Free" && (
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <Typography
                    variant="h3"
                    className="media-font-h3"
                    margin={"20px 0"}
                  >
                    Cancled Plan
                  </Typography>
                  <Stack
                    sx={{
                      padding: "20px",
                      borderRadius: "20px",
                      border: "1px solid lightgrey",
                    }}
                  >
                    <Stack
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Typography
                        variant="h6"
                        color="lightgray"
                        className="media-font-h3"
                      >
                        {"Cancled At"}
                      </Typography>
                    </Stack>
                    <Stack flexDirection={"row"} alignItems={"center"}>
                      <div className="pricing-card-price-transaction">
                        {moment
                          .unix(curruntSubPlan?.cancel_at)
                          .format("MM-DD-yyyy HH:mm:ss")}
                      </div>
                    </Stack>
                  </Stack>
                </Grid>
              )}
            {(curruntSubPlan?.cancel_at_period_end &&
              curruntSubPlan?.downgrade_subscription_plan?.[0] &&
              Object.keys(curruntSubPlan?.downgrade_subscription_plan?.[0])
                ?.length > 0 &&
              curruntSubPlan?.product__name !== "Free") ||
            (!curruntSubPlan?.cancel_at_period_end &&
              curruntSubPlan?.downgrade_subscription_plan?.[0] &&
              Object.keys(curruntSubPlan?.downgrade_subscription_plan?.[0])
                ?.length > 0 &&
              curruntSubPlan.product__name !== "Free") ? (
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <Typography
                  variant="h3"
                  className="media-font-h3"
                  margin={"20px 0 10px"}
                >
                  Next Plan :{" "}
                  {
                    curruntSubPlan?.downgrade_subscription_plan?.[0]
                      ?.product__name
                  }
                </Typography>
                <Stack
                  sx={{
                    margin: "20px 0",
                    padding: "20px",
                    borderRadius: "20px",
                    border: "1px solid lightgrey",
                  }}
                >
                  <Stack
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                  >
                    <Typography
                      variant="h6"
                      color="lightgray"
                      className="media-font-h3"
                    >
                      {
                        curruntSubPlan?.downgrade_subscription_plan[
                          LatestUpgrade
                        ]?.product__description
                      }
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection={"row"}
                    alignItems={"center"}
                    className="currunt-plan-media"
                  >
                    <div
                      className="pricing-card-price-transaction"
                      style={{ textTransform: "capitalize" }}
                    >
                      $
                      {
                        curruntSubPlan?.downgrade_subscription_plan[
                          LatestUpgrade
                        ]?.amount
                      }
                      /
                      {
                        curruntSubPlan?.downgrade_subscription_plan[
                          LatestUpgrade
                        ]?.interval
                      }
                    </div>
                    <div>
                      (Started At{" "}
                      {moment(
                        curruntSubPlan?.downgrade_subscription_plan[
                          LatestUpgrade
                        ].plan_start_date
                      ).format("MM-DD-yyyy HH:mm:ss")}
                      )
                    </div>
                  </Stack>{" "}
                </Stack>
              </Grid>
            ) : (
              <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
            )}
          </Grid>
          <hr></hr>
          <Stack>
            <Typography
              variant="h3"
              className="media-font-h3"
              margin={"10px 0"}
            >
              Transactions
            </Typography>
            {curruntSubPlan.plan_end_date ? (
              <Box sx={{ flexGrow: 1 }}>
                <DataGridTable
                  data={subPlanHistory}
                  columns={columns}
                  setPaginationModel={setPaginationModel}
                  isLoading={isLoading}
                  paginationModel={paginationModel}
                  totalRow={totalRow}
                />
              </Box>
            ) : (
              <Typography
                variant="h6"
                textAlign={"center"}
                fontSize={"16px"}
                height={"0px"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                You don't have any Transactions
              </Typography>
            )}
          </Stack>
        </Paper>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      )}

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Cancel subscription"}
        open={cancelSubscription}
        handleClose={() => {
          setCancelSubscription(false);
        }}
      >
        <Box padding={"15px"}>
          Are you sure you want to cancel the subscription ?
        </Box>
        <DialogActions>
          <Box
            className="modal-footers"
            sx={{
              display: "flex",
              flexDirection: "row",
              cursor: "pointer",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={() => {
                setCancelSubscription(false);
              }}
              sx={{ margin: "15px !important" }}
              className="secondary-button"
              variant="outlined"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                fetchCancelSubscriptionPlan();
              }}
              className="primary-button"
              variant="contained"
              disabled={isLoading}
            >
              {isLoading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              yes, Cancel It
            </Button>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </div>
  );
};

export default BilingsAndPlans;
