import React, { useState, useEffect } from "react";
import {
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  Box,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { fetchTemplateListThunk } from "../../../../redux/slices/tenant.slice";
import { fetchAllPublicKBThunk } from "../../../../redux/slices/knowledgeBase.slice";
import { useNavigate } from "react-router-dom";
import {
  creatlatesteWorkflowThunk,
  getOneWorkflowThunk,
  updateWorkflowThunk,
  // fetchAllWorkflowKBfileThunk,
} from "../../../../redux/slices/workflow.slice";
import { PAGE_URL } from "../../../../constants/pageUrl.constant";
import CustomizedDialog from "../../../../components/common/Dialog";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const ScrollBars = {
  "&::-webkit-scrollbar": {
    width: " 4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cccccc",
  },
};

const CreateWorkflow = ({
  setIsOpenDrawer,
  setDrawerState,
  drawerState,
  isOpenDrawer,
  selectedRowData,
  setSelectedRowData,
}) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  // const [publicKb, setPublicKb] = useState([]);
  // const [publicTemplateList, setPublicTemplateList] = useState([]);
  // const [getKbFiles, setGetKbFiles] = useState([]);
  // const [templateActions, setTemplateActions] = useState([]);
  const Navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
  });

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
    setDrawerState("create");
    setSelectedRowData(null);
  };

  const fetchKBs = async () => {
    let response = await dispatch(fetchAllPublicKBThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      // setPublicKb(
      //   payload?.data.map((item) => ({
      //     label: item.kbName,
      //     value: item.kbId,
      //   }))
      // );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  const fetchTemplateList = async () => {
    let response = await dispatch(fetchTemplateListThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      // setPublicTemplateList(
      //   response?.payload?.data.map((item) => ({
      //     label: item.name,
      //     value: item.id,
      //   }))
      // );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup.string().trim().required("Name is required"),
      description: yup.string().required("Description is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateWorkflowThunk(values)
            : creatlatesteWorkflowThunk(values)
        );
        const { payload } = response;
        if (payload && payload?.status) {
          Navigate(PAGE_URL.EDIT_WORKFLOW, {
            state: {
              workflow_id: payload.data.workflow_id,
              trigger_id: payload.data.trigger_id,
            },
          });
          handleCloseDrawer();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: response?.payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const getConfigureActionValues = async () => {
    let response = await dispatch(getOneWorkflowThunk(selectedRowData.id));
    const { payload } = response;
    if (response) {
      const data = {
        id: payload?.data?.id,
        name: payload?.data?.name,
        // description: payload?.data?.description,
      };
      setInitialValues(data);
      formik.setValues(data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  // const handlechange = async (value) => {
  //   const response = await dispatch(fetchAllWorkflowKBfileThunk(value));
  //   if (response.payload.status) {
  //     // setGetKbFiles(response.payload.data);
  //   }
  // };

  useEffect(() => {
    if (drawerState !== "create") {
      getConfigureActionValues();
    }
    // eslint-disable-next-line
  }, [isOpenDrawer]);

  useEffect(() => {
    fetchKBs();
    fetchTemplateList();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={
          drawerState === "view"
            ? "Workflow Detail"
            : drawerState === "create"
            ? "Create Workflow"
            : "Edit Workflow"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        <DialogContent sx={ScrollBars}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Name"
                    size="small"
                    rows={4}
                    name="name"
                    disabled={drawerState === "view"}
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="form-error-message">
                      {formik.errors.name}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Description"
                    size="small"
                    rows={4}
                    name="description"
                    disabled={drawerState === "view"}
                    {...formik.getFieldProps("description")}
                    multiline
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {drawerState !== "view" && (
            <Box className="modal-footers" borderRadius="0px 0px 10px 10px">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Create
                </Button>
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateWorkflow;
