import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user.slice";
import common from "./slices/common.slice";
import chat from "./slices/chat.slice";
import knowledge from "./slices/knowledgeBase.slice";

export const store = configureStore({
    reducer: {
        user,
        common,
        knowledge,
        chat                        
    }
})