export const PAGE_URL = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
  KNOWLEDGE_BASE: "/knowledge-base",
  // CHAT: "/chat",
  CONFIGURE_CHAT: "/settings/configure-assistant",
  SETTINGS: "/settings",
  INTEGRATION: "/settings/integration",
  WORKSPACE: "/settings/workspace",
  SEARCH_DOC: "/search-document",
  USERS: "/settings/users",
  CONFIGURE_ACTIONS: "/settings/configureactions",
  PROJECT: "/project", 
  BILLINGS_AND_PLANS: "/settings/billings",
  SUBSCRIPTION_BILLINGS: "/settings/upgradesubscription",
  WORKFLOW: "/workflows",
  RUN_WORKFLOW: "/workflows/runworkflow",
  EDIT_WORKFLOW: "/workflows/editworkflow",
  EXTERNAL_ASSISTANT: "/settings/assistants",
  CREATE_EXTERNAL_ASSISTANT: "/settings/assistants/create",
  UPDATE_ASSISTANT: "/settings/assistants/update",
  ASSITANT_CHAT: "/assistant-chat",
  ASSITANT_CHAT_HISTORY: "/assistant-chat-history",
  CHANGE_PASSWORD: "/change-password",
  PRIVACY_POLICY: "/privacy-policy",
  // PRICING: "/pricing",
  SUBSCRIPTION: "/subscription",
  REPORT: "/reports", 
  REPORT_DASHBOARD: "/dashboard"
};

export const PAGE_TITLE = [
  { path: "/knowledge-base", title: "Knowledge Base" },
  { path: "/reports", title: "Reports" },
  { path: "/workflows", title: "Workflows" },
  {
    path: "/workflows/runworkflow",
    title: "Run Workflow",
    breadcrumb: [
      { path: "/workflows", title: "Workflows" },
      { path: "", title: "Run Workflow" },
    ],
  },
  {
    path: "/workflows/editworkflow",
    title: "Edit Workflow",
    breadcrumb: [
      { path: "/workflows", title: "Workflows" },
      { path: "", title: "Edit Workflow" },
    ],
  },
  // { path: "/chat", title: "Chat" },
  { 
    path: "/settings/configure-assistant", 
    title: "External Assistant", 
    breadcrumb: [
      {path: "/settings", title: "Settings"}, 
      {path: "", title: "External Assistant"}
    ]
  },
  { path: "/settings", title: "Settings" },
  { path: "/search-document", title: "Search Documents" },
  {
    path: "/settings/integration",
    title: "Integration",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Integration" },
    ],
  },
  {
    path: "/settings/workspace",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Workspace" },
    ],
  },
  {
    path: "/settings/assistants",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Assistants" },
    ],
  },
  {
    path: "/settings/users",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Users" },
    ],
  },
  {
    path: "/settings/assistants/create", 
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "/settings/assistants", title: "Assistants" },
      { path: "", title: "Create" },
    ]
  }, 
  {
    path: "/settings/assistants/update",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "/settings/assistants", title: "Assistants" },
      { path: "", title: "Update" },
    ]
    
  },
  {
    path: "/settings/configureactions",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Configure Actions" },
    ],
  },
  {
    path: "/project",
    // breadcrumb: [
    //   { path: "/project", title: "Projects" },
    //   // { path: "", title: "Project" },
    // ],
    title: "Projects"
  },
  {
    path: "/settings/billings",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Billings & Plans" },
    ],
  },
  {
    path: "/settings/upgradesubscription",
    breadcrumb: [
      { path: "/settings", title: "Settings" },
      { path: "", title: "Subscription & Billings" },
    ],
  },
  {
    path: "/assistants",
    title: "Assistants",
  },
  {
    path: "/assistants/create",
    breadcrumb: [
      { path: "/assistants", title: "Assistants" },
      { path: "", title: "Create Assistant" },
    ],
  },
  {
    path: "/assistants/update",
    breadcrumb: [
      { path: "/assistants", title: "Assistants" },
      { path: "", title: "Update Assistant" },
    ],
  },
  { path: "/assistant-chat", title: "Assistant Chat" },
  { path: "/assistant-chat-history", title: "Recent Chats" },
  { path: "/dashboard", title: "Dashboard" },
];
