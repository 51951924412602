import * as XLSX from "xlsx";

export const HandleDownload = (workflowHistoryList) => {
  const workbook = XLSX.utils.book_new();

  const ActionNameCheck = [];

  workflowHistoryList.map((item, i) => {
    if (ActionNameCheck.length && ActionNameCheck?.includes(item.action_name)) {
      ActionNameCheck.push(item.action_name + i);
    } else {
      ActionNameCheck.push(item.action_name);
    }
  });

  workflowHistoryList.forEach((action, index) => {
    const worksheet = XLSX.utils.json_to_sheet(action.action_response);
    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(
      workbook,
      worksheet,
      ActionNameCheck[index].substr(0, 30)
    );
  });

  const arrayBuffer = XLSX.write(workbook, {
    bookType: "xlsx",
    type: "array",
  });
  const blob = new Blob([arrayBuffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });

  const url = window.URL.createObjectURL(blob);

  // Create temporary anchor element
  const a = document.createElement("a");
  a.href = url;
  a.download = "workflow.csv";

  // Simulate click on anchor element
  document.body.appendChild(a);
  a.click();

  // Clean up
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};
