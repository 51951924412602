import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { setSnackbar } from "../../../redux/slices/common.slice";
import Select from "react-select";
import { API_URL } from "../../../constants/apiUrl.constant";
import "../../../styles/externalAssistant.scss";
import {
  getAllAssistantsThunk,
  createNewThredThunk,
  fetchThreadHistoryThunk,
} from "../../../redux/slices/assistants.slice";
import { useLocation } from "react-router-dom";
import EditChatName from "./EditChatName";
import * as Yup from "yup";
import { useFormik } from "formik";
import Markdown from "react-markdown";
import SendIcon from "../../../../src/assets/icons/send.png";
import chatIcon from "../../../../src/assets/icons/chat.svg";
import Pencil from "../../../../src/assets/icons/pencil.svg";
import { CONFIG_IMG } from "../../../constants/common.constant";
import AssistantSelectKB from "./AssistantSelectKB";
import { FaRegCopy } from "react-icons/fa";
import AssitantDetail from "./AssitantDetail";
import { log10 } from "chart.js/helpers";

const customStyles = {
  control: (base, { isDisabled }) => {
    return {
      ...base,
      background: "#FFFFFF",
      color: "#000",
      borderRadius: "8px",
      boxShadow: "none !important",
      minHeight: "44px",
      border: "1px solid #0D333F54",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  menu: (base) => ({
    ...base,
    background: "#FFFFFF",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    marginTop: 0,
    overflow: "auto",
    maxHeight: "280px",
  }),
  menuList: (base) => ({
    ...base,
    padding: "0",
    color: "#030F1C",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    overflow: "auto",
    maxHeight: "280px",
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb ": {
      backgroundColor: " #cccccc",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#273167",
    "&:hover": {
      color: "#273167",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "12px 16px",
    backgroundColor: state.isSelected ? "#F2F4F7" : "#FEFEFF",
    color: state.isSelected ? "#0075FF" : "#000",
    borderBottom: "1px solid #EAECF0",
    borderRadius: "8px 8px 0px 0px",
    "&:hover": {
      backgroundColor: state.isSelected ? "#F2F4F7" : "#F2F4F7",
      color: state.isSelected ? "#0075FF" : "#005ECC",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "1em",
    color: "#000",
    fontWeight: 400,
  }),
};

const AssistantChat = ({ searchModule = false }) => {
  const [messages, setMessages] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabledInput, setIsDisabledInput] = useState(false);
  const [newMessage, setNewMessage] = useState();
  const [newAnswer, setNewAnswer] = useState();
  const [sendMessage, setSendMessage] = useState("");
  const [assistantList, setAssistantList] = useState([]);
  const [threadDetails, setThreadDetails] = useState(null);
  const [selectedAssistant, setSelectedAssistant] = useState({});
  const [selectedDoc, setSelectedDoc] = useState();
  const [viewId, setViewId] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [selectedDocPage] = useState(1);
  const [token, setToken] = useState({});
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const messagesEndRef = useRef(null);
  let uniqueFilenames = new Set();
  let location = useLocation();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleViewDetails = () => {
    setIsDetailsOpen(true);
  };

  const scrollToBottom = () => {
    if (messagesEndRef) messagesEndRef?.current?.scrollIntoView({});
  };

  const fetchAllAssistants = async () => {
    let response = await dispatch(
      getAllAssistantsThunk({
        search: "",
      })
    );
    const { payload } = response;
    if (payload && payload.status) {
      setAssistantList(
        payload?.data.map((item) => ({
          label: item.name,
          value: item.id,
          is_qa: item.is_qa,
        }))
      );
      if (location?.state?.assistant_id) {
        const selectedData = payload?.data?.find(
          (item) => item?.id === location?.state?.assistant_id
        );
        setSelectedAssistant({
          label: selectedData?.name,
          value: selectedData?.id,
          is_qa: selectedData?.is_qa,
        });
        setViewId(location?.state?.assistant_id);
      }
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchNewThred = async () => {
    let response = await dispatch(
      createNewThredThunk({
        assistant_id: location?.state?.assistant_id ?? null,
      })
    );
    const { payload } = response;
    if (payload && payload.status) {
      setThreadDetails(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchChatHistory = async () => {
    setMessages([]);
    setNewMessage("");
    setNewAnswer("");
    let response = await dispatch(
      fetchThreadHistoryThunk(location?.state?.thread_id)
    );
    const { payload } = response;
    if (payload && payload?.status) {
      await setMessages([
        ...messages,
        ...convertToFormattedArray(payload?.data),
      ]);
      setToken({ ...token, ...payload?.token });
      await scrollToBottom();
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllAssistants();
    if (location?.state?.thread_id) {
      setThreadDetails({
        thread_id: location?.state?.thread_id,
        thread_name: location?.state?.thread_name,
      });
      fetchChatHistory();
    } else {
      fetchNewThred();
    }
    // eslint-disable-next-line
  }, []);

  function convertToFormattedArray(input) {
    const resultArray = [];

    input.forEach((item) => {
      // User's question
      resultArray.push({
        text: item.question,
        sender: "user",
      });

      // AI's answer
      resultArray.push({
        text: item.answer,
        sender: "receiver",
        citations: item?.other_response?.citations,
      });
    });

    return resultArray;
  }

  const handleSendMessage = async (e) => {
    await scrollToBottom();
    e.preventDefault();
    if (newMessage && newAnswer) {
      setMessages((pre) => [...pre, newMessage, newAnswer]);
    } else if (newMessage) {
      setMessages((pre) => [...pre, newMessage]);
    } else if (newAnswer) {
      setMessages((pre) => [...pre, newAnswer]);
    }
    setNewMessage(null);
    setNewAnswer(null);
    if (sendMessage.length === 0) {
      return;
    }
    setNewMessage({
      text: sendMessage,
      sender: "user",
    });
    setSendMessage("");
    dispatchMessage();
  };


  const fetchAnswer = async (payload) => {
    await scrollToBottom();
    setIsDisabledInput(true);
    try {
      setNewAnswer({
        isLoading: true,
        text: "",
        sender: "receiver",
      });
      await scrollToBottom();

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${API_URL.SEND_ASSISTANTS_MESSAGE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (response.ok) {
        setIsDisabledInput(false);
      }

      if (!response.ok || !response.body) {
        setIsDisabledInput(false);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Internal server error",
          })
        );
      }
      setNewAnswer({
        isLoading: false,
        text: "",
        sender: "receiver",
      });

      const combineDocuments = (data) => {
        let allDocuments = [];
        data?.forEach((item) => {
          item?.citations?.forEach((doc) => {
            // Check if the filename already exists in uniqueFilenames Set
            if (!uniqueFilenames.has(doc.filename)) {
              // If the filename doesn't exist, add the document to allDocuments
              allDocuments.push({ ...doc });
              setToken({ ...token, ...item.sas });
              uniqueFilenames.add(doc.filename);
            }
          });
        });
        return allDocuments;
      };

      const reader = response.body.getReader();
      const decoder = new TextDecoder();


      let allChunk = "" ; 

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        let decodedChunk = decoder.decode(value, { stream: true });
        decodedChunk = decodedChunk.replace("'", "@@@");

        try {
          let temp = JSON.parse(decodedChunk) ; 
          allChunk = decodedChunk ; 
        } catch (error) {
          allChunk = allChunk + decodedChunk ; 
          
        }
        
        // allChunk = 
        let jsonData = [];
        if (allChunk.includes("}")) {
          try {
            // Parse the individual JSON object
            if (allChunk.includes("}{")) {
              
              allChunk = allChunk.replace(/}{/g, "}}{{");
              let correctedJsonStrings = allChunk.split("}{");
              correctedJsonStrings.forEach((chunk) => {
                let chunkJSON = JSON.parse(chunk);
                jsonData.push(
                  JSON.parse(JSON.stringify(chunkJSON).replace("@@@", "'"))
                );
              });
            } else {
              let chunkJSON = JSON.parse(allChunk);
              jsonData.push(
                JSON.parse(JSON.stringify(chunkJSON).replace("@@@", "'"))
              );
            }
          } catch (error) {
            allChunk = allChunk.replace(/}}{{/g, "}{");
          }
        }
        let citations = [];
        if (!searchModule)
          citations = [...citations].concat(combineDocuments(jsonData));
        // setNewAnswer((prevValue) => ({
        //   text: `${prevValue?.text ?? ""}${jsonData
        //     .map((m) => m.content)
        //     .join("")}`,
        //   sender: "receiver",
        //   citations: citations,
        // }));
        setNewAnswer((prevValue) => ({
          text: `${jsonData
            .map((m) => m.content)
            .join("")}`,
          sender: "receiver",
          citations: citations,
        }));
        uniqueFilenames.clear();
        await scrollToBottom();
      }
    } catch (error) {
      console.log("error-->", error);
    }
  };

  const formik = useFormik({
    initialValues: { kb: {} },
    validationSchema: Yup.object({
      kb: Yup.object().required("kb selection is require"),
    }),
  });

  const dispatchMessage = async () => {
    await scrollToBottom();
    try {
      let payload = {
        question: sendMessage,
        assistant_id: selectedAssistant?.value,
        thread_id: threadDetails?.thread_id,
        ...(!selectedAssistant.is_qa ? formik.values : {}),
      };
      await fetchAnswer(payload);
      await scrollToBottom();
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const Message = ({ text, sender, isLoading, citations = [] }) => {
    const isSender = sender;
    const WrapperStyle = {
      display: "flex",
      justifyContent: isSender ? "flex-end" : "flex-start",
      marginBottom: "20px",
      position: "reletive",
    };
    const messageStyle = {
      padding: "8px",
      borderRadius: "5px",
      background: isSender ? "#CA9688" : "#BBDEED", // Adjust the background color as needed
      color: isSender ? "#FFFFFF" : "black", // Adjust the text color as needed
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "22px",
      maxWidth: "70%",
    };

    const copyText = async () => {
      try {
        await navigator.clipboard.writeText(text);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Content copied to clipboard.",
          })
        );
      } catch (err) {
        console.error("Failed to copy content: ", err);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Failed to copy content: " + err,
          })
        );
      }
    };

    return (
      <div style={WrapperStyle}>
        <div className="message-item" style={messageStyle}>
          {isLoading ? (
            <Stack flexDirection={"row"}>
              <div className="chat-loader-wrapper">
                <div className="loader">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              just a moment
            </Stack>
          ) : (
            <>
              <Typography variant="p">
                <Markdown>{text}</Markdown>
              </Typography>
              <Stack direction="row" flexWrap="wrap" spacing={1}>
                {!isSender && citations.length > 0 && <b>CITIATIONS:</b>}
                {!isSender &&
                  citations.length > 0 &&
                  citations.map((citation, index) => (
                    <span
                      key={index} // Add a unique key for each item when using map
                      className="citation-preview"
                      onClick={() => setSelectedDoc(citation)}
                    >
                      {(citation?.filename.split("/")[0] === "Google drive" ||
                        citation.filename.split("/")[0] === "One drive" ||
                        citation.filename.split("/")[0] === "Notion") && (
                        <img
                          src={CONFIG_IMG[citation.filename.split("/")[0]]}
                          alt={citation.filename.split("/")[0]}
                          style={{
                            width: "15px",
                            marginRight: "5px",
                          }}
                        />
                      )}
                      {` ${
                        citation.filename.split("/").length === 1
                          ? citation?.filename
                          : citation?.filename.split("/")[1]
                      }${citations.length !== index + 1 ? "," : ""}`}
                    </span>
                  ))}
              </Stack>
              {!isSender && (
                <Stack
                  direction="row"
                  flexWrap="wrap"
                  justifyContent={"end"}
                  spacing={1}
                >
                  <span onClick={copyText} className="copy-button">
                    <FaRegCopy /> Copy
                  </span>
                </Stack>
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Box
        className={`content-header assistant-chat-header`}
        justifyContent={"end"}
        gap={"20px !important"}
      >
        <Grid
          container
          spacing={3}
          margin={0}
          width={"100% !important"}
          justifyContent={"end"}
          alignItems={"baseline"}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={8}
            lg={9}
            xl={9}
            display={"flex"}
            paddingX={"0px !important"}
            alignItems="center"
          >
            <Select
              sx={{ width: "100% !important" }}
              value={selectedAssistant}
              styles={customStyles}
              name="kbs"
              options={assistantList}
              className="basic-multi-select assistant-chat-select"
              onChange={(e) => {
                setSelectedAssistant(e);
                setViewId(e.value);
              }}
              isDisabled={location?.state?.assistant_id ? true : false}
              classNamePrefix="select"
              placeholder={"Select Assitant..."}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={3}
            xl={3}
            justifyContent={"end"}
            display={"flex"}
            paddingX={"0px !important"}
          >
            <div
              style={{ margin: "0 10px" }}
              onClick={() => {
                setIsEdit(true);
              }}
            >
              <img
                alt="chat"
                src={chatIcon}
                width={22}
                height={22}
                style={{ margin: "0 10px" }}
              />
              {threadDetails?.thread_name
                ? `${
                    threadDetails?.thread_name.length > 25
                      ? threadDetails?.thread_name.substring(0, 25) + "..."
                      : threadDetails?.thread_name
                  }`
                : ""}
              <img
                alt="edit"
                src={Pencil}
                width={22}
                height={22}
                className="actionIcon"
                cursor={"pointer"}
              />
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          width={"100%"}
          margin={"auto"}
          justifyContent={"end"}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            display={"flex"}
            paddingRight={"0px"}
            padding={"0px !important"}
            alignItems="center"
            justifyContent="end"
          >
            {!selectedAssistant.is_qa &&
              selectedAssistant.is_qa !== undefined && (
                <Box margin={"0 5px !important"}>
                  <Button
                    maxWidth={"150px !important"}
                    variant="contained"
                    size="large"
                    className="primary-button add-btn"
                    disableRipple
                    onClick={handleOpen}
                  >
                    <Typography variant="span" width={"max-content"}>
                      Select KB
                    </Typography>
                  </Button>
                </Box>
              )}
            <Box margin={"0 5px !important"}>
              <Button
                maxWidth={"150px !important"}
                variant="contained"
                size="large"
                className="primary-button add-btn"
                disableRipple
                onClick={handleViewDetails}
                disabled={!viewId}
              >
                <Typography variant="span" width={"max-content"}>
                  View Assistant Details
                </Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Grid
        container
        spacing={3}
        margin={"auto !important"}
        width={"100% !important"}
      >
        <Grid item xs={selectedDoc ? 6 : 12} padding={"0px !important"}>
          <Box className="chat-main-box">
            <Paper
              elevation={0}
              className="chat-main-box-paper"
              marginTop={"0px !important"}
            >
              <div className={`chat-box-container scrolling-area`}>
                {messages.map((message, index) => (
                  <Message
                    key={index}
                    text={message?.text}
                    sender={message?.sender === "user"}
                    citations={message?.citations}
                  />
                ))}
                {newMessage && (
                  <Message key="new" text={newMessage.text} sender="user" />
                )}
                {newAnswer && (
                  <Message
                    key="newanswer"
                    isLoading={newAnswer.isLoading}
                    text={newAnswer.text}
                    sender={false}
                    citations={newAnswer?.citations}
                  />
                )}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={messagesEndRef}
                ></div>
              </div>
              <form onSubmit={handleSendMessage}>
                <Paper
                  elevation={0}
                  className={`chat-box-input-container ${
                    Object.keys(selectedAssistant).length === 0
                      ? "disable-input-opacity"
                      : ""
                  }`}
                >
                  <InputBase
                    disabled={
                      !Object.keys(selectedAssistant).length ||
                      isDisabledInput ||
                      (!Object.keys(formik.values.kb).length &&
                        !selectedAssistant.is_qa)
                    }
                    className={`chat-input ${
                      Object.keys(selectedAssistant).length === 0
                        ? "disable-input"
                        : ""
                    }`}
                    value={sendMessage}
                    onChange={(e) => setSendMessage(e.target.value)}
                    placeholder="Type your questions here..."
                    inputProps={{ "aria-label": "search" }}
                  />

                  <IconButton
                    type="submit"
                    className={`sendQ-btn ${
                      Object.keys(selectedAssistant).length === 0
                        ? "disable-input"
                        : ""
                    }`}
                    aria-label="search"
                  >
                    <img alt="send" src={SendIcon} width={32} height={32} />
                  </IconButton>
                </Paper>
              </form>
            </Paper>
          </Box>
        </Grid>
        {selectedDoc && (
          <Grid item xs={6} sx={{ mt: "5px", height: "calc(100vh - 190px)" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                margin: "15px 0",
              }}
            >
              <Button
                sx={{ color: "#fff !important" }}
                type="button"
                className="primary-button"
                aria-label="search"
                onClick={() => {
                  scrollToBottom();
                  setSelectedDoc();
                }}
              >
                <CloseIcon />
                Close Preview
              </Button>
            </div>
            <iframe
              src={`${selectedDoc.location}?${
                token[selectedDoc?.kb]
              }#page=${selectedDocPage}`}
              title={selectedDoc?.filename}
              height="100%"
              width="100%"
            />
          </Grid>
        )}
      </Grid>
      {isEdit && (
        <EditChatName
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          threadDetails={threadDetails}
          setThreadDetails={setThreadDetails}
        />
      )}
      {open && (
        <AssistantSelectKB
          open={open}
          handleClose={handleClose}
          formik={formik}
        />
      )}
      {isDetailsOpen && (
        <AssitantDetail
          isOpen={isDetailsOpen}
          viewId={viewId}
          handleCloseView={() => {
            setIsDetailsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default AssistantChat;
