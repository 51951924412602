import React from "react";

const CopyToClipboard = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.667 2.66666H12.0003C12.3539 2.66666 12.6931 2.80714 12.9431 3.05719C13.1932 3.30724 13.3337 3.64638 13.3337 4V13.3333C13.3337 13.687 13.1932 14.0261 12.9431 14.2761C12.6931 14.5262 12.3539 14.6667 12.0003 14.6667H4.00033C3.6467 14.6667 3.30756 14.5262 3.05752 14.2761C2.80747 14.0261 2.66699 13.687 2.66699 13.3333V4C2.66699 3.64638 2.80747 3.30724 3.05752 3.05719C3.30756 2.80714 3.6467 2.66666 4.00033 2.66666H5.33366"
        stroke="white"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99967 1.33334H5.99967C5.63148 1.33334 5.33301 1.63181 5.33301 2V3.33334C5.33301 3.70153 5.63148 4 5.99967 4H9.99967C10.3679 4 10.6663 3.70153 10.6663 3.33334V2C10.6663 1.63181 10.3679 1.33334 9.99967 1.33334Z"
        stroke="white"
        strokeWidth ="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CopyToClipboard;
