import React, { forwardRef, useEffect, useState } from "react";
import CustomizedDialog from "../Dialog";
import {
  Box,
  CircularProgress,
  DialogContent,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { VscPreview } from "react-icons/vsc";
import { TreeItem2 } from "@mui/x-tree-view/TreeItem2";
import { RichTreeView } from "@mui/x-tree-view";
import { useTreeItem2Utils } from "@mui/x-tree-view/hooks";
import {
  getAllDefaultAssistantThunk,
  getAllKBFilesThunk,
  getOneAssistantThunk,
} from "../../../redux/slices/assistants.slice";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { TbListDetails } from "react-icons/tb";

const AssitantDetail = ({ isOpen, viewId, handleCloseView }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [viewDetails, setViewDetails] = useState([]);
  const [selectedKbFiles, setSelectedKbFile] = useState([]);
  const [defaultAssistant, setDefaultAssistant] = useState([]);
  const dispatch = useDispatch();

  const SelectedCustomTreeItem = forwardRef(function MyTreeItem(props, ref) {
    const { interactions } = useTreeItem2Utils({
      itemId: props.itemId,
      children: props.children,
    });

    const handleContentClick = (event) => {
      event.defaultMuiPrevented = true;
      interactions.handleSelection(event);
    };

    const handleIconContainerClick = (event) => {
      event.stopPropagation();
      interactions.handleExpansion(event);
    };

    return (
      <TreeItem2
        {...props}
        ref={ref}
        slotProps={{
          content: { onClick: handleContentClick },
          iconContainer: { onClick: handleIconContainerClick },
        }}
      />
    );
  });

  const ScrollBars = {
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  };

  const fetchAllDefaultAssistant = async () => {
    let response = await dispatch(getAllDefaultAssistantThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      setDefaultAssistant(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchAssistantDetails = async (id) => {
    setIsLoading(true);
    let response = await dispatch(getOneAssistantThunk(id));
    let kbFilesresponse = await dispatch(getAllKBFilesThunk());
    const { payload } = response;
    const kbfile = payload?.data?.kb;
    if (payload && payload?.status) {
      setViewDetails(payload?.data);
      setIsLoading(false);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    if (kbFilesresponse.payload) {
      const kbs = (kbFilesresponse?.payload?.data || []).map((item) => ({
        id: item.kbId,
        label: item.kbName,
        children: item?.kbfiles?.files
          ? item?.kbfiles?.files?.map((ele) => ({
              id: ele.fileurl,
              label: ele.filename,
              parentId: item.kbId,
            }))
          : [],
      }));
      let value = [];
      Object.keys(kbfile).length &&
        Object.keys(kbfile).forEach((item) => {
          const findedKB = kbs?.find((data) => data.id === Number(item));
          kbfile[item]?.length !== findedKB?.children?.length
            ? kbfile[item].forEach((ele) => {
                const fileData = findedKB?.children?.filter(
                  (data) => data.id === ele.fileurl
                );
                if (value.find((valueData) => valueData.id === Number(item))) {
                  value = value.map((valueEle) => {
                    return {
                      ...valueEle,
                      children: [
                        ...valueEle.children,
                        ...(Number(item) === valueEle.id ? fileData : []),
                      ],
                    };
                  });
                } else {
                  value.push({
                    ...findedKB,
                    children: [...(fileData ?? [])],
                  });
                }
              })
            : value.push(findedKB);
        });
      setSelectedKbFile(value);
    }
  };

  useEffect(() => {
    fetchAssistantDetails(viewId);
    fetchAllDefaultAssistant();
    // eslint-disable-next-line
  }, []);

  return (
    <CustomizedDialog
      className="modal-scroll"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={"View Assitant Details"}
      open={isOpen}
      handleClose={handleCloseView}
    >
      <DialogContent sx={ScrollBars}>
        {isLoading ? (
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            height={"50vh"}
          >
            <CircularProgress />
          </Box>
        ) : (
          <Box>
            <Grid container spacing={4}>
              <Grid item xs={12} md={12} lg={12}>
                <Stack
                  sx={{
                    background: "#f3f6f9",
                    padding: "10px",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#273167",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap="10px">
                    <TbListDetails />
                    <Typography
                      sx={{
                        color: "#273167",
                        fontSize: "16px !important",
                      }}
                    >
                      Basic Details
                    </Typography>
                  </Stack>
                  <hr style={{ margin: "10px 0" }}></hr>
                  <Stack
                    flexDirection="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        color: "#273167 !important",
                        fontSize: "18px !important",
                      }}
                    >
                      Name
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey !important",
                        fontSize: "16px !important",
                        marginX: "20px",
                      }}
                    >
                      {viewDetails?.name}
                    </Typography>
                  </Stack>
                  <Stack
                    flexDirection="row"
                    alignItems="baseline"
                    justifyContent="space-between"
                  >
                    <Typography
                      sx={{
                        color: "#273167 !important",
                        fontSize: "18px !important",
                      }}
                    >
                      Description
                    </Typography>
                    <Typography
                      sx={{
                        color: "grey !important",
                        fontSize: "16px !important",
                        marginX: "20px",
                      }}
                    >
                      {`${
                        viewDetails?.description?.length > 90
                          ? viewDetails?.description.substring(0, 90) + "..."
                          : viewDetails?.description
                      }`}
                    </Typography>
                  </Stack>
                </Stack>
              </Grid>

              <Grid item xs={12} md={12} lg={12}>
                <Stack
                  sx={{
                    background: "#f3f6f9",
                    padding: "10px",
                    borderRadius: "5px",
                    border: 1,
                    borderColor: "#273167",
                  }}
                >
                  <Stack flexDirection="row" alignItems="center" gap="10px">
                    <VscPreview />
                    <Typography
                      sx={{
                        color: "#273167",
                        fontSize: "16px !important",
                      }}
                    >
                      Instructions
                    </Typography>
                  </Stack>
                  <hr style={{ margin: "10px 0" }}></hr>
                  <Stack>
                    <Typography
                      //   variant="h6"
                      sx={{
                        color: "#273167 !important",
                        fontSize: "18px !important",
                      }}
                    >
                      {viewDetails.assistant === null
                        ? "Custom Assitant"
                        : defaultAssistant
                            ?.filter(({ id }) => id === viewDetails?.assistant)
                            .map((item) => item.name)}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{ color: "grey" }}
                    dangerouslySetInnerHTML={{
                      __html: `${
                        viewDetails?.instruction?.length > 120
                          ? viewDetails?.instruction.substring(0, 120) + "..."
                          : viewDetails?.instruction
                      }`,
                    }}
                  />
                </Stack>
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={12}>
                {viewDetails?.is_qa === true ? (
                  <Grid item paddingTop={"0px !important"}>
                    <Typography
                      variant="h6"
                      sx={{
                        color: "#273167",
                      }}
                    >
                      Selected KB
                    </Typography>
                    <RichTreeView
                      aria-label="icon expansion"
                      slots={{ item: SelectedCustomTreeItem }}
                      items={selectedKbFiles}
                    />
                  </Grid>
                ) : (
                  "Note*- It's free form Assistant"
                )}
              </Grid>
            </Grid>
          </Box>
        )}
      </DialogContent>
    </CustomizedDialog>
  );
};

export default AssitantDetail;
