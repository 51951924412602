import {
  Select,
  MenuItem,
  Typography,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import moment from "moment";
import { BarChart } from "@mui/x-charts/BarChart";
import { PieChart } from "@mui/x-charts/PieChart";
import DateRangePicker from "./dateRangePicker";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/common.slice";
import {
  getAllUsersThunk,
  getDashboardReportCountThunk,
  getDashboardSafetyBuddyCountThunk,
  getProjectSelectionThunk,
} from "../../redux/slices/tenant.slice";
import { getDashboardCountThunk } from "../../redux/slices/tenant.slice";

const customStyles = {
  "& .MuiInputBase-input.MuiOutlinedInput-input": {
    background: "#FFFFFF",
    borderRadius: "5px",
    boxShadow: "none !important",
    border: "1px solid #0D333F54",
    cursor: "pointer",
    padding: "14px",
    pr: 3,
    width: 160,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none !important",
  },
};
const ReportDashbaord = () => {
  let currentDate = new Date();
  let firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );
  firstDayOfMonth = moment(firstDayOfMonth).format("MM-DD-YYYY");
  currentDate = moment(currentDate).format("MM-DD-YYYY");

  // Report related date range
  const [dateRange, setDateRange] = useState([firstDayOfMonth, currentDate]);
  const [userTypeFilter, setUserTypeFilter] = useState("all");
  const [projectFilter, setProjectFilter] = useState("all") ; 

  // report inside date range
  const [reportInsideDateRange, setReportInsideDateRange] = useState([
    firstDayOfMonth,
    currentDate,
  ]);
  const [reportInsideUserFilter, setReportInsideUserFilter] = useState("all");
  const [reportInsideData, setReportInsideData] = useState([]);
  const [reportXLabel, setReportXLabel] = useState([]) ;
  const [reportSafetyBuddyCount, setReportSafetyBudddyCount] = useState([]) ;
  const [reportCount, setReportCount] = useState([]) ;

  const [userData, setUserData] = useState([]);
  const [projectData, setProjectData] = useState([]) ; 
  const [dashboardCount, setDashboardCount] = useState({});

  const handleDateRangeChange = (newDateRange) => {
    setDateRange(newDateRange);
  };

  const handleReportInsideDateRange = (newDateRange) => {
    setReportInsideDateRange(newDateRange);
  };

  
  const highlightScope = {
    highlighted: "series",
    faded: "global",
  };

  const dispatch = useDispatch();

  // Fetch all user information
  const fetchAllUser = async () => {
    let response = await dispatch(
      getAllUsersThunk({
        page_number: 1,
        page_size: 1000,
        status: "all",
      })
    );
    const { payload } = response;
    if (payload?.status) {
      let temp = [{ label: "All User", value: "all" }];

      payload?.data?.map((element) => {
        temp.push({
          label: `${element?.first_name} ${element?.last_name}`,
          value: element?.id,
        });
        setUserData(temp);
      });
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  // Fetch all porojet selection related information 
  const fetchAllProject = async () => {
    let response = await dispatch(
      getProjectSelectionThunk()
    )

    const {payload} = response ; 

    if (payload?.status){
      let temp = [{ label: "All Project", value: "all" }];
      payload?.data?.project?.map((element) => {
        temp.push({
          label: element?.name, 
          value: element?.id
        })
      })
      setProjectData(temp) ; 
    }
  }

  const fetchDashboardCount = async () => {
    let response = await dispatch(getDashboardCountThunk({}));
    const { payload } = response;

    if (payload?.status) {
      setDashboardCount(payload?.data);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
  };

  const fetchDashboardReportCount = async () => {
    let send_payload = {};
    if (reportInsideDateRange?.length > 0) {
      send_payload["start"] =
        reportInsideDateRange[0] == ""
          ? undefined
          : moment(reportInsideDateRange[0]).format("YYYY-MM-DD");
      send_payload["to"] =
        reportInsideDateRange[1] == ""
          ? undefined
          : moment(reportInsideDateRange[1]).format("YYYY-MM-DD");
    }

    if (reportInsideUserFilter !== "all") {
      send_payload["user_id"] = reportInsideUserFilter;
    }

    let response = await dispatch(getDashboardReportCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {
      let tempData = [
        {
          id: 0,
          value: payload?.data?.approved_report,
          label: "Completed Report",
          color: "#036185"
        },
        {
          id: 1,
          value: payload?.data?.pending_report,
          label: "Pending Report",
        },
      ];
      setReportInsideData(tempData);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchSafetyBuddyReportCount = async () => {
    let send_payload = {};
    if (dateRange?.length > 0) {
      send_payload["start"] =
      dateRange[0] == ""
          ? undefined
          : moment(dateRange[0]).format("YYYY-MM-DD");
      send_payload["end"] =
      dateRange[1] == ""
          ? undefined
          : moment(dateRange[1]).format("YYYY-MM-DD");
    }

    if (userTypeFilter !== "all") {
      send_payload["user_id"] = userTypeFilter;
    }

    if (projectFilter !== "all"){
      send_payload["project_id"] = projectFilter
    }
    
    let response = await dispatch(getDashboardSafetyBuddyCountThunk(send_payload));
    const { payload } = response;

    if (payload?.status) {

      let dates = [] ; 
      let currentDate = new Date(dateRange[0]);
      const finalDate = new Date(dateRange[1]);

      if (currentDate > finalDate) {
        throw new Error("startDate must be before endDate");
      }

      while (currentDate <= finalDate) {
        dates.push(new Date(currentDate));
        currentDate.setDate(currentDate.getDate() + 1);
      }

      let x_label = [] ; 
      let safety_count = [] ; 
      let report_count = [] ; 

      dates?.map((element) => {
        let temp_date = moment(element).format("YYYY-MM-DD") ; 
        let safety_buddy_push = false ; 
        let report_push = false ; 
 
        payload?.data?.safety_buddy?.map((dateCount) => {
          if (temp_date == dateCount?.date){
            x_label.push(temp_date) ; 
            safety_count.push(dateCount?.count) ; 
            safety_buddy_push = true; 
          }
        })

        payload?.data?.report?.map((dateCount) => {
          if (temp_date == dateCount?.date){
            if (!x_label.includes(temp_date)){
              x_label.push(temp_date) ; 
            }
            report_count.push(dateCount?.count) ; 
            report_push = true ; 
          }
        })

        if (safety_buddy_push == false && report_push == true){
          safety_count.push(0) ; 
        }

        if (report_push == false && safety_buddy_push == true){
          report_count.push(0) ; 
        }

      })

      setReportXLabel(x_label) ; 
      setReportSafetyBudddyCount(safety_count) ; 
      setReportCount(report_count) ; 

    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllUser();
    fetchDashboardCount();
    fetchAllProject() ; 
  }, []);

  useEffect(() => {
    fetchDashboardReportCount();
  }, [reportInsideUserFilter, reportInsideDateRange]);

  useEffect(() => {
    fetchSafetyBuddyReportCount();
  }, [userTypeFilter, dateRange, projectFilter]);

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Users
              </Typography>
              <Typography className="dashboard-card-count">
                {dashboardCount?.user}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Reports
              </Typography>
              <Typography className="dashboard-card-count">
                {dashboardCount?.report}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card className="dashboard-card">
            <CardContent>
              <Typography variant="h5" className="dashboard-card-title">
                Total Safety Buddy Images
              </Typography>
              <Typography className="dashboard-card-count">
                {dashboardCount?.safety_check}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Safety buddy report / Report insides title  */}

      <div className="graph-main-div">
        <div className="dashboard-graph-div">
          <h5 className="graph-title">Safety buddy/Report</h5>

          <div className="graph-filter-div" style={{paddingBottom: 20, gap: "15px"}}>
            <div
              style={{
                justifyContent: "flex-start",
                textAlign: "left",
              }}
            >
              <DateRangePicker
                dateRange={dateRange}
                onDateRangeChange={handleDateRangeChange}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </div>

            <div >
              <Select
                sx={customStyles}
                name="status"
                className="dashboard-selection"
                style={{ width: "fit-content", marginRight: "0px" }}
                onChange={(event) => {
                  setUserTypeFilter(event.target.value);
                }}
                value={userTypeFilter}
                classNamePrefix="select"
                components={{
                  IndicatorSeparator: () => null,
                }}
              >
                {userData.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </div>
            
            <div >
              <Select
                sx={customStyles}
                name="status"
                className="dashboard-selection"
                style={{ width: "fit-content" }}
                onChange={(event) => {
                  setProjectFilter(event.target.value);
                }}
                value={projectFilter}
                placeholder="Selecte Project"
                classNamePrefix="select"
                components={{
                  IndicatorSeparator: () => null,
                }}
              >
                {projectData.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </div>
          
          </div>

          <div
            style={{
              flex: 1,
              marginTop: "auto",
              marginBottom: "auto",
              height: "20vw",
            }}
          >
            <BarChart
              height={300}
              series={[
                {
                  label: "Safety buddy",
                  data: reportSafetyBuddyCount,
                  color: "#036185"
                },
                {
                  label: "Report",
                  data: reportCount,
                },
              ].map((s) => ({ ...s, highlightScope }))}
              skipAnimation={true}
              xAxis={[{scaleType: "band", data: reportXLabel}]}
            />
          </div>
        </div>

        <div className="dashboard-graph-div">
          <h5 className="graph-title">Report</h5>

          <div className="graph-filter-div" 
            style={{paddingBottom: 40, gap: "15px"}}>

            <div
              style={{
                justifyContent: "flex-start",
                textAlign: "left",
              }}
            >
              <DateRangePicker
                dateRange={reportInsideDateRange}
                onDateRangeChange={handleReportInsideDateRange}
                machineFilter={null} // Adjust according to your use case
                disableClearable={false} // or true depending on your requirement
              />
            </div>

            <div>
              <Select
                sx={customStyles}
                name="status"
                className="dashboard-selection"
                style={{ width: "fit-content" }}
                onChange={(event) => {
                  setReportInsideUserFilter(event.target.value);
                }}
                value={reportInsideUserFilter}
                classNamePrefix="select"
                components={{
                  IndicatorSeparator: () => null,
                }}
              >
                {userData.map((item) => (
                  <MenuItem value={item.value}>{item.label}</MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div
            style={{
              flex: 1,
              marginTop: "auto",
              marginBottom: "auto",
              paddingRight: 20,
              height: "20vw",
            }}
          >
            <PieChart
              series={[
                {
                  data: reportInsideData,
                  backgroundColor: ["red", "green"]
                },
              ]}
              height={200}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportDashbaord;
