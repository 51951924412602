import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/apiUrl.constant";
import axios from "axios";

// Define a Redux action to set data
export const setDataAction = (data) => ({
    type: 'chat/setData',
    payload: data,
});

export const sendMessageThunk = createAsyncThunk(
    'sendMessage', async (page, { dispatch }) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}${API_URL.SEND_CHAT_MESSAGE}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${JSON.parse(localStorage?.getItem('user')).access_token}`
                },
                body: JSON.stringify(page),
            });
            if (!response.ok || !response.body) {
                throw response.statusText;
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();

            let data = [];

            while (true) {
                const { value, done } = await reader.read();
                if (done) {
                    break;
                }

                let decodedChunk = decoder.decode(value, { stream: true });
                decodedChunk = decodedChunk.replace("'", "@@@");
                // data += decodedChunk;

                if (decodedChunk.includes('}')) {
                    try {
                        // Parse the individual JSON object
                        if (decodedChunk.includes('}{')) {
                            decodedChunk = decodedChunk.replace(/}{/g, '}}{{');
                            let correctedJsonStrings = decodedChunk.split("}{");
                            correctedJsonStrings.forEach((chunk) => {
                                let jsonData = JSON.parse(chunk);
                                jsonData = JSON.parse(JSON.stringify(jsonData).replace("@@@", "'"));
                                // Dispatch the action to set data in the Redux store
                                data.push(jsonData);
                            })
                        }
                        else {
                            let jsonData = JSON.parse(decodedChunk);
                            jsonData = JSON.parse(JSON.stringify(jsonData).replace("@@@", "'"));
                            // Dispatch the action to set data in the Redux store
                            data.push(jsonData);
                        }
                    } catch (error) {
                        // Handle JSON parsing errors
                        console.error('Error parsing JSON:', error);
                    }
                }
            }
            dispatch(setDataAction(data));
            return data;
        } catch (error) {
            // You might want to handle errors here
            return {
                "status": false,
                "message": "Internal server error"
            }
        }
    }
);

export const fetchChatHistoryThunk = createAsyncThunk(
    'fetchChatHistory', async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}${API_URL.FETCH_CHAT_HISTORY}`,
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage?.getItem('user')).access_token}`,
                    }
                });
            return response.data;
        } catch (error) {
            return error.response.data
        }
    }
);

export const clearChatHistoryThunk = createAsyncThunk(
    'clearChatHistory', async () => {
        try {
            const response = await axios.patch(
                `${process.env.REACT_APP_API_URL}${API_URL.CLEAR_CHAT_HISTORY}`,
                {},
                {
                    headers: {
                        Authorization: `Bearer ${JSON.parse(localStorage?.getItem('user')).access_token}`,
                    }
                }
            );
            return response.data;
        } catch (error) {
            return error.response.data;
        }
    }
);


// chat slice
const chatSlice = createSlice({
    name: 'chat',
    initialState: {
        isLoading: false,
        data: null,
        isError: false,
        error: null,
    },
    reducers: {
        // Add a reducer to handle the setDataAction
        setData: (state, action) => {
            state.data = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendMessageThunk.pending, (state) => {
                state.isLoading = true;
                state.isError = false;
                state.error = null;
            })
            .addCase(sendMessageThunk.fulfilled, (state, action) => {
                state.isLoading = false;
                // The data is set through the setDataAction, no need to set it here
                state.isError = false;
                state.error = null;
            })
            .addCase(sendMessageThunk.rejected, (state, action) => {
                state.isLoading = false;
                state.isError = true;
                state.error = action.payload;
            });
    },
});

export const { setData } = chatSlice.actions; // Export the setData action
export default chatSlice.reducer;
