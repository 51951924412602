import React, { useEffect, useState } from "react";
import FolderIcon from "@mui/icons-material/Folder";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Button,
} from "@mui/material";
import {
  googleFolderDetailListThunk,
  googleFolderListThunk,
  notionFolderListThunk,
  oneDriveFolderDetailListThunk,
  oneDriveFolderListThunk,
} from "../../redux/slices/knowledgeBase.slice";
import { setSnackbar } from "../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import "../../styles/drive.scss";
import { Box } from "@mui/system";
import CloseIcon from "@mui/icons-material/CloseRounded";

const DriveStructure = ({
  selectedIntegration,
  setSelectedIntegration,
  setSelectedFileFolder,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [tempSelectedFileFolder, setTempSelectedFileFolder] = useState({});
  const [currentPath, setCurrentPath] = useState([
    { file: "Home", home: true },
  ]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (selectedIntegration?.id) fetchFolders();
    // eslint-disable-next-line
  }, [selectedIntegration]);

  const fetchFolders = async () => {
    setCurrentPath([{ file: "Home", home: true }]);
    setIsLoading(true);
    let response;
    if (selectedIntegration.type === "Notion")
      response = await dispatch(
        notionFolderListThunk({ id: selectedIntegration?.id })
      );
    else if (selectedIntegration.type === "Google drive")
      response = await dispatch(
        googleFolderListThunk({ id: selectedIntegration?.id })
      );
    else if (selectedIntegration.type === "One drive")
      response = await dispatch(
        oneDriveFolderListThunk({ id: selectedIntegration?.id })
      );
    if (response?.payload?.status) {
      setData(response.payload.data);
      // setTotalRow(response.payload.total_object);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response?.payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  const handleFolderClick = async (folder) => {
    setIsLoading(true);
    if (selectedIntegration.type === "One drive") {
      if (currentPath.some((selectedItem) => selectedItem.id === folder.id)) {
        setCurrentPath(
          currentPath.filter((selectedItem) => selectedItem.id !== folder.id)
        );
        setCurrentPath(
          currentPath.filter(
            (obj) =>
              currentPath.findIndex((item) => item.id === folder.id) >=
              currentPath.findIndex((item) => item.id === obj.id)
          )
        );
      } else {
        setCurrentPath([...currentPath, folder]);
      }
      let payload = {
        file_id: folder.id,
        configuration_id: selectedIntegration?.id,
      };
      let response = await dispatch(oneDriveFolderDetailListThunk(payload));
      if (response?.payload?.status) {
        setData(response.payload.data);
        // setTotalRow(response.payload.total_object);
      } else
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: response.payload.message || "Internal server error",
          })
        );
    } else {
      if (
        currentPath.some(
          (selectedItem) => selectedItem.file_id === folder.file_id
        )
      ) {
        setCurrentPath(
          currentPath.filter(
            (selectedItem) => selectedItem.file_id !== folder.file_id
          )
        );
        setCurrentPath(
          currentPath.filter(
            (obj) =>
              currentPath.findIndex(
                (item) => item.file_id === folder.file_id
              ) >= currentPath.findIndex((item) => item.file_id === obj.file_id)
          )
        );
      } else {
        setCurrentPath([...currentPath, folder]);
      }
      let payload = {
        file_id: folder.file_id,
        configuration_id: selectedIntegration?.id,
      };
      let response = await dispatch(googleFolderDetailListThunk(payload));
      if (response?.payload?.status) {
        setData(response.payload.data);
        // setTotalRow(response.payload.total_object);
      } else
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: response.payload.message || "Internal server error",
          })
        );
    }
    setIsLoading(false);
  };

  const handleCheckboxChange = (item) => {
    setTempSelectedFileFolder((prevSelectedFileFolder) => {
      const updatedFileFolder = { ...prevSelectedFileFolder };
      if (selectedIntegration.type === "Notion")
        if (
          updatedFileFolder[selectedIntegration?.id]?.some(
            (selectedItem) => selectedItem.id === item.id
          )
        ) {
          // Remove the item from the array
          updatedFileFolder[selectedIntegration?.id] =
            updatedFileFolder[selectedIntegration?.id]?.filter(
              (selectedItem) => selectedItem.id !== item.id
            ) || [];
        } else {
          // Add the item to the array
          updatedFileFolder[selectedIntegration?.id] = [
            ...(updatedFileFolder[selectedIntegration?.id] || []),
            item,
          ];
        }
      if (selectedIntegration.type === "Google drive")
        if (
          updatedFileFolder[selectedIntegration?.id]?.some(
            (selectedItem) => selectedItem.file_id === item.file_id
          )
        ) {
          // Remove the item from the array
          updatedFileFolder[selectedIntegration?.id] =
            updatedFileFolder[selectedIntegration?.id]?.filter(
              (selectedItem) => selectedItem.file_id !== item.file_id
            ) || [];
        } else {
          // Add the item to the array
          updatedFileFolder[selectedIntegration?.id] = [
            ...(updatedFileFolder[selectedIntegration?.id] || []),
            item,
          ];
        }
      if (selectedIntegration.type === "One drive")
        if (
          updatedFileFolder[selectedIntegration?.id]?.some(
            (selectedItem) => selectedItem.id === item.id
          )
        ) {
          // Remove the item from the array
          updatedFileFolder[selectedIntegration?.id] =
            updatedFileFolder[selectedIntegration?.id]?.filter(
              (selectedItem) => selectedItem.id !== item.id
            ) || [];
        } else {
          // Add the item to the array
          updatedFileFolder[selectedIntegration?.id] = [
            ...(updatedFileFolder[selectedIntegration?.id] || []),
            item,
          ];
        }
      return updatedFileFolder;
    });
  };

  function isIdExists(object, idToCheck) {
    // Iterate over each property in the object
    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        // Check if the property is an array
        if (Array.isArray(object[key])) {
          // Iterate over each item in the array
          for (const item of object[key]) {
            // Check if the item has 'id' property
            if (item.hasOwnProperty("id")) {
              // Compare the 'id' with the provided idToCheck
              if (item.id === idToCheck) {
                return true; // ID exists
              }
            }

            // Check if the item has 'file_id' property
            if (item.hasOwnProperty("file_id")) {
              // Compare the 'file_id' with the provided idToCheck
              if (item.file_id === idToCheck) {
                return true; // file_id exists
              }
            }
          }
        }

        // Check if the property is an object (nested object)
        if (typeof object[key] === "object") {
          // Recursively check for the id in the nested object
          if (isIdExists(object[key], idToCheck)) {
            return true;
          }
        }
      }
    }

    return false; // ID does not exist
  }

  const Breadcrumb = ({ path }) => {
    return (
      <div className="breadcrumb">
        <div></div>
        {path.map((folder, index) => (
          <span
            key={index}
            style={{ cursor: "pointer", fontSize: "16px" }}
            onClick={() => {
              folder.home ? fetchFolders() : handleFolderClick(folder);
            }}
          >
            {folder.file || folder.name}
            {index < path.length - 1 && <span> / </span>}
          </span>
        ))}
      </div>
    );
  };
  return (
    <Dialog
      open={selectedIntegration}
      onClose={() => {
        setSelectedIntegration(false);
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>
        <Breadcrumb path={currentPath} />
        <IconButton
          type="button"
          className="close-preview"
          onClick={() => {
            setSelectedIntegration(false);
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {isLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <div className="file-explorer">
            {data.length !== 0 ? (
              data.map((foldfile, index) =>
                selectedIntegration.type === "Notion" ? (
                  <Box sx={{ display: "flex" }}>
                    <Checkbox
                      checked={isIdExists(tempSelectedFileFolder, foldfile.id)}
                      onChange={(e) => {
                        handleCheckboxChange(foldfile);
                      }}
                    />
                    <div
                      key={index}
                      className="file"
                      onClick={() => {
                        handleCheckboxChange(foldfile);
                      }}
                    >
                      <InsertDriveFileIcon />
                      {foldfile.page_name?.content
                        ? foldfile.page_name?.content
                        : foldfile.page_name}
                    </div>
                  </Box>
                ) : selectedIntegration.type === "One drive" ? (
                  foldfile?.folder ? (
                    <Box sx={{ display: "flex" }}>
                      <Checkbox
                        checked={isIdExists(
                          tempSelectedFileFolder,
                          foldfile.id
                        )}
                        onChange={(e) => {
                          e.stopPropagation();
                          handleCheckboxChange(foldfile);
                        }}
                      />

                      <div
                        key={index}
                        className="folder"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleFolderClick(foldfile);
                        }}
                      >
                        <FolderIcon />
                        {foldfile.name}
                      </div>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex" }}>
                      <Checkbox
                        checked={isIdExists(
                          tempSelectedFileFolder,
                          foldfile.id
                        )}
                        onChange={(e) => {
                          handleCheckboxChange(foldfile);
                        }}
                      />
                      <div
                        key={index}
                        className="file"
                        onClick={() => {
                          handleCheckboxChange(foldfile);
                        }}
                      >
                        <InsertDriveFileIcon />
                        {foldfile.name}
                      </div>
                    </Box>
                  )
                ) : foldfile?.type === "application/vnd.google-apps.folder" ? (
                  <Box sx={{ display: "flex" }}>
                    <Checkbox
                      checked={isIdExists(
                        tempSelectedFileFolder,
                        foldfile.file_id
                      )}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleCheckboxChange(foldfile);
                      }}
                    />

                    <div
                      key={index}
                      className="folder"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleFolderClick(foldfile);
                      }}
                    >
                      <FolderIcon />
                      {foldfile.file}
                    </div>
                  </Box>
                ) : (
                  <Box sx={{ display: "flex" }}>
                    <Checkbox
                      checked={isIdExists(
                        tempSelectedFileFolder,
                        foldfile.file_id
                      )}
                      onChange={(e) => {
                        handleCheckboxChange(foldfile);
                      }}
                    />
                    <div
                      key={index}
                      className="file"
                      onClick={() => {
                        handleCheckboxChange(foldfile);
                      }}
                    >
                      <InsertDriveFileIcon />
                      {foldfile.file}
                    </div>
                  </Box>
                )
              )
            ) : (
              <div className="text-center no-files-found">
                No Files/Documents Found
              </div>
            )}
            {data.length !== 0 && (
              <div className="modal-save-button">
                <Button
                  variant="contained"
                  className="primary-button"
                  onClick={() => {
                    setSelectedFileFolder((prev) => ({
                      ...prev,
                      ...tempSelectedFileFolder,
                    }));
                    setSelectedIntegration(false);
                  }}
                >
                  Save{" "}
                </Button>
              </div>
            )}
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default DriveStructure;
