import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API_URL } from "../../constants/apiUrl.constant";
import axios from "axios";

export const uploadchatbotFileThunk = createAsyncThunk(
  "uploadchatbotFile",
  async (data) => {
    const payload = new FormData();
    data.file.forEach((element) => {
      payload.append("file", element);
    });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPLOAD_CHATBOT_FILE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const uploadLogoThunk = createAsyncThunk(
  "uploadchatbotFile",
  async (data) => {
    const payload = new FormData();
    payload.append("logo", data.file);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPLOAD_CHATBOT_LOGO}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const uploadchatboturlThunk = createAsyncThunk(
  "uploadchatbotuRL",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPLOAD_CHATBOT_URL}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateChatbotThunk = createAsyncThunk(
  "updateChatbot",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_CHATBOT}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchChatbotDetailThunk = createAsyncThunk(
  "fetchChatbotDetail",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.CHATBOT_DETAIL}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeDocThunk = createAsyncThunk("removeDoc", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_URL.DELETE_CHATBOT_DOC}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

// chat slice
const chatSlice = createSlice({
  name: "chat",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {},
});

export default chatSlice.reducer;
