import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllAssistantsThunk = createAsyncThunk(
  "AllAssistants",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_ASSISTANTS}` +
          (payload.is_qa !== undefined ? `?is_qa=${payload.is_qa}` : "") +
          (payload.search ? `&search=${payload.search}` : ""),
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllDefaultAssistantThunk = createAsyncThunk(
  "AllDefaultAssistant",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_DEFAULT_ASSISTANT}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const getAllKBFilesThunk = createAsyncThunk(
  "AllKBFiles",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_KBFILES}` +
          (payload ? "?is_csv=true" : ""),
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createAssistantsThunk = createAsyncThunk(
  "createAssistants",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_ASSISTANTS}`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateAssistantsThunk = createAsyncThunk(
  "updateAssistants",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_ASSISTANTS}/${payload.id}`,
        payload.data,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeAssistantsThunk = createAsyncThunk(
  "removeAssistants",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE__ASSISTANTS}/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOneAssistantThunk = createAsyncThunk(
  "getOneAssistant",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_ASSISTANTS}/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createNewThredThunk = createAsyncThunk(
  "createNewThred",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_NEW_THRED}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getAllThreadThunk = createAsyncThunk(
  "AllThread",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_THREAD}?page_number=${payload.page_number}&page_size=${payload.page_size}` +
          (payload?.search ? `&search=${payload.search}` : ""),
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeThreadThunk = createAsyncThunk(
  "removeThread",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_THREAD}/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchThreadHistoryThunk = createAsyncThunk(
  "fetchThreadHistory",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.FETCH_THREAD_HISTORY}/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateThredThunk = createAsyncThunk(
  "updateThred",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_NEW_THRED}/${payload.thread_id}`,
        { thread_name: payload.thread_name },
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Assistants slice
const assistantsSlice = createSlice({
  name: "assistants",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default assistantsSlice.reducer;
