import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Button,
  Typography,
  Box,
  CardContent,
  Grid,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
  Tooltip,
  IconButton,
  InputBase,
  Tabs,
  Tab,
  Stack,
} from "@mui/material";
import { RiAddCircleLine } from "react-icons/ri";
import "../../../styles/externalAssistant.scss";
import { useNavigate } from "react-router-dom";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import {
  getAllAssistantsThunk,
  removeAssistantsThunk,
} from "../../../redux/slices/assistants.slice";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import SearchIcon from "@mui/icons-material/Search";
import DeleteConfirmation from "../DeleteConfirmation";
import chatIcon from "../../../assets/icons/chat.svg";
import menuIcon from "../../../assets/icons/menu.svg";
import profileIcon from "../../../assets/icons/profile.svg";
import { DiDatabase } from "react-icons/di";
import AssitantDetail from "./AssitantDetail";
import { IoMdEye } from "react-icons/io";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ paddingY: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExternalAssistant = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [assistants, setAssistants] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [deleteId, setDeleteId] = useState(null);
  const [viewId, setViewId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const anchorRefs = useRef([]);
  const Navigate = useNavigate();

  const handleClose = (event, index) => {
    if (
      anchorRefs.current[index] &&
      anchorRefs.current[index].contains(event.target)
    ) {
      return;
    }

    setOpenIndex(null);
  };

  const fetchAllAssistants = async () => {
    let response = await dispatch(
      getAllAssistantsThunk({
        search: searchTerm,
        is_qa: value === 0 ? undefined : value === 1 ? false : true,
      })
    );
    if (response?.payload?.status) {
      setAssistants(response?.payload?.data ?? []);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response?.payload?.message || "Internal server error",
        })
      );
    }
  };

  const deleteHandler = async () => {
    let response = await dispatch(removeAssistantsThunk({ id: deleteId }));
    if (response.payload && response.payload.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Successfully Removed",
        })
      );
      fetchAllAssistants();
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response.payload.message || "Internal server error",
        })
      );
    }
    setOpenIndex(null);
  };

  const options = [
    {
      label: "Edit",
      onClick: (_, id) => {
        Navigate(PAGE_URL.UPDATE_ASSISTANT, { state: { id } });
      },
    },
    {
      label: "Delete",
      onClick: (_, id) => {
        setDeleteId(id);
      },
    },
  ];

  useEffect(() => {
    fetchAllAssistants();
    // eslint-disable-next-line
  }, [searchTerm, value]);

  const handleToggle = (e, index) => {
    setOpenIndex(openIndex === index ? null : index);
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const searchtermAssistant = (e) => {
    setSearchTerm(e.target.value);
  };

  const listingData = () => {
    return (
      <Grid container spacing={4}>
        {assistants.length > 0 ? (
          <>
            {assistants.map((assistantcard, i) => {
              return (
                <Grid key={assistantcard.id} item xs={12} sm={6} md={4} lg={4}>
                  <Card
                    sx={{
                      maxHeight: 200,
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    <CardContent>
                      <Box
                        className="space-between"
                        sx={{
                          paddingBottom: "10px",
                        }}
                      >
                        <img
                          alt="Profile"
                          src={profileIcon}
                          width={24}
                          height={24}
                        />

                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Tooltip title="View Assitant Details">
                            <IconButton
                              onClick={() => {
                                setViewId(assistantcard.id);
                              }}
                              className="New-Chat"
                            >
                              <IoMdEye color="#CA9688" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Open in new chat">
                            <IconButton
                              onClick={() => {
                                Navigate(PAGE_URL.ASSITANT_CHAT, {
                                  state: {
                                    assistant_id: assistantcard.id,
                                  },
                                });
                              }}
                              className="New-Chat"
                              sx={{
                                background: "transparent",
                                color: "black",
                                boxShadow: "none",
                                padding: "0px",
                                minWidth: "0px",
                              }}
                            >
                              <img
                                alt="Chat"
                                src={chatIcon}
                                width={20}
                                height={20}
                              />
                            </IconButton>
                          </Tooltip>
                          <Box sx={{ position: "relative" }}>
                            <IconButton
                              className="toggle-assistant"
                              size="small"
                              aria-controls={
                                openIndex === i
                                  ? "split-button-menu"
                                  : undefined
                              }
                              aria-expanded={
                                openIndex === i ? "true" : undefined
                              }
                              aria-label="select merge strategy"
                              aria-haspopup="menu"
                              onClick={(e) => handleToggle(e, i)}
                              sx={{
                                minWidth: "20px",
                                fontWeight: "bold",
                              }}
                            >
                              <img
                                alt="Menu"
                                src={menuIcon}
                                width={18}
                                height={18}
                              />
                            </IconButton>
                            <Popper
                              className="assistant-dropdown"
                              sx={{
                                zIndex: 1,
                              }}
                              open={openIndex === i}
                              anchorEl={anchorRefs.current[i]}
                              role={undefined}
                              transition
                              disablePortal
                            >
                              {({ TransitionProps, placement }) => (
                                <Grow
                                  {...TransitionProps}
                                  style={{
                                    transformOrigin:
                                      placement === "bottom"
                                        ? "center top"
                                        : "center bottom",
                                  }}
                                >
                                  <Paper>
                                    <ClickAwayListener
                                      onClickAway={(event) =>
                                        handleClose(event, i)
                                      }
                                    >
                                      <MenuList
                                        id="split-button-menu"
                                        autoFocusItem
                                      >
                                        {options.map((option, index) => (
                                          <MenuItem
                                            key={`${option[index]} + ${index}`}
                                            onClick={(event) =>
                                              option.onClick(
                                                event,
                                                assistantcard.id
                                              )
                                            }
                                          >
                                            {option.label}
                                          </MenuItem>
                                        ))}
                                      </MenuList>
                                    </ClickAwayListener>
                                  </Paper>
                                </Grow>
                              )}
                            </Popper>
                          </Box>
                        </Box>
                      </Box>
                      <Typography
                        sx={{
                          color: "#036185",
                          fontSize: "16px",
                          marginTop: "10px",
                          fontWeight: "700",
                        }}
                        variant="h6"
                      >
                        {assistantcard.name}
                      </Typography>
                      <hr style={{ color: "#EBEBEB", opacity: 1 }} />
                      <Typography
                        sx={{ fontSize: "14px", color: "#4F4F4F" }}
                        variant="h6"
                      >
                        {`${
                          assistantcard.description.length > 115
                            ? assistantcard.description.substring(0, 115) +
                              "..."
                            : assistantcard.description
                        }`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </>
        ) : (
          <Box
            className="no-data-found"
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              mt: 1,
            }}
          >
            <Stack spacing={0} justifyContent="center" alignItems="center">
              <DiDatabase size={50} />
              <span>No data found</span>
            </Stack>
          </Box>
        )}
      </Grid>
    );
  };

  return (
    <>
      <Box sx={{ paddingBottom: "15px" }}>
        <Box
          variant="div"
          component="div"
          className="space-between media-assitant"
        >
          <Typography sx={{ color: "#00446B !important" }}>
            Create and manage your AI-powered business assistants.
          </Typography>
          <Box sx={{ display: "flex" }} className="content-header-right">
            <Paper
              elevation={0}
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "auto",
                borderRadius: "50px",
                marginRight: "10px",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search"
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                onChange={searchtermAssistant}
              />
              <IconButton sx={{ p: "10px" }} aria-label="search">
                <SearchIcon />
              </IconButton>
            </Paper>
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              endIcon={<RiAddCircleLine size={20} />}
              disableRipple
              onClick={() => {
                Navigate(PAGE_URL.CREATE_EXTERNAL_ASSISTANT);
              }}
            >
              Create New Assistant
            </Button>
          </Box>
        </Box>
        <hr />
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              className="configure-action-tab"
              onChange={handleChange}
              aria-label="Free form assistants"
            >
              <Tab label="All Assistants" {...a11yProps(0)} />
              <Tab label="Free Form Assistants" {...a11yProps(1)} />
              <Tab label="KB Assistants" {...a11yProps(2)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            {listingData()}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            {listingData()}
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            {listingData()}
          </CustomTabPanel>
        </Box>
      </Box>
      {deleteId && (
        <DeleteConfirmation
          open={deleteId}
          onClose={() => setDeleteId(null)}
          onDelete={deleteHandler}
        />
      )}
      {viewId && (
        <AssitantDetail
          isOpen={viewId}
          viewId={viewId}
          handleCloseView={() => {
            setViewId(null);
          }}
        />
      )}
    </>
  );
};

export default ExternalAssistant;
