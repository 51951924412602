import React from "react";

const Right = () => {
  return (
    <svg
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_590_2577)">
        <path
          d="M13.6797 26.1513C20.6903 26.1513 26.3732 20.4684 26.3732 13.4578C26.3732 6.44716 20.6903 0.764282 13.6797 0.764282C6.66908 0.764282 0.986206 6.44716 0.986206 13.4578C0.986206 20.4684 6.66908 26.1513 13.6797 26.1513Z"
          fill="#CA9688"
        />
        <path
          d="M7.93524 14.2784L11.2178 17.561L19.4242 9.35461"
          stroke="white"
          strokeWidth="1.95285"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_590_2577">
          <rect
            width="25.387"
            height="25.387"
            fill="white"
            transform="translate(0.986206 0.764282)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Right;
