import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Card,
  CardContent,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Switch,
  Modal,
  Stack,
} from "@mui/material";
import { CgProfile } from "react-icons/cg";
import "../../../styles/externalAssistant.scss";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import AddInstructions from "./AddInstructions";
import { alpha, styled } from "@mui/material/styles";
import { useFormik } from "formik";
import * as Yup from "yup";
import SelecteKb from "./SelectKb";
import {
  createAssistantsThunk,
  getAllDefaultAssistantThunk,
  getOneAssistantThunk,
  updateAssistantsThunk,
} from "../../../redux/slices/assistants.slice";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import { RiCloseLine } from "react-icons/ri";
import { VscPreview } from "react-icons/vsc";
import AddIcon from "../../../assets/icons/add.svg";
import ProfileIcon from "../../../assets/icons/profileBlue.svg";

const steps = ["Basic", "Instructions", "Is this a Q&A Assistant?"];

const label = { inputProps: { "aria-label": "Switch demo" } };

const OrangeSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#036185",
    "&:hover": {
      backgroundColor: alpha("#273167", theme?.palette?.action?.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#273167",
  },
}));

const CreateAssistant = () => {
  const [open, setOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  let location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const [defaultAssistant, setDefaultAssistant] = useState([]);
  const [initialValues, setInitialValues] = useState({
    name: "",
    description: "",
    instruction: "",
    kb: {},
    assistant: null,
    is_qa: false,
    is_safty_assistant: false
    // id: null,
  });
  const [activeStep, setActiveStep] = useState(0);

  const validationSchema1 = Yup.object({
    name: Yup.string().required("Name is required"),
    description: Yup.string().required("Description is required"),
  });

  const validationSchema2 = Yup.object({
    instruction: Yup.string().required("instruction is required"),
  });

  const validationSchema3 = Yup.object({
    is_qa: Yup.boolean().required("The terms and conditions must be accepted."),
    kb: Yup.object().when(["is_qa"], {
      is: (is_qa) => is_qa,
      then: Yup.object().required("kb selection is require"),
    }),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema:
      activeStep === 0
        ? validationSchema1
        : activeStep === 1
        ? validationSchema2
        : validationSchema3,
    onSubmit: async (values) => {
      if (activeStep === 0 || activeStep === 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setIsLoading(true);
        let response = await dispatch(
          location?.state?.id
            ? updateAssistantsThunk({ id: location.state.id, data: values,  })
            : createAssistantsThunk(values)
        );

        if (response.payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: location?.state?.id
                ? "Assistant updated successfully"
                : "Assistant created successfully",
            })
          );
          Navigate(PAGE_URL.EXTERNAL_ASSISTANT);
        } else
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: response.payload.message || "Internal server error",
            })
          );
        setIsLoading(false);
      }
    },
  });

  const fetchAssistantDetails = async (id) => {
    let response = await dispatch(getOneAssistantThunk(id));
    const { payload } = response;
    if (payload && payload?.status) {
      formik.setValues(payload?.data);
      setInitialValues(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    location?.state?.id && fetchAssistantDetails(location?.state?.id);
    // eslint-disable-next-line
  }, [location?.state?.id]);

  const BootstrapInput = {
    marginBottom: "20px",
    "& label": {
      width: "100%",
      "&.Mui-focused": {
        color: "#273167",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& .Mui-disabled": {
        backgroundColor: "rgba(39, 49, 103, 0.1)",
      },
      "&.Mui-focused": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
      "&:hover": {
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#273167 !important",
          borderWidth: "1px !important",
        },
      },
    },
  };

  const fetchAllDefaultAssistant = async () => {
    let response = await dispatch(getAllDefaultAssistantThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      setDefaultAssistant(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAllDefaultAssistant();
    // eslint-disable-next-line
  }, []);

  const handleInstructions = (id) => {
    if (id !== formik.values.assistant) {
      formik.setFieldValue(
        "instruction",
        defaultAssistant.find((ele) => ele.id === id).instruction
      );
      formik.setFieldValue("assistant", id);
    } else {
      formik.setFieldValue("assistant", id);
      formik.setFieldValue("instruction", formik.values.instruction);
    }
    setOpen(true);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleCancel = () => {
    Navigate(PAGE_URL.EXTERNAL_ASSISTANT);
  };

  const handleChange = (event) => {
    formik.setFieldValue("is_qa", event.target.checked);
  };
  
  const safetyhandleChange = (event) => {
    formik.setFieldValue("is_safty_assistant", event.target.checked);

  }
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <div className="mt mx-auto main-div-assistant">
          <Stepper
            sx={{
              paddingBottom: "20px",
              display: "flex",
              justifyContent: "center",
            }}
            flexDirection={{ sm: "column !important" }}
            activeStep={activeStep}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};
              return (
                <Step
                  className={`step-lable ${
                    activeStep === index && activeStep !== 2
                      ? "step-lable-before"
                      : ""
                  }`}
                  key={label}
                  {...stepProps}
                  sx={{
                    backgroundColor:
                      activeStep === index
                        ? "#CA9688"
                        : activeStep > index
                        ? "#fff"
                        : "#fff",
                  }}
                >
                  <StepLabel
                    className={`${
                      activeStep === index
                        ? "step-circle active-step"
                        : "inactive-text"
                    } ${activeStep > index ? "step-circle" : ""} `}
                    {...labelProps}
                    sx={{ color: "white !important" }}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
          <Grid
            item
            xs={12}
            sm={12}
            md={11}
            lg={11}
            xl={11}
            sx={{ paddingX: "40px !important" }}
          >
            <hr style={{ marginTop: "0px", marginBottom: "40px" }}></hr>
          </Grid>
          <>
            <form onSubmit={formik.handleSubmit}>
              {activeStep + 1 === 1 ? (
                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={12} md={11} lg={11} xl={11}>
                    <p
                      style={{
                        paddingBottom: "15px",
                        color: "#0D333F",
                        fontWeight: 500,
                        fontSize: "15px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Give a public name and description to the assistant, and
                      control privacy settings.
                    </p>
                    <Grid item className="margin-bottom-input">
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography
                          color="#0D333F"
                          variant="body1"
                          component="label"
                        >
                          Name
                        </Typography>
                        <TextField
                          fullWidth
                          id="name"
                          name="name"
                          label=""
                          variant="outlined"
                          placeholder="Enter Name"
                          size="small"
                          {...formik.getFieldProps("name")}
                        />
                        {formik.touched.name && formik.errors.name && (
                          <div className="form-error-message">
                            {formik.errors.name}
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid item className="margin-bottom-input">
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography
                          variant="body1"
                          component="label"
                          color="#0D333F"
                        >
                          Description
                        </Typography>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Enter Description"
                          size="small"
                          name="description"
                          {...formik.getFieldProps("description")}
                          rows={4}
                          multiline
                        />
                        {formik.touched.description &&
                          formik.errors.description && (
                            <div className="form-error-message">
                              {formik.errors.description}
                            </div>
                          )}
                        <Typography
                          variant="subtitle1"
                          sx={{ fontSize: "14px", color: "#989898" }}
                        >
                          The description can help teammates know what the
                          assistant is for.
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              ) : activeStep + 1 === 2 ? (
                <>
                  <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyItems="center"
                        px={{ md: 6, sm: 0 }}
                        pt={2}
                      >
                        <Grid item xs={12} md={12} lg={12}>
                          <p
                            style={{
                              paddingBottom: "15px",
                              color: "#0D333F",
                              fontWeight: 500,
                              fontSize: "16px",
                            }}
                          >
                            This is where you explain to the assistant what it's
                            goal is, and share any other context you want it to
                            always know.
                          </p>
                        </Grid>
                      </Grid>
                      <Grid item className="margin-bottom-input">
                        <Box variant="div" component="div" sx={BootstrapInput}>
                          <Grid
                            container
                            spacing={4}
                            alignItems="center"
                            justifyItems="center"
                            pt={4}
                            px={{ sm: 0, md: 6 }}
                          >
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Card
                                sx={{
                                  height: "100%",
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                                className={`assistantCard-hover ${
                                  formik?.values?.assistant == null &&
                                  formik.values.instruction
                                    ? "custom-active"
                                    : ""
                                }`}
                                onClick={() => {
                                  formik.setFieldValue(
                                    "instruction",
                                    formik.values.instruction ?? ""
                                  );
                                  formik.setFieldValue("assistant", null);
                                  setOpen(true);
                                }}
                              >
                                <CardContent>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <img
                                      alt="Add"
                                      src={AddIcon}
                                      width={24}
                                      height={24}
                                    />
                                    <Typography
                                      sx={{
                                        marginLeft: "10px",
                                        fontSize: "18px",
                                        color: "#036185",
                                      }}
                                      variant="h6"
                                    >
                                      Custom Set Up
                                    </Typography>
                                  </Box>
                                  <hr
                                    style={{ color: "#EBEBEB", opacity: 1 }}
                                  />
                                  <Typography
                                    sx={{ fontSize: "14px", color: "grey" }}
                                    variant="h6"
                                  >
                                    Create your AI assistant with guided
                                    customization for your unique goals.
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6}>
                              <Card
                                sx={{
                                  minWidth: 200,
                                  height: "100%",
                                  position: "relative",
                                  cursor: "pointer",
                                }}
                                className={`assistantCard-hover ${
                                  formik?.values?.assistant != null
                                    ? "custom-active"
                                    : ""
                                }`}
                                onClick={() => {
                                  setIsModalOpen(true);
                                }}
                              >
                                <CardContent>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      justifyContent: "start",
                                      alignItems: "center",
                                      paddingBottom: "10px",
                                    }}
                                  >
                                    <img
                                      alt="Profile"
                                      src={ProfileIcon}
                                      width={24}
                                      height={24}
                                    />

                                    <Typography
                                      sx={{
                                        marginLeft: "10px",
                                        fontSize: "18px",
                                        color: "#036185",
                                      }}
                                      variant="h6"
                                    >
                                      Use Assistant Libraray
                                    </Typography>
                                  </Box>
                                  <hr
                                    style={{ color: "#EBEBEB", opacity: 1 }}
                                  />
                                  <Typography
                                    sx={{ fontSize: "14px", color: "grey" }}
                                    variant="h6"
                                  >
                                    Begin with a pre-made starting point, then
                                    customize from there.
                                  </Typography>
                                </CardContent>
                              </Card>
                            </Grid>
                          </Grid>
                        </Box>
                        {formik.touched.name && formik.errors.name && (
                          <div className="form-error-message">
                            {formik.errors.name}
                          </div>
                        )}
                        <Grid />
                      </Grid>
                    </Grid>
                  </Grid>
                  {formik.values.instruction && (
                    <Grid
                      container
                      spacing={2}
                      alignItems="center"
                      justifyItems="center"
                      px={6}
                      pt={1}
                    >
                      <Grid item xs={12} md={12} lg={12}>
                        <Stack
                          sx={{
                            background: "#f3f6f9",
                            padding: "10px",
                            borderRadius: "5px",
                            border: 1,
                            borderColor: "#273167",
                          }}
                        >
                          <Stack
                            flexDirection="row"
                            alignItems="center"
                            gap="10px"
                          >
                            <VscPreview />
                            <Typography
                              sx={{
                                color: "#273167",
                                fontSize: "16px !important",
                              }}
                            >
                              Preview
                            </Typography>
                          </Stack>
                          <hr style={{ margin: "10px 0" }}></hr>
                          <Stack>
                            <Typography
                              variant="h6"
                              sx={{
                                color: "#273167",
                              }}
                            >
                              {defaultAssistant.find((item) => {
                                return item.id === formik.values.assistant;
                              })?.name ?? "Custom Assitant"}
                            </Typography>
                          </Stack>
                          <Stack
                            sx={{ color: "grey" }}
                            dangerouslySetInnerHTML={{
                              __html: formik.values.instruction,
                            }}
                          />
                        </Stack>
                      </Grid>
                    </Grid>
                  )}
                </>
              ) : activeStep + 1 === 3 ? (
                <Grid container spacing={4} sx={{ justifyContent: "center" }}>
                  <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      className="margin-bottom-input"
                    >
                      <p style={{ marginBottom: "15px", color: "#5C5C5C" }}>
                        Q&A assistants search through the entire knowledge base
                        to provide you precise answers. Examples include
                        customer service assistant that answers support
                        questions, HR assistant that answers employee questions,
                        etc.
                      </p>
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Box sx={{ marginBottom: "15px" }}>
                          <Box sx={{ marginBottom: "15px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                color="#0D333F !important"
                                fontSize="18px !important"
                                fontWeight="600 !important"
                              >
                                Enable Q&A Assistant
                              </Typography>
                              <OrangeSwitch
                                {...label}
                                checked={formik.values.is_qa}
                                onChange={handleChange}
                              />
                            </Box>
                            <Typography
                              variant="body1"
                              component="label"
                              color="#5C5C5C"
                            >
                              When enabled, the assistant will search the
                              Knowledge Base for your answer. Do not enable this
                              for assistants that produce writing.
                            </Typography>
                          </Box>
                          <Typography
                            variant="body1"
                            component="label"
                            color="#5C5C5C"
                          >
                            Note: All assistants can reference the knowledge
                            base using the “#” in the chat.
                          </Typography>
                        </Box>
                        <Box sx={{ marginBottom: "15px" }}>
                          <Box sx={{ marginBottom: "15px" }}>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <Typography
                                color="#0D333F !important"
                                fontSize="18px !important"
                                fontWeight="600 !important"
                              >
                                Safety Assistant
                              </Typography>
                              <OrangeSwitch
                                {...label}
                                checked={formik.values.is_safty_assistant}
                                onChange={safetyhandleChange}
                              />
                            </Box>
                            <Typography
                              variant="body1"
                              component="label"
                              color="#5C5C5C"
                            >
                              {/* When enabled, the assistant will search the
                              Knowledge Base for your answer. Do not enable this
                              for assistants that produce writing. */}
                            </Typography>
                          </Box>
                        </Box>
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{
                            fontWeight: 600,
                            color: "#0D333F",
                            fontSize: "18px !important",
                          }}
                        >
                          Select Knowledge Base
                        </Typography>
                      </Box>
                      {/* <Grid /> */}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        className={
                          !formik.values.is_qa ? "disable-kb-select" : ""
                        }
                      >
                        <Grid item>
                          <SelecteKb id={location?.state?.id} formik={formik} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                ""
              )}
              <Box
                flexDirection={{ sm: "column", md: "row" }}
                sx={{
                  display: "flex",
                  pt: 2,
                  justifyContent: "center",
                }}
                className="Create-assitant-media"
              >
                {activeStep !== 0 && (
                  <Button
                    onClick={handleCancel}
                    sx={{
                      padding: "8px 32px !important",
                      borderRadius: "5px",
                      minWidth: "auto",
                      backgroundColor: "#fff",
                      color: "#036185 !important",
                      border: 1,
                      borderColor: "#273167",
                      "&:hover": {
                        backgroundColor: "#fff",
                        color: "#273167",
                        border: 1,
                        borderColor: "#273167",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                )}
                <Button
                  onClick={activeStep === 0 ? handleCancel : handleBack}
                  sx={{
                    padding: "8px 32px !important",
                    borderRadius: "5px",
                    marginLeft: "15px",
                    minWidth: "auto",
                    backgroundColor: "#fff",
                    color: "#036185",
                    border: 1,
                    borderColor: "#273167",
                    "&:hover": {
                      color: "#fff",
                      backgroundColor: "#036185",
                      border: 1,
                      borderColor: "#036185",
                    },
                  }}
                >
                  {activeStep === 0 ? "Cancel" : "Back"}
                </Button>
                <Button
                  type="submit"
                  disabled={
                    (activeStep + 1 === 2 && !formik.values.instruction) ||
                    isLoading
                  }
                  sx={{
                    padding: "8px 32px !important",
                    borderRadius: "5px",
                    marginLeft: "15px",
                    backgroundColor: "#036185 ",
                    color: "#fff",
                    "&:hover": {
                      backgroundColor: "#fff !important",
                      color: "#273167 !important",
                      border: 1,
                      borderColor: "#036185",
                    },
                  }}
                  className={`${
                    (activeStep + 1 === 2 && !formik.values.instruction) ||
                    isLoading
                      ? "disablestep"
                      : ""
                  }`}
                >
                  {activeStep + 1 !== 3 ? "Next" : "Save"}
                </Button>
              </Box>
            </form>
          </>
        </div>
      </Box>
      {open && (
        <AddInstructions
          open={open}
          setOpen={setOpen}
          createFormik={formik}
          editId={location?.state?.id}
        />
      )}

      <Modal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
      >
        <Box
          className="default-assitant-media"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingBottom: 2,
            }}
          >
            <Typography component="div" color="#0D333F">
              Choose a default Assistant
            </Typography>
            <Box
              className="close-btn"
              onClick={() => setIsModalOpen(false)}
              sx={{ cursor: "pointer" }}
            >
              <RiCloseLine fill="#000000" />
            </Box>
          </Box>
          <Grid container spacing={4}>
            {defaultAssistant?.map((assistantcard, i) => {
              return (
                <Grid key={assistantcard.id} item xs={12} sm={6} md={6} lg={6}>
                  <Card
                    sx={{
                      maxHeight: 215,
                      height: "100%",
                      position: "relative",
                      cursor: "pointer",
                      boxShadow: " 0px 2px 27px 0px #0000001F",
                    }}
                    className={`${
                      formik?.values?.assistant != null &&
                      formik?.values?.assistant === assistantcard.id
                        ? "custom-active"
                        : ""
                    }`}
                    onClick={() => {
                      handleInstructions(assistantcard.id);
                      setIsModalOpen(false);
                    }}
                  >
                    <CardContent>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "center",
                          paddingBottom: "10px",
                        }}
                      >
                        <CgProfile />
                        <Typography
                          sx={{
                            fontSize: "18px",
                            marginLeft: "10px",
                          }}
                          variant="h6"
                        >
                          {`${
                            assistantcard.name.length > 25
                              ? assistantcard.name.substring(0, 25) + "..."
                              : assistantcard.name
                          }`}
                        </Typography>
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "grey",
                        }}
                        variant="h6"
                      >
                        {`${
                          assistantcard.description.length > 115
                            ? assistantcard.description.substring(0, 115) +
                              "..."
                            : assistantcard.description
                        }`}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default CreateAssistant;
