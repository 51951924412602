import React, { useState } from "react";
import { Box, Paper } from "@mui/material";
import Header from "./header";
import SideMenu from "./sideMenu";
import "../../styles/dashboard.scss";

const Dashboard = ({ children }) => {
  const [isAdmin] = useState(true);
  const [openSideMenu, setOpenSideMenu] = useState(true);

  return (
    <Box>
      <Header
        setOpenSideMenu={setOpenSideMenu}
        openSideMenu={openSideMenu}
        isAdmin={isAdmin}
      />
      {isAdmin ? (
        <SideMenu
          openSideMenu={openSideMenu}
          setOpenSideMenu={setOpenSideMenu}
        />
      ) : null}
      <main className={`main-content ${openSideMenu ? "" : "full-width"} `}>
        <Paper className="content-wrapper" elevation={0}>
          {children}
        </Paper>
      </main>
    </Box>
  );
};

export default Dashboard;
