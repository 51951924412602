import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Button,
  Typography,
  Stack,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/CloseRounded";
import Select from "react-select";
import { fetchAllPublicKBThunk } from "../../redux/slices/knowledgeBase.slice";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../redux/slices/common.slice";
import { MdOutlineClear } from "react-icons/md";
import {
  clearChatHistoryThunk,
  fetchChatHistoryThunk,
} from "../../redux/slices/chat.slice";
import { API_URL } from "../../constants/apiUrl.constant";
import "../../styles/chat.scss";
import { CONFIG_IMG } from "../../constants/common.constant";
import Markdown from "react-markdown";
import SendIcon from "../../assets/icons/send.png";
import { FaRegCopy } from "react-icons/fa";

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    minHeight: "44px",
    border: "1px solid #0D333F54",
    cursor: "pointer",
  }),
  menu: (base) => ({
    ...base,
    background: "#FFFFFF",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    marginTop: 0,
    overflow: "auto",
    maxHeight: "280px",
  }),
  menuList: (base) => ({
    ...base,
    padding: "0",
    color: "#030F1C",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    overflow: "auto",
    maxHeight: "280px",
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb ": {
      backgroundColor: " #cccccc",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FFFFFF",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#273167",
    "&:hover": {
      color: "#273167",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "12px 16px",
    backgroundColor: state.isSelected ? "#0075FF" : "#FEFEFF",
    borderBottom: "1px solid #EAECF0",
    borderRadius: "8px 8px 0px 0px",
    "&:hover": {
      backgroundColor: state.isSelected ? "#0075FF" : "#F2F4F7",
      color: state.isSelected ? "#FFFFFF" : "#005ECC",
    },
  }),
};

const Chat = ({
  searchModule = false,
  selectedDocument = [],
  selectedKnowledge = [],
}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState();
  const [newAnswer, setNewAnswer] = useState();
  const [sendMessage, setSendMessage] = useState("");
  const [publicKb, setPublicKb] = useState([]);
  const [selectedKb, setSelectedKb] = useState([]);
  const [selectedDoc, setSelectedDoc] = useState();
  const [token, setToken] = useState({});
  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);
  let uniqueFilenames = new Set();
  const scrollToBottom = () => {
    if (messagesEndRef) messagesEndRef?.current?.scrollIntoView({});
  };
  useEffect(() => {
    if (!searchModule) {
      fetchAllKB();
      fetchChatHistory();
    }
    // eslint-disable-next-line
  }, []);
  const fetchAllKB = async () => {
    let response = await dispatch(fetchAllPublicKBThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      setPublicKb(
        payload?.data.map((item) => ({
          label: item.kbName,
          value: item.kbId,
        }))
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  const fetchChatHistory = async () => {
    setMessages([]);
    setNewMessage("");
    setNewAnswer("");
    let response = await dispatch(fetchChatHistoryThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      await setMessages([
        ...messages,
        ...convertToFormattedArray(payload?.data),
      ]);
      setToken({ ...token, ...payload?.token });
      await scrollToBottom();
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const handleClearChat = async () => {
    let response = await dispatch(clearChatHistoryThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: payload?.message || "Chat clear",
        })
      );
      setMessages([]);
      setNewAnswer("");
      setNewMessage("");
      uniqueFilenames.clear();
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  function convertToFormattedArray(input) {
    const resultArray = [];

    input.forEach((item) => {
      // User's question
      resultArray.push({
        text: item.question,
        sender: "user",
      });

      // AI's answer
      resultArray.push({
        text: item.answer,
        sender: "receiver",
        citations: item.documents.document,
      });
    });

    return resultArray;
  }

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage && newAnswer) {
      setMessages((pre) => [...pre, newMessage, newAnswer]);
    } else if (newMessage) {
      setMessages((pre) => [...pre, newMessage]);
    } else if (newAnswer) {
      setMessages((pre) => [...pre, newAnswer]);
    }
    setNewMessage(null);
    setNewAnswer(null);
    if (sendMessage.length === 0) {
      return;
    }
    setNewMessage({
      text: sendMessage,
      sender: "user",
    });
    setSendMessage("");
    dispatchMessage();
  };

  const fetchAnswer = async (payload) => {
    try {
      await scrollToBottom();
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}${API_URL.SEND_CHAT_MESSAGE}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
          body: JSON.stringify(payload),
        }
      );
      if (!response.ok || !response.body) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Internal server error",
          })
        );
      }

      const combineDocuments = (data) => {
        let allDocuments = [];
        [...data].forEach((item) => {
          item.documents.forEach((doc) => {
            // Check if the filename already exists in uniqueFilenames Set
            if (!uniqueFilenames.has(doc.filename)) {
              // If the filename doesn't exist, add the document to allDocuments
              allDocuments.push({ ...doc });
              setToken({ ...token, ...item.sas });
              uniqueFilenames.add(doc.filename);
            }
          });
        });

        return allDocuments;
      };

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let allChunk = "" ; 

      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }

        let decodedChunk = decoder.decode(value, { stream: true });
        decodedChunk = decodedChunk.replace("'", "@@@");

        try {
          let temp = JSON.parse(decodedChunk) ; 
          allChunk = decodedChunk ; 
        } catch (error) {
          allChunk = allChunk + decodedChunk ; 
          
        }

        let jsonData = [];

        if (allChunk.includes("}")) {
          try {
            // Parse the individual JSON object
            if (allChunk.includes("}{")) {
              
              allChunk = allChunk.replace(/}{/g, "}}{{");
              let correctedJsonStrings = allChunk.split("}{");
              correctedJsonStrings.forEach((chunk) => {
                let chunkJSON = JSON.parse(chunk);
                jsonData.push(
                  JSON.parse(JSON.stringify(chunkJSON).replace("@@@", "'"))
                );
              });
            } else {
              let chunkJSON = JSON.parse(allChunk);
              jsonData.push(
                JSON.parse(JSON.stringify(chunkJSON).replace("@@@", "'"))
              );
            }
          } catch (error) {
            allChunk = allChunk.replace(/}}{{/g, "}{");
          }
        }

        let citations = [];
        if (!searchModule)
          citations = [...citations].concat(combineDocuments(jsonData));

        // setNewAnswer((prevValue) => ({
        //   text: `${prevValue?.text ?? ""}${jsonData
        //     .map((m) => m.content)
        //     .join("")}`,
        //   sender: "receiver",
        //   citations,
        // }));
        setNewAnswer((prevValue) => ({
          text: `${jsonData
            .map((m) => m.content)
            .join("")}`,
          sender: "receiver",
          citations,
        }));
        uniqueFilenames.clear();
        await scrollToBottom();
      }
    } catch (error) {
      console.log("error-->", error);
      // Handle other errors
    }
  };

  const dispatchMessage = async () => {
    try {
      await scrollToBottom();
      let payload = {
        message: sendMessage,
        kb_id: selectedKb.map((kb) => kb.value),
      };
      if (searchModule) {
        payload.kb_id = selectedKnowledge.map((kb) => kb.value);
        payload.location = selectedDocument.map((sd) => sd.location);
      }
      await fetchAnswer(payload);
      await scrollToBottom();
    } catch (error) {
      // Handle error
      console.error("Error sending message:", error);
    }
  };

  const Message = ({ text, sender, citations = [] }) => {
    const isSender = sender;
    const WrapperStyle = {
      display: "flex",
      justifyContent: isSender ? "flex-end" : "flex-start",
      marginBottom: "20px",
    };
    const messageStyle = {
      padding: "8px",
      borderRadius: "5px",
      background: isSender ? "#CA9688" : "#BBDEED", // Adjust the background color as needed
      color: isSender ? "#FFFFFF" : "black", // Adjust the text color as needed
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "22px",
      maxWidth: "70%",
    };

    const copyText = async () => {
      try {
        await navigator.clipboard.writeText(text);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Content copied to clipboard.",
          })
        );
      } catch (err) {
        console.error("Failed to copy content: ", err);
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: "Failed to copy content: " + err,
          })
        );
      }
    };

    return (
      <div style={WrapperStyle}>
        <div className="message-item" style={messageStyle}>
          <Typography variant="p">
            <Markdown>{text}</Markdown>
          </Typography>
          <Stack direction="row" flexWrap="wrap" spacing={1}>
            {!isSender && citations.length > 0 && <b>CITIATIONS:</b>}
            {!isSender &&
              citations.length > 0 &&
              citations.map((citation, index) => (
                <span
                  key={index} // Add a unique key for each item when using map
                  className="citation-preview"
                  onClick={() => setSelectedDoc(citation)}
                >
                  {(citation?.filename.split("/")[0] === "Google drive" ||
                    citation.filename.split("/")[0] === "One drive" ||
                    citation.filename.split("/")[0] === "Notion") && (
                    <img
                      src={CONFIG_IMG[citation.filename.split("/")[0]]}
                      alt={citation.filename.split("/")[0]}
                      style={{
                        width: "15px",
                        marginRight: "5px",
                      }}
                    />
                  )}
                  {` ${
                    citation.filename.split("/").length === 1
                      ? citation?.filename
                      : citation?.filename.split("/")[1]
                  }${citations.length !== index + 1 ? "," : ""}`}
                </span>
              ))}
          </Stack>
          {!isSender && (
            <Stack
              direction="row"
              flexWrap="wrap"
              justifyContent={"end"}
              spacing={1}
            >
              <span onClick={copyText} className="copy-button">
                <FaRegCopy /> Copy
              </span>
            </Stack>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <Box className={`content-header ${searchModule && "hide-box"}`}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={9} lg={9} xl={10}>
            <Select
              styles={customStyles}
              isMulti
              name="kbs"
              options={publicKb}
              className="basic-multi-select"
              onChange={(e) => {
                setSelectedKb(e);
              }}
              classNamePrefix="select"
              placeholder={"Select Knowledge Base(s)..."}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={2}
            textAlign="end"
            paddingRight="24px"
          >
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn "
              endIcon={<MdOutlineClear />}
              disableRipple
              onClick={() => handleClearChat()}
            >
              <Typography variant="span">Clear chat</Typography>
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={selectedDoc ? 6 : 12} sx={{ px: "24px" }}>
          <Box className="chat-main-box">
            <Paper elevation={0} className="chat-main-box-paper">
              <div
                className={`${
                  searchModule
                    ? "chat-box-container-full"
                    : "chat-box-container"
                } scrolling-area`}
              >
                {messages.map((message, index) => (
                  <Message
                    key={index}
                    text={message?.text}
                    sender={message?.sender === "user"}
                    citations={message?.citations}
                  />
                ))}
                {newMessage && (
                  <Message key="new" text={newMessage.text} sender="user" />
                )}
                {newAnswer && (
                  <Message
                    key="newanswer"
                    text={newAnswer.text}
                    sender={false}
                    citations={newAnswer?.citations}
                  />
                )}
                <div
                  style={{ float: "left", clear: "both" }}
                  ref={messagesEndRef}
                ></div>
              </div>
              <form onSubmit={handleSendMessage}>
                <Paper
                  elevation={0}
                  className="chat-box-input-container"
                  sx={{ background: "#F6F6F6", border: "1px solid #D4D4D4" }}
                >
                  <InputBase
                    disabled={selectedKb.length <= 0 && !searchModule}
                    className="chat-input"
                    value={sendMessage}
                    onChange={(e) => setSendMessage(e.target.value)}
                    placeholder="Type a new question (e.g. What does the role of project manager?)"
                    inputProps={{ "aria-label": "search" }}
                  />

                  <IconButton
                    type="submit"
                    className="sendQ-btn"
                    aria-label="search"
                  >
                    <img alt="Send" src={SendIcon} width={32} height={32} />
                  </IconButton>
                </Paper>
              </form>
            </Paper>
          </Box>
        </Grid>
        {selectedDoc && (
          <Grid item xs={6} sx={{ mt: "5px", height: "calc(100vh - 190px)" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                margin: "15px 0",
              }}
            >
              <Button
                sx={{ color: "#fff !important" }}
                type="button"
                className="primary-button"
                aria-label="search"
                onClick={() => {
                  scrollToBottom();
                  setSelectedDoc();
                }}
              >
                <CloseIcon />
                Close Preview
              </Button>
            </div>
            <iframe
              // src="https://icseindia.org/document/sample.pdf"
              src={`${selectedDoc.location}?${token[selectedDoc?.kb]}#page=2`}
              title={selectedDoc?.filename}
              height="100%"
              width="100%"
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Chat;
