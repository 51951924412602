import React from "react";
import "../../styles/footer.scss";
import {
  Box,
  Button,
  Container,
  Grid,
  InputAdornment,
  InputBase,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import logo from "../../assets/logo/Footer-Logo.png";
import { Link } from "react-router-dom";
import { FaFacebookSquare } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import { RiInstagramFill } from "react-icons/ri";
import { IoLogoYoutube } from "react-icons/io5";
import Email from "../../assets/logo/email.svg";
import Phone from "../../assets/logo/phone.svg";
import Emailicon from "../../utils/icon/Email";

const Footer = () => {
  const footerItems = [
    { title: "Home", href: "/" },
    { title: "About", href: "/" },
    { title: "Contact", href: "/" },
    { title: "Support", href: "/" },
    { title: "Privacy Policy", href: "/privacy-policy" },
    { title: "Terms & Conditions", href: "/" },
  ];

  const isMobile = useMediaQuery("(min-width: 401px) and (max-width: 600px)");
  const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 960px)");
  const isLaptop = useMediaQuery("(min-width: 961px) and (max-width: 1280px)");
  const isDesktop = useMediaQuery("(min-width: 1281px)");

  const MaxWidthValue = isDesktop
    ? "lg"
    : isLaptop
    ? "lg"
    : isTablet
    ? "md"
    : isMobile
    ? "sm"
    : "xs";
  return (
    <Box sx={{ paddingY: "50px" }}>
      <Container maxWidth={MaxWidthValue}>
        <section className="footer_wrapper pt-5">
          <Container maxWidth={MaxWidthValue}>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} md={4} lg={5}>
                <Box className="mb-3">
                  <Link to="/" className="mb-3 d-flex text-center">
                    <img src={logo} alt="Logo" style={{ maxWidth: "200px" }} />
                  </Link>
                  <Typography variant="subtitle1" sx={{ color: "#425262" }}>
                    PrismView Programmed To Generate Human-Like Responses And
                    Provide Information On A Wide Range Of Topics.
                  </Typography>
                </Box>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={12} md={12} lg={6}>
                    <Stack spacing={2} direction="column">
                      <Stack direction="row" alignItems={"center"}>
                        <img
                          src={Phone}
                          className="work-card-img"
                          alt="email"
                        />
                        <Typography variant="h4" className="title">
                          Connect On
                        </Typography>
                      </Stack>

                      <Typography variant="subtitle1" className="description">
                        703-577-2554
                      </Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Stack spacing={2} direction="column">
                      <Stack direction="row" alignItems={"center"}>
                        <img
                          src={Email}
                          className="work-card-img"
                          alt="email"
                        />
                        <Typography variant="h4" className="title">
                          Contact
                        </Typography>
                      </Stack>
                      <Typography variant="subtitle1" className="description">
                        support@prismview.ai
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={3}>
                <Box className="">
                  <Typography
                    variant="h3"
                    className="mb-3"
                    style={{
                      color: "#036185",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Quick Links
                  </Typography>
                  <Stack spacing={3} direction="row">
                    <Stack spacing={2} className="nav-items">
                      {footerItems.map((item) => (
                        <Link
                          to={item.href}
                          key={item.title}
                          style={{ textDecoration: "none", marginTop: "10px" }}
                        >
                          <Button
                            on
                            sx={{
                              px: 0,
                              color: "#fff",
                              textAlign: "left",
                              justifyContent: "flex-start",
                            }}
                          >
                            {item.title}
                          </Button>
                        </Link>
                      ))}
                    </Stack>
                  </Stack>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={4} lg={4}>
                <Box>
                  <Typography
                    variant="h3"
                    className="mb-3"
                    style={{
                      color: "#036185",
                      fontWeight: "700",
                      fontSize: "18px",
                    }}
                  >
                    Join Us
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    className="mb-3 title"
                    sx={{ padding: "0px !important" }}
                  >
                    Get hot news and benefits delivered to your inbox
                  </Typography>
                  <Paper
                    component="form"
                    className="subscribe"
                    sx={{
                      p: "6px 0px",
                      display: "flex",
                      alignItems: "center",
                      width: 400,
                      flexDirection: "column",
                      background: "transparent",
                    }}
                  >
                    <InputBase
                      sx={{
                        px: 1,
                        flex: 1,
                        border: "1px solid #c4c4cd",
                        borderRadius: "9px",
                        width: "100%",
                        padding: "16px",
                        marginBottom: "10px",
                      }}
                      placeholder="Enter Your Email Address"
                      inputProps={{ "aria-label": "search google maps" }}
                      startAdornment={
                        <InputAdornment position="start">
                          <Emailicon />
                        </InputAdornment>
                      }
                    />
                    <Button
                      className="send-button"
                      color="primary"
                      sx={{ p: "10px" }}
                      aria-label="directions"
                    >
                      Subscribe Now
                    </Button>
                  </Paper>
                </Box>
              </Grid>
            </Grid>
          </Container>
          <Container maxWidth={MaxWidthValue}>
            <Box className="copyright-wrapper mt-3 py-3">
              <Typography
                variant="caption"
                component="div"
                className="d-block text-center"
              >
                © 2024 , All Rights Reserved
              </Typography>
              <Stack spacing={4} direction="row" className="social-wrapper">
                <Link to="/">
                  <FaFacebookSquare size={30} />
                </Link>
                <Link to="/">
                  <RiInstagramFill size={30} />
                </Link>
                <Link to="/">
                  <FaSquareXTwitter size={30} />
                </Link>
                <Link to="/">
                  <IoLogoYoutube size={30} />
                </Link>
              </Stack>
            </Box>
          </Container>
        </section>
      </Container>
    </Box>
  );
};

export default Footer;
