import React from "react";
import { Box, Button, Drawer, Typography, Stack } from "@mui/material";
import { RiCloseLine } from "react-icons/ri";
import { useFormik } from "formik";
import * as Yup from "yup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const BootstrapInput = {
  marginBottom: "20px",
  display: "contents",
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const AddInstructions = ({ open, setOpen, createFormik, editId }) => {
  const handleCloseDrawer = () => {
    setOpen(false);
    createFormik.setFieldValue("instruction", "");
    createFormik.setFieldValue("assistant", null);
  };

  const formik = useFormik({
    initialValues: {
      instruction: createFormik.values.instruction ?? "",
    },
    validationSchema: Yup.object().shape({
      instruction: Yup.string()
        .required("Instruction is required")
        .nullable(false),
    }),
    onSubmit: async (values) => {
      createFormik.setFieldValue("instruction", values.instruction);
      formik.resetForm();
      setOpen(false);
    },
  });

  const handleChange = (html) => {
    formik.setFieldValue("instruction", html);
  };

  return (
    <div>
      <Drawer
        open={open}
        onClose={handleCloseDrawer}
        className="side-drawer"
        anchor="right"
      >
        <form onSubmit={formik.handleSubmit} style={{ display: "contents" }}>
          <Box variant="div" component="div" sx={BootstrapInput}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              className="sidebar-wrapper p-3"
            >
              <Typography variant="h4" className="text-white">
                {editId ? "Edit" : "Add"} Instructions
              </Typography>
              <Box
                className="close-btn"
                onClick={handleCloseDrawer}
                sx={{ cursor: "pointer" }}
              >
                <RiCloseLine fill="#ffffff" />
              </Box>
            </Stack>
            <Box variant="div" component="div" className="form-wrap p-3">
              <ReactQuill
                theme="snow"
                onChange={handleChange}
                value={formik?.values?.instruction ?? ""}
                name="instruction"
              />
              {formik?.touched?.instruction && formik?.errors?.instruction && (
                <div
                  style={{ margin: "0.5rem" }}
                  className="form-error-message"
                >
                  {formik?.errors?.instruction}
                </div>
              )}
            </Box>
          </Box>

          <Box className="modal-footers">
            <Stack spacing={2} direction="row">
              <Button
                type="submit"
                variant="contained"
                className="primary-button"
              >
                Save
              </Button>
              <Button
                variant="outlined"
                className="secondary-button"
                onClick={handleCloseDrawer}
              >
                Cancel
              </Button>
            </Stack>
          </Box>
        </form>
      </Drawer>
    </div>
  );
};

export default AddInstructions;
