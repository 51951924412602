import React from "react";
import {
  Box,
  Button,
  Paper,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { removeThreadThunk } from "../../../redux/slices/assistants.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import CustomizedDialog from "../Dialog";

const DeleteThread = ({
  isDelete,
  setIsDelete,
  selectedRowData,
  isLoading,
  fetchAllChatHistory,
}) => {
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    let response = await dispatch(removeThreadThunk(id));
    const { payload } = response;
    if (payload && payload?.status) {
      setIsDelete(false);
      fetchAllChatHistory();
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Chat deleted successfully",
        })
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  return (
    <CustomizedDialog
      className="modal-scroll"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={"Are You Sure You Want To Delete ?"}
      open={isDelete}
      handleClose={() => {
        setIsDelete(false);
      }}
    >
      <DialogContent>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Paper
            sx={{
              overflowX: "auto",
              whiteSpace: "pre-wrap",
              boxShadow: "none",
            }}
          >
            {`you want to delete ${selectedRowData.name}`}
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          className="modal-footers"
          sx={{
            display: "flex",
            flexDirection: "row",
            cursor: "pointer",
            justifyContent: "end",
            alignItems: "center",
          }}
        >
          <Button
            sx={{ marginRight: "15px" }}
            variant="contained"
            className="primary-button"
            disabled={isLoading}
            onClick={() => handleDelete(selectedRowData)}
          >
            Delete
          </Button>
          <Button
            variant="outlined"
            className="secondary-button"
            disabled={isLoading}
            onClick={() => setIsDelete(false)}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </CustomizedDialog>
  );
};

export default DeleteThread;
