import React, { useState, useEffect, useMemo } from "react";
import {
  Button,
  Grid,
  Box,
  Paper,
  Card,
  Typography,
  Stack,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import { FaEllipsisVertical } from "react-icons/fa6";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { fetchActionListThunk } from "../../../redux/slices/tenant.slice";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getDefaultActionThunk,
  getParticularWorkflowThunk,
  LatestStoreWorkflowThunk,
  updateLatestWorkflowThunk,
} from "../../../redux/slices/workflow.slice";
import { PAGE_URL } from "../../../constants/pageUrl.constant";
import RunWorkflow from "../../../utils/icon/RunWorkflow";
import PreviousRuns from "../../../utils/icon/PreviousRuns";
import "../../../styles/editWorkflow.scss";
import ExtractWorkflow from "./ExtractWorkflow";
import WhitePlusicon from "../../../utils/icon/WhitePlusicon";
import RunWorkflowHistory from "./runWorkflowHistory";
import ManualTrigger from "./ManualTrigger";
import {
  getAllAssistantsThunk,
  getAllKBFilesThunk,
} from "../../../redux/slices/assistants.slice";
import EditActionKB from "./NewWorkflow/editActionKB";
import ActionSelection from "./actionSelection";

const EditWorkflow = () => {
  const dispatch = useDispatch();
  const [defaultActionList, setDefaultActionList] = useState([]);
  const [OneWorkflowActionList, setOneWorkflowActionList] = useState([]);
  const [openIndex, setOpenIndex] = useState(null);
  const [selectedButtonIndex, setSelectedButtonIndex] = useState(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOpenDrawer, setIsOpenDrawer] = useState(false);
  const [workflowHistory, setWorkflowHistory] = useState(false);
  const [manualTrigger, setManualTrigger] = useState(false);
  const [actionss, setActions] = useState(null);
  const [publicTemplateList, setPublicTemplateList] = useState([]);
  const [actionSelectionList, setActionSelectionList] = useState([]);
  const [allActionList, setAllActionList] = useState([]);
  const [isSave, setIsSave] = useState(false);
  const [KbFiles, setKbFile] = useState([]);
  const Navigate = useNavigate();
  const location = useLocation();
  const [initialValues] = useState({
    configureActions: [
      {
        id: 1,
        configureAction: "Manual Trigger",
      },
    ],
  });

  const [dataRemainsId, setDataRemainsId] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedId, setSelectedId] = useState();
  const open = Boolean(anchorEl);

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedId(id);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedId();
  };

  const dataRemainId = (values) => {
    const dataRemains = values?.configureActions
      ?.slice(1)
      .filter(
        (item) =>
          item?.payload?.template === undefined ||
          (item.configureAction !== "Extraction from CSV file" &&
            item?.payload?.prompt === undefined) ||
          Object.keys(item?.payload?.data_source?.kb).length <= 0
      );
    const dataRemainsmap = dataRemains?.map((item) => item.id);
    setDataRemainsId(dataRemainsmap);
    return dataRemainsmap;
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      configureActions: yup.array().required("Action is required"),
    }),
    onSubmit: async (values) => {
      // setIsLoading(true);
      try {
        if (values?.configureActions.slice(1).length === 0) {
          return;
        }

        const idremain = dataRemainId(values);

        if (idremain.length) {
          return;
        }

        const data = [...values.configureActions.slice(1)].map((item) => ({
          id: item?.id,
          default_action_id: defaultActionList?.filter(
            (ele) => ele?.name === item?.configureAction
          )[0]?.id,
          payload: {
            template: item?.payload?.template,
            data_source: item?.payload?.data_source,
            prompt: {
              is_assistant: item?.payload?.prompt?.is_assistant,
              assistnat_id: item?.payload?.prompt?.assistnat_id,
              user_prompt: item?.payload?.prompt?.user_prompt,
            },
          },
        }));
        const actionData = {
          total_action: values?.configureActions?.slice(1).length,
          payload: data.map((ele, i) => ({ ...ele, id: i + 1 })),
        };

        let response = await dispatch(
          location.state.isEdit
            ? updateLatestWorkflowThunk({
                id: location.state.workflow_id,
                values: actionData,
                is_run: isSave ? "" : true,
              })
            : LatestStoreWorkflowThunk({
                id: location.state.workflow_id,
                values: actionData,
              })
        );
        const { payload } = response;
        if (payload && payload?.status) {
          if (isSave) {
            Navigate(PAGE_URL.WORKFLOW);
          } else {
            if (payload?.data?.run_id) {
              Navigate(PAGE_URL.RUN_WORKFLOW, {
                state: {
                  workflow_id: payload?.data?.run_id,
                },
              });
            }
          }
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: response?.payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      // setIsLoading(false);
    },
  });

  const fetchAssistantDetails = async () => {
    let kbFilesresponse = await dispatch(getAllKBFilesThunk());

    if (kbFilesresponse.payload) {
      const kbs = (kbFilesresponse?.payload?.data || []).map((item) => ({
        id: item.kbId,
        label: item.kbName,
        children: item?.kbfiles?.files
          ? item?.kbfiles?.files?.map((ele) => ({
              id: ele.fileurl,
              label: ele.filename,
              parentId: item.kbId,
            }))
          : [],
      }));
      setKbFile(kbs);
    }
  };

  const isMobile = useMediaQuery("(max-width: 899px)");

  const options = useMemo(
    () => [
      {
        label: "Duplicate",
        onClick: () => {
          const newValue = [...formik.values.configureActions];
          let newObjects = newValue
            .flatMap((e, i) =>
              i === selectedId ? Array(2).fill({ ...e, id: e.id }) : e
            )
            .map((item, i) => ({ ...item, id: i + 1 }));

          formik.setFieldValue("configureActions", newObjects);
          handleClose();
        },
      },
      {
        label: "Delete",
        onClick: () => {
          const newValue = [...formik.values.configureActions];
          const deletedData = newValue.filter((_, i) => i !== selectedId);

          const mappedData = deletedData.map((item, i) => ({
            ...item,
            id: i + 1,
          }));

          formik.setFieldValue("configureActions", mappedData);
          dataRemainId(mappedData);
          handleClose();
        },
      },
    ],
    [formik, selectedId]
  );

  const handleToggle = (event, index) => {
    event.stopPropagation();
    setOpenIndex(openIndex === index ? null : index);
  };

  const kbFormik = useFormik({
    initialValues: { kb: {} },
    validationSchema: yup.object({
      kb: yup.object().required("kb selection is require"),
    }),
  });

  const fetchActionList = async () => {
    let response = await dispatch(fetchActionListThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      setAllActionList(
        payload?.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchPublicActionList = async () => {
    let response = await dispatch(getAllAssistantsThunk({ search: "" }));
    const { payload } = response;
    if (payload && payload?.status) {
      setPublicTemplateList(
        payload?.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchAssistantDetails();
    fetchActionList();
    // eslint-disable-next-line
  }, []);

  const fetchDefaultActionList = async () => {
    let response = await dispatch(getDefaultActionThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      setDefaultActionList(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchWorkflowDetailList = async () => {
    let response = await dispatch(
      getParticularWorkflowThunk(location.state.workflow_id)
    );
    const { payload } = response;
    if (payload && payload?.status) {
      setOneWorkflowActionList(payload?.data);
      const newValue = [
        ...formik.values.configureActions,
        ...payload?.data.action,
      ];
      const EditActions = [
        formik.values.configureActions[0],
        ...payload?.data?.action,
      ];
      formik.setFieldValue(
        "configureActions",
        location.state.isEdit ? EditActions : newValue
      );
      // setDefaultActionList(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const handleStoreAction = () => {
    formik.handleSubmit();
  };

  useEffect(() => {
    fetchActionList();
    fetchPublicActionList();
    fetchDefaultActionList();
    (location?.state?.workflow_id || location?.state?.isEdit) &&
      fetchWorkflowDetailList();
    // eslint-disable-next-line
  }, []);

  const HandleWorkflowHistory = () => {
    setWorkflowHistory(true);
    setIsExpanded(true);
  };

  const handleActionWorkflow = (action) => {
    setIsExpanded(true);
    if (action.configureAction === "Manual Trigger") {
      setManualTrigger(true);
    } else {
      setManualTrigger(false);
    }
    setWorkflowHistory(false);
    const data = formik.values.configureActions.find(
      (item) => item.id === action.id
    );
    kbFormik.setFieldValue("kb", data?.payload?.data_source?.kb ?? {});
    setActions({
      type:
        action.configureAction === "Extract action"
          ? "Extract"
          : action.configureAction === "Criteria Check"
          ? "Criteria Check"
          : "Extraction from CSV file",
      id: action.id,
    });
  };
  return (
    <>
      <Box
        className="content-header mb-4"
        justifyContent={"flex-end !important"}
      >
        <Box
          variant="div"
          component="div"
          className="content-header-right"
          sx={{
            flexDirection: {
              sm: "row !important",
              md: "row !important",
            },
          }}
        >
          {location.state.isEdit && (
            <Button
              variant="contained"
              size="large"
              className="primary-button add-btn"
              disableRipple
              onClick={() => {
                setIsSave(true);
                handleStoreAction();
              }}
            >
              Save
            </Button>
          )}
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            startIcon={<RunWorkflow />}
            disableRipple
            onClick={() => {
              handleStoreAction();
            }}
          >
            Save & Run
          </Button>
          <Button
            variant="contained"
            size="large"
            className="primary-button add-btn"
            startIcon={<PreviousRuns />}
            disableRipple
            onClick={HandleWorkflowHistory}
          >
            View Previous Runs
          </Button>
        </Box>
      </Box>

      <Grid key={0} container spacing={1} sx={{ height: "100%" }}>
        <Grid item xs={12} md={isExpanded ? 6 : 12} sx={{ px: "24px" }}>
          <Paper elevation={0} className="action-background">
            <Grid
              item
              xs={12}
              sm={12}
              md={isExpanded ? 12 : 8}
              lg={isExpanded ? 12 : 8}
              sx={{
                width: "100%",
                margin: "auto",
              }}
            >
              {formik?.values?.configureActions?.length > 0 &&
                formik.values.configureActions.map((actions, i) => (
                  <Grid
                    key={i + 1}
                    container
                    spacing={3}
                    margin={"15x"}
                    sx={{
                      flexDirection: {
                        xs: "column-reverse !important",
                        sm: "column-reverse !important",
                        md: "row !important",
                      },
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={4}
                      className="end"
                      flexDirection={"column"}
                      sx={{
                        paddingTop: {
                          xs: "0 !important",
                          sm: "0 !important",
                          md: "24px !important",
                        },
                      }}
                    >
                      {i > 0 && (
                        <div
                          style={{
                            borderLeft: "5px solid #C1C1C1",
                            height: "100%",
                          }}
                        ></div>
                      )}
                      <Grid container>
                        <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
                        <Grid item xs={6} sm={6} md={6} lg={6}>
                          <div
                            className={
                              isMobile
                                ? `before-vertical-media`
                                : `before-vertical`
                            }
                            style={{
                              borderBottom: isMobile
                                ? "0px"
                                : "5px solid #C1C1C1",
                              height: "2px",
                            }}
                          ></div>
                        </Grid>
                      </Grid>
                      <div
                        style={{
                          borderLeft: "5px solid #C1C1C1",
                          height: i > 0 ? "  100%" : "50%",
                        }}
                      ></div>
                      <Button
                        variant="contained"
                        className="primary-button add-action-button"
                        onClick={() => {
                          setIsOpenDrawer(true);
                          setSelectedButtonIndex(i);
                        }}
                      >
                        <WhitePlusicon />
                      </Button>
                      {formik?.values?.configureActions?.length - 1 !== i && (
                        <div
                          style={{
                            display: isMobile ? "block" : "none",
                          }}
                          className={isMobile ? `after-vertical-media` : ``}
                        ></div>
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      lg={8}
                      className="center"
                      sx={{
                        paddingLeft: {
                          md: "0px !important",
                          sm: "24px !important",
                        },
                      }}
                    >
                      <hr className="horizontal-hr"></hr>
                      <Card
                        onClick={() => handleActionWorkflow(actions)}
                        className={`action-cards ${
                          dataRemainsId?.includes(actions?.id)
                            ? "select-remain-data"
                            : ""
                        }`}
                      >
                        <Stack
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          <Typography
                            variant="subtitle"
                            className="card-action-title"
                          >
                            {actions.configureAction}
                          </Typography>
                          <Box
                            sx={{ position: "relative" }}
                            onClick={(event) => handleToggle(event, i)}
                          >
                            {i > 0 && (
                              <>
                                <IconButton
                                  sx={{ p: 0, m: 0 }}
                                  aria-label="menu"
                                  aria-controls={
                                    open ? "basic-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={open ? "true" : undefined}
                                  onClick={(e) => {
                                    handleClick(e, i);
                                  }}
                                  id="basic-button"
                                >
                                  <FaEllipsisVertical size={16} />
                                </IconButton>
                                <Menu
                                  id="basic-menu"
                                  aria-labelledby="demo-positioned-button"
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}
                                  MenuListProps={{
                                    "aria-labelledby": "basic-button",
                                  }}
                                >
                                  {options?.map((option, index) => (
                                    <MenuItem
                                      key={`${option[index]} + ${index}`}
                                      sx={{
                                        padding: "10px 10px",
                                        fontSize: "14px",
                                      }}
                                      onClick={(event) =>
                                        option.onClick(
                                          // event
                                          actions
                                        )
                                      }
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </Menu>
                              </>
                            )}
                          </Box>
                        </Stack>
                        <hr></hr>
                        {i === 0 && (
                          <Typography
                            variant="h5"
                            className="card-action-subtitle"
                          >
                            Manually start a workflow with custom inputs
                          </Typography>
                        )}
                        {i !== 0 ? (
                          <Stack className="actions-stack">
                            <Stack>
                              <Box
                                sx={{
                                  color: "#273167",
                                }}
                              >
                                {" "}
                                Workflow Action
                              </Box>
                              <Typography
                                variant="h5"
                                className="selected-action"
                              >
                                {allActionList.find((id) => {
                                  return (
                                    id.value === actions?.payload?.template
                                  );
                                })?.label ?? "-"}
                              </Typography>
                            </Stack>
                            {actions.configureAction !==
                              "Extraction from CSV file" && (
                              <Stack>
                                <Typography
                                  variant="subtitle"
                                  sx={{
                                    color: "#273167",
                                  }}
                                >
                                  Selected Assitant
                                </Typography>
                                <Typography
                                  variant="h5"
                                  className="selected-action"
                                >
                                  {publicTemplateList.find((id) => {
                                    return (
                                      id.value ===
                                      actions?.payload?.prompt?.assistnat_id
                                    );
                                  })?.label ?? "-"}
                                </Typography>
                              </Stack>
                            )}
                            {actions?.payload &&
                            actions?.payload?.data_source?.kb &&
                            Object.keys(actions?.payload?.data_source?.kb)
                              ?.length ? (
                              <Stack>
                                <Typography
                                  variant="subtitle"
                                  sx={{
                                    color: "#273167",
                                  }}
                                >
                                  Selected KB
                                </Typography>
                                {actions.configureAction ===
                                "Extraction from CSV file" ? (
                                  <>
                                    {
                                      Object.values(
                                        actions?.payload?.data_source.kb
                                      )?.[0]?.filename
                                    }
                                  </>
                                ) : (
                                  <EditActionKB
                                    configureAction={
                                      formik?.values?.configureActions
                                    }
                                    actions={actions}
                                    KbFiles={KbFiles}
                                    isEdit={location.state.isEdit}
                                  />
                                )}
                              </Stack>
                            ) : (
                              <div></div>
                            )}
                          </Stack>
                        ) : (
                          <div style={{ minHeight: "60px" }}></div>
                        )}
                      </Card>
                    </Grid>
                  </Grid>
                ))}
            </Grid>
          </Paper>
        </Grid>

        {isExpanded && (
          <Grid item xs={12} md={isExpanded ? 6 : 12} sx={{ px: "24px" }}>
            {workflowHistory ? (
              <>
                <RunWorkflowHistory
                  workflow_id={location?.state?.workflow_id}
                  setIsExpanded={setIsExpanded}
                />
              </>
            ) : (
              <>
                {manualTrigger ? (
                  <ManualTrigger
                    Trigger={OneWorkflowActionList?.trigger?.type}
                    setIsExpanded={setIsExpanded}
                  />
                ) : (
                  <ExtractWorkflow
                    globalFormik={formik}
                    kbFormik={kbFormik}
                    actions={actionss}
                    setIsExpanded={setIsExpanded}
                    publicTemplateList={publicTemplateList}
                    setPublicTemplateList={setPublicTemplateList}
                    actionSelectionList={actionSelectionList}
                    setActionSelectionList={setActionSelectionList}
                  />
                )}
              </>
            )}
          </Grid>
        )}
      </Grid>

      <ActionSelection
        setIsOpenDrawer={setIsOpenDrawer}
        isOpenDrawer={isOpenDrawer}
        defaultActionList={defaultActionList}
        setIsExpanded={setIsExpanded}
        selectedButtonIndex={selectedButtonIndex}
        setActions={setActions}
        kbFormik={kbFormik}
        formik={formik}
        setWorkflowHistory={setWorkflowHistory}
      />
    </>
  );
};

export default EditWorkflow;
