import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import "../../styles/auth.scss";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { changePasswordThunk } from "../../redux/slices/user.slice";
import { PAGE_URL } from "../../constants/pageUrl.constant";
import { setSnackbar } from "../../redux/slices/common.slice";
import LoginLogo from "../../assets/logo/PrismViewLogoWhite.png";

const BootstrapInput = {
  "& label": {
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#273167 !important",
      borderWidth: "1px !important",
    },
  },
};

const ChangePassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [oldShowPassword, setOldShowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickOldShowPassword = () => setOldShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
    },
    validationSchema: yup.object({
      oldPassword: yup
        .string()
        .trim()
        .required("Please specify old password")
        .min(6, "The password should have at least 6 characters"),
      newPassword: yup
        .string()
        .test(
          "match",
          "New password matched please change",
          function (newPassword) {
            return newPassword !== this.parent.oldPassword;
          }
        )
        .required("Please specify new password")
        .min(6, "The password should have at least 6 characters"),
    }),
    onSubmit: async (values) => {
      setisLoading(true);
      try {
        let response = await dispatch(
          changePasswordThunk({
            current_password: values.oldPassword,
            new_password: values.newPassword,
          })
        );
        const { payload } = response;
        if (payload && payload.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "Password changed Successfully",
            })
          );
          navigate(PAGE_URL.LOGIN);
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: response.payload.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
        console.error("Error during login:", error);
      }
      setisLoading(false);
    },
  });

  useEffect(() => {
    const access_token = JSON.parse(localStorage?.getItem("user")).access_token;

    if (!access_token) {
      navigate(PAGE_URL.LOGIN);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Box className="login-wrapper p-5">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8} md={5} lg={4}>
          <Box className="text-center logo-wrap mb-5">
            <Link to="/">
              <img src={LoginLogo} alt="Logo" />
            </Link>
          </Box>
          <Paper component="form" elevation={1} className="p-4">
            <Typography variant="h3" className="mb-4 text-center">
              Change Password for your account
            </Typography>

            <Stack spacing={3}>
              <FormControl variant="outlined" sx={BootstrapInput}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Old Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-email"
                  type={oldShowPassword ? "text" : "password"}
                  label="old password"
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickOldShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {oldShowPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility sx={{ fill: "#273167" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  {...formik.getFieldProps("oldPassword")}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                  <div className="form-error-message">
                    {formik.errors.oldPassword}
                  </div>
                )}
              </FormControl>
              <FormControl variant="outlined" sx={BootstrapInput}>
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  {...formik.getFieldProps("newPassword")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility sx={{ fill: "#273167" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                  <div className="form-error-message">
                    {formik.errors.newPassword}
                  </div>
                )}
              </FormControl>
              <Button
                variant="contained"
                className="primary-button mt-2"
                sx={{ mt: "24px !important" }}
                disabled={isLoading}
                size="large"
                onClick={formik.handleSubmit}
              >
                {isLoading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Change password
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ChangePassword;
