import React from "react";
import "../../styles/footer.scss";
import { Box, Container, useMediaQuery } from "@mui/material";

const NewFooter = () => {
  const isTablet = useMediaQuery("(min-width: 601px) and (max-width: 960px)");
  const isLaptop = useMediaQuery("(min-width: 961px) and (max-width: 1280px)");
  const isDesktop = useMediaQuery("(min-width: 1281px)");

  const MaxWidthValue = isDesktop
    ? "lg"
    : isLaptop
    ? "md"
    : isTablet
    ? "sm"
    : "xs";

  return (
    <>
      <Box className="footer-wrapper">
        <Container maxWidth={MaxWidthValue}>
          <Box className="footer-container"></Box>
        </Container>
      </Box>
    </>
  );
};

export default NewFooter;
