import { useState, useEffect, useRef, useCallback } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  subDays,
  subMonths,
  format,
  parse,
  startOfMonth,
  endOfMonth,
  isValid,
} from "date-fns";
import { enUS } from "date-fns/locale";
import {
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { FaCalendarAlt } from "react-icons/fa";
import { MdClear } from "react-icons/md";
import PropTypes from "prop-types";
import "../../styles/reports.scss";

const DATE_FORMAT = "MM-dd-yyyy";

const DateRangePicker = ({
  dateRange,
  onDateRangeChange,
  machineFilter,
  disableClearable,
}) => {
  const [initialStartDate, initialEndDate] = dateRange;
  const [startDate, setStartDate] = useState(() => {
    if (!initialStartDate) return null;
    const parsedDate = parse(initialStartDate, DATE_FORMAT, new Date(), {
      locale: enUS,
    });
    return isValid(parsedDate) ? parsedDate : null;
  });
  const [endDate, setEndDate] = useState(() => {
    if (!initialEndDate) return null;
    const parsedDate = parse(initialEndDate, DATE_FORMAT, new Date(), {
      locale: enUS,
    });
    return isValid(parsedDate) ? parsedDate : null;
  });

  const [isOpen, setIsOpen] = useState(false);
  const [prevStartDate, setPrevStartDate] = useState(null);
  const [prevEndDate, setPrevEndDate] = useState(null);
  const pickerRef = useRef(null);

  const formatDateRange = useCallback((start, end) => {
    return `${start ? format(start, DATE_FORMAT) : ""}${
      end ? ` ~ ${format(end, DATE_FORMAT)}` : ""
    }`;
  }, []);

  const [selectedDates, setSelectedDates] = useState(() =>
    formatDateRange(startDate, endDate)
  );

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const handleOk = () => {
    if (startDate && endDate) {
      setSelectedDates(formatDateRange(startDate, endDate));
      const start = format(startDate, DATE_FORMAT);
      const end = format(endDate, DATE_FORMAT);
      onDateRangeChange([start, end]);
      setPrevStartDate(startDate);
      setPrevEndDate(endDate);
      setIsOpen(false);
    }
  };

  const handleCancel = () => {
    setStartDate(prevStartDate);
    setEndDate(prevEndDate);
    setIsOpen(false);
  };

  const directDateChange = (startDate, endDate) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setSelectedDates(formatDateRange(startDate, endDate));
    setPrevStartDate(startDate);
    setPrevEndDate(endDate);
    const start = format(startDate, DATE_FORMAT);
    const end = format(endDate, DATE_FORMAT);
    onDateRangeChange([start, end]);
    setIsOpen(false);
  };

  const handlePresetClick = (preset) => {
    const today = new Date();
    const presets = {
      today: [today, today],
      yesterday: [subDays(today, 1), subDays(today, 1)],
      last7Days: [subDays(today, 6), today],
      thisMonth: [startOfMonth(today), endOfMonth(today)],
      last30Days: [subDays(today, 29), today],
      last6Months: [subMonths(today, 6), today],
    };

    const [start, end] = presets[preset] || [null, null];
    if (start && end) {
      directDateChange(start, end);
    }
  };

  const handleClickOutside = useCallback(
    (event) => {
      if (pickerRef.current && !pickerRef.current.contains(event.target)) {
        if (prevStartDate && prevEndDate) {
          setStartDate(prevStartDate);
          setEndDate(prevEndDate);
        }
        setIsOpen(false);
      }
    },
    [prevStartDate, prevEndDate]
  );

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
    setPrevStartDate(null);
    setPrevEndDate(null);
    setSelectedDates("");
    onDateRangeChange([]);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen) {
        handleClickOutside(event);
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen, handleClickOutside]);

  useEffect(() => {
    if (prevStartDate && prevEndDate) {
      setSelectedDates(formatDateRange(prevStartDate, prevEndDate));
    }
  }, [prevStartDate, prevEndDate, formatDateRange]);

  return (
    <div className="date-range-picker" ref={pickerRef}>
      <FormControl
        variant="standard"
        className="form-control datepicker-form-control"
        size="small"
        height={"100%"}
      >
        <TextField
          value={selectedDates}
          onFocus={() => setIsOpen(true)}
          type="text"
          sx={{ height: "100%" }}
          placeholder="Select date"
          className="input-field filter-input"
          size="small"
          InputProps={{
            readOnly: true,
            style: { width: 265, height: "100%" },
            endAdornment: disableClearable ? null : (
              <InputAdornment position="end">
                <IconButton
                  aria-label={
                    selectedDates ? "Clear dates" : "Open date picker"
                  }
                  onClick={
                    selectedDates ? handleClear : () => setIsOpen(!isOpen)
                  }
                >
                  {selectedDates ? (
                    <MdClear fontSize={18} />
                  ) : (
                    <FaCalendarAlt fontSize={18} opacity={0.8} />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
      {isOpen && (
        <div
          className={`date-picker-container ${
            machineFilter ? "machine-date" : ""
          }`}
        >
          <DatePicker
            selected={startDate}
            onChange={onChange}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            inline
            maxDate={new Date()}
          />
          <div className="preset-options">
            {[
              "today",
              "yesterday",
              "last7Days",
              "thisMonth",
              "last30Days",
              "last6Months",
            ].map((preset) => (
              <Button
                key={preset}
                variant="outlined"
                className="date-btn"
                onClick={() => handlePresetClick(preset)}
              >
                {preset === "last7Days"
                  ? "Last 7 Days"
                  : preset === "last30Days"
                  ? "Last 30 Days"
                  : preset === "last6Months"
                  ? "Last 6 Months"
                  : preset.charAt(0).toUpperCase() +
                    preset
                      .slice(1)
                      .replace(/([A-Z])/g, " $1")
                      .trim()}
              </Button>
            ))}
            <Stack gap={2} direction="row" marginTop={"15px"}>
              <Button
                variant="contained"
                onClick={handleOk}
                className={
                  !startDate || !endDate ? "apply-btn disabled " : "apply-btn"
                }
                disabled={!startDate || !endDate}
              >
                Apply
              </Button>
              <Button
                variant="contained"
                className="secondary cancel-btn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </Stack>
          </div>
        </div>
      )}
    </div>
  );
};

DateRangePicker.propTypes = {
  dateRange: PropTypes.arrayOf(PropTypes.string).isRequired,
  onDateRangeChange: PropTypes.func.isRequired,
  machineFilter: PropTypes.bool,
  disableClearable: PropTypes.bool,
};

export default DateRangePicker;
