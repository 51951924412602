import React from "react";

const Email = () => {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1641 17.5834H5.83073C3.33073 17.5834 1.66406 16.3334 1.66406 13.4167V7.58342C1.66406 4.66675 3.33073 3.41675 5.83073 3.41675H14.1641C16.6641 3.41675 18.3307 4.66675 18.3307 7.58342V13.4167C18.3307 16.3334 16.6641 17.5834 14.1641 17.5834Z"
        stroke="#A1A1AA"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.1693 8L11.5609 10.0833C10.7026 10.7667 9.29427 10.7667 8.43593 10.0833L5.83594 8"
        stroke="#A1A1AA"
        strokeWidth="1.2"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Email;
