import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import RightArrow from "../../../../utils/icon/RightArrow";
import CopyToClipboard from "../../../../utils/icon/copyToClipboard";
import Markdown from "react-markdown";
import "../../../../styles/runworkflow.scss";

const ChildAccordian = ({ item, index }) => {
  const [expanded, setExpanded] = useState([]);
  const handleCopyToClicpboard = (id, index) => {
    const copyDiv = document.getElementById(
      `copy-${index}-id-${id + 1}`
    )?.innerHTML;

    const stripHTMLTags = (str) => str.replace(/<[^>]*>/g, "");

    stripHTMLTags(copyDiv);

    navigator.clipboard.writeText(stripHTMLTags(copyDiv));
  };

  const handleAccordionChange = (expanded, index) => {
    setExpanded((prev) =>
      expanded ? [...prev, index] : [...prev].filter((ele) => ele !== index)
    );
  };

  const handleExpandAll = (expandAll) => {
    setExpanded(
      expandAll
        ? []
        : item?.action_response?.map((_, i) => {
            return i;
          })
    );
  };
  return (
    <>
      <AccordionDetails className="elevation-response">
        {item?.action_response.length &&
        item?.action_response[0].term !== "error message" ? (
          <Box className="end">
            <Button
              className="workflow-finished-btn"
              sx={{ margin: "10px" }}
              onClick={() =>
                handleExpandAll(
                  item?.action_response.length === expanded.length
                )
              }
            >
              {item?.action_response.length === expanded.length
                ? "Collapse All"
                : "Expand All"}
            </Button>
          </Box>
        ) : (
          ""
        )}
        {item?.action_response.length &&
        item?.action_response[0].term !== "error message" ? (
          item?.action_response?.map((terms, i) => {
            return (
              <Accordion
                expanded={expanded.includes(i)}
                onChange={(e, expanded) => handleAccordionChange(expanded, i)}
                key={i}
                className="child-accordion"
              >
                <AccordionSummary
                  expandIcon={<RightArrow />}
                  aria-controls={`panel${i + 1}-content`}
                  id={`panel${i + 1}-header`}
                  sx={{ margin: "0px" }}
                >
                  <Typography
                    variant="subtitle"
                    sx={{ margin: "0px", fontSize: "16px" }}
                  >
                    {terms?.term}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className="child-accordion-details">
                  <Paper elevation={0} className="child-accordion-data">
                    <IconButton
                      className="copy-clipboard-button"
                      onClick={() => {
                        handleCopyToClicpboard(i, index);
                      }}
                    >
                      {" "}
                      <CopyToClipboard />
                    </IconButton>
                    <div id={`copy-${index}-id-${i + 1}`}>
                      <Markdown>{terms?.response}</Markdown>
                    </div>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            );
          })
        ) : (
          <Paper elevation={0} className="elevation-response">
            {item[0]?.response ?? "Response has been failed"}
          </Paper>
        )}
      </AccordionDetails>
    </>
  );
};
export default ChildAccordian;
