import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import {
  Button,
  Box,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  Avatar,
  DialogActions,
  Paper,
  Skeleton,
  IconButton,
  DialogContent,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { HiOutlineTrash } from "react-icons/hi";
import { setSnackbar } from "../../redux/slices/common.slice";
import {
  fetchChatbotDetailThunk,
  updateChatbotThunk,
  uploadLogoThunk,
  uploadchatbotFileThunk,
  uploadchatboturlThunk,
  removeDocThunk,
} from "../../redux/slices/chatbot.slice";
import ChatBot from "./chatbot";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import "../../styles/configChat.scss";
import Edit from "../../assets/icons/edit.svg";
import UploadUrl from "../../assets/icons/uploadUrl.svg";
import UploadUrlWhite from "../../assets/icons/uploadUrlWhite.svg";
import Uploadfile from "../../assets/icons/uploadfile.svg";
import UploadfileWhite from "../../assets/icons/uploadfileWhite.svg";
import Delete from "../../assets/icons/delete.svg";
import CustomizedDialog from "./Dialog";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const ColorPickerInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .colorPicker": {
    "& .MuiOutlinedInput-root": {
      border: "none !important",
      "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "0 !important",
        borderWidth: "0 !important",
        borderColor: "transparent !important",
      },
    },
  },
  "& .MuiOutlinedInput-root": {
    border: "none !important",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "0 !important",
      borderColor: "#000000 !important",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000000 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "30px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "rgba(39, 49, 103, 0.1)",
  borderStyle: "dashed",
  backgroundColor: "rgba(39, 49, 103, 0.1)",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

const ScrollBars = {
  "&::-webkit-scrollbar": {
    width: " 4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cccccc",
  },
};

const ConfigChatBase = () => {
  const [chatbotFile, setChatbotFile] = useState([]);
  const [kbFile, setKbFile] = useState([]);
  const [uploadUrl, setUploadUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [downloadModal, setDownloadModal] = useState(false);
  const [imageLoading, setImageLoading] = useState(false);
  const [uploadLoading, setUploadLoading] = useState(false);
  const [fileUploadDialog, setFileUploadDialog] = useState(false);
  const [chatbotDetail, setChatbotDetail] = useState({});
  const [isChatBotVisible, setChatBotVisibility] = useState(false);
  const handleChatBotToggle = () => {
    setChatBotVisibility(!isChatBotVisible);
  };

  const onDrop = useCallback((acceptedFiles) => {
    setKbFile(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const dispatch = useDispatch();
  useEffect(() => {
    fetchChatbotDetail();
    // eslint-disable-next-line
  }, []);

  const formik = useFormik({
    initialValues: {
      title: chatbotDetail?.title || "",
      initial_message: chatbotDetail?.initial_message || "",
    },
    validationSchema: yup.object({
      title: yup.string().trim().required("Name is required"),
      initial_message: yup.string().required("Initial Text is required"),
      color_schema: yup.object().optional(),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      let response = await dispatch(updateChatbotThunk(values));
      if (response?.payload?.status) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Updated successfully",
          })
        );
        handleFileUploadClose();
        fetchChatbotDetail();
      } else
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: response?.payload?.message || "Internal server error",
          })
        );
      setIsLoading(false);
    },
  });

  const fetchChatbotDetail = async () => {
    setIsLoading(true);
    let response = await dispatch(fetchChatbotDetailThunk());
    const { payload } = response;
    if (payload?.status) {
      setChatbotDetail(payload?.data);
      formik.setFieldValue("title", payload?.data.title);
      formik.setFieldValue("initial_message", payload?.data.initial_message);
      formik.setFieldValue("color_schema", payload?.data.color_schema);
      setChatbotFile(payload?.data.documents.document);
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    setIsLoading(false);
  };

  const handleRemoveDoc = async (urlname) => {
    let response = await dispatch(removeDocThunk({ url: urlname }));
    const { payload } = response;
    if (payload && payload?.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Successfully Removed",
        })
      );
      fetchChatbotDetail();
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };
  const handleFileUploadClose = async () => {
    setFileUploadDialog(false);
    setKbFile([]);
    setUploadUrl("");
  };

  const handleRemoveFile = useCallback((index) => {
    setKbFile((prevFiles) => prevFiles.filter((file, i) => i !== index));
  }, []);

  const handleFileUpload = async () => {
    setUploadLoading(true);
    let response = await dispatch(
      fileUploadDialog === "file"
        ? uploadchatbotFileThunk({ file: kbFile })
        : uploadchatboturlThunk({ url: uploadUrl })
    );
    if (response.payload.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Uploaded successfully",
        })
      );
      handleFileUploadClose();
      fetchChatbotDetail();
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response.payload.message || "Internal server error",
        })
      );
    setUploadLoading(false);
  };

  const handleLogo = (event) => {
    const file = event.target.files[0];

    if (file) {
      // Call the function to upload the image
      uploadImage(file);

      // Optionally, you can reset the input value to allow selecting the same file again
      event.target.value = null;
    }
  };

  const uploadImage = async (file) => {
    setImageLoading(true);
    let response = await dispatch(uploadLogoThunk({ file }));
    if (response.payload.status) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Uploaded successfully",
        })
      );
      fetchChatbotDetail();
    } else
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response.payload.message || "Internal server error",
        })
      );
    setImageLoading(false);
  };
  const scriptContent = `<!-- Place the following script in your root file/index.html above </body> -->
  <script>localStorage.setItem('chatbotToken', "${chatbotDetail?.token}")</script>
  <script src="${process.env.REACT_APP_EMBEDDED_JS_FILE}"></script>
  <link href="${process.env.REACT_APP_EMBEDDED_CSS_FILE}" rel="stylesheet">
  `;
  const handleDownloadClick = () => {
    const blob = new Blob([scriptContent], { type: "text/plain" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "prism-chatbot-script.txt";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
    setDownloadModal(false);
  };

  function isTooDark(hexcolor) {
    const r = parseInt(hexcolor?.substr(1, 2), 16);
    const g = parseInt(hexcolor?.substr(3, 2), 16);
    const b = parseInt(hexcolor?.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    // Return new color if to dark, else return the original
    return yiq < 40 ? "color-box-white" : "color-box-black";
  }

  const fileUploadModal = () => {
    return (
      <>
        <CustomizedDialog
          className="modal-scroll"
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          title={fileUploadDialog === "file" ? "Upload Files" : "URL"}
          open={fileUploadDialog}
          handleClose={() => {
            handleFileUploadClose();
          }}
        >
          <Box variant="div" component="div">
            {fileUploadDialog === "file" ? (
              <>
                <Box className="dialoag-contents">
                  <div {...getRootProps({ style })}>
                    <input
                      id="fileInput"
                      type="file"
                      multiple
                      // onChange={handleFileChange}
                      {...getInputProps()}
                    />
                    <p>
                      Drag 'n' drop some files here, or click to select files
                    </p>
                    <p>Maximum File Size: 15MB</p>
                  </div>
                  {kbFile.length > 0 && (
                    <Box
                      variant="div"
                      component="div"
                      className="uploded_files-wrapper"
                    >
                      {kbFile.map((file, index) => (
                        <Stack
                          key={index}
                          sx={{ mt: 1 }}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="label" sx={{ flex: 1 }}>
                            {file.name}
                          </Typography>
                          <Typography variant="span" sx={{ mr: 1 }}>
                            {(file.size / 1024 / 1024).toFixed(2)} Mb
                          </Typography>
                          <Box
                            className="icon-wrap"
                            onClick={() => handleRemoveFile(index)}
                          >
                            {" "}
                            <HiOutlineTrash size="20" />{" "}
                          </Box>
                        </Stack>
                      ))}
                    </Box>
                  )}
                </Box>
              </>
            ) : (
              <>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Box className="dialoag-contents">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label=""
                      onChange={(e) => setUploadUrl(e.target.value)}
                      variant="outlined"
                      placeholder="Enter URL"
                      size="small"
                      name="url"
                    />
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <DialogActions>
            <Box
              className="modal-footers"
              sx={{
                display: "flex",
                flexDirection: "row",
                cursor: "pointer",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button
                onClick={handleFileUploadClose}
                sx={{ margin: "15px !important" }}
                className="secondary-button"
                variant="outlined"
              >
                Cancel
              </Button>
              <Button
                onClick={handleFileUpload}
                className="primary-button"
                variant="contained"
                disabled={uploadLoading}
              >
                {uploadLoading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Upload
              </Button>
            </Box>
          </DialogActions>
        </CustomizedDialog>
      </>
    );
  };

  return (
    <>
      <div className="chatbot pb-3">
        <Box variant="div" component="div" className="pb-3">
          <form>
            <Grid
              container
              spacing={2}
              sx={{ display: "flex", marginLeft: "0px", width: "100%" }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                lg={4}
                xl={3}
                className="first-grid-chat-config media-padding"
              >
                <Paper
                  elevation={0}
                  sx={{ padding: "16px" }}
                  className="first-grid-chat-config-paper profile_component"
                >
                  <Grid container rowSpacing={3}>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="chatbot-logo-section"
                    >
                      <Box sx={{ borderRadius: "50%" }}>
                        {imageLoading ? (
                          <Skeleton
                            variant="circular"
                            className="chatbot-detail-logo-loader"
                          />
                        ) : (
                          <Box className="chatbot-deatil-logo">
                            <Avatar
                              alt="User Profile Image"
                              src={chatbotDetail.logo}
                              className="chatbot-detail-logo-avatar"
                            />
                            <IconButton className="chatbot-detail-logo-avatar-edit">
                              <Box id="profile-upload-holder">
                                <label
                                  style={{ cursor: "pointer" }}
                                  htmlFor="profile-upload"
                                >
                                  <img
                                    alt="Edit"
                                    src={Edit}
                                    width={24}
                                    height={24}
                                  />
                                </label>
                                <input
                                  id="profile-upload"
                                  type="file"
                                  accept="image/*"
                                  style={{ display: "none" }}
                                  onChange={handleLogo}
                                />
                              </Box>
                            </IconButton>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography variant="body1" component="label">
                          Name
                        </Typography>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          label=""
                          variant="outlined"
                          placeholder="Enter Name"
                          size="small"
                          name="title"
                          {...formik.getFieldProps("title")}
                        />
                        {formik.touched.title && formik.errors.title && (
                          <div className="form-error-message">
                            {formik.errors.title}
                          </div>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Box variant="div" component="div" sx={BootstrapInput}>
                        <Typography variant="body1" component="label">
                          Initial Text
                        </Typography>
                        <TextField
                          fullWidth
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Enter Description"
                          size="small"
                          name="initial_message"
                          {...formik.getFieldProps("initial_message")}
                          rows={3}
                          multiline
                        />
                        {formik.touched.initial_message &&
                          formik.errors.initial_message && (
                            <div className="form-error-message">
                              {formik.errors.initial_message}
                            </div>
                          )}
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={8}
                lg={8}
                xl={9}
                className="media-padding"
              >
                <Paper
                  sx={{
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                  elevation={0}
                  className="color-schema-section"
                >
                  <Typography variant="h3" className="mb-3">
                    Color Schema
                  </Typography>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{ ...ColorPickerInput }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          ChatBot Background
                        </Typography>
                        <Box className="more_colors">
                          <TextField
                            fullWidth
                            className={
                              "color-name-field " +
                              isTooDark(
                                formik?.values?.color_schema
                                  ?.chatbot_background_color
                              )
                            }
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_background_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_background_color"
                            )}
                          />
                          <TextField
                            id="colorpickerr"
                            type="color"
                            value="#aeb2cb"
                            className="colorPicker color-box"
                            fullWidth
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_background_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_background_color"
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{ ...ColorPickerInput }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          Button Background
                        </Typography>

                        <Box className="more_colors">
                          <TextField
                            fullWidth
                            className={
                              "color-name-field " +
                              isTooDark(
                                formik?.values?.color_schema
                                  ?.chatbot_button_background_color
                              )
                            }
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_button_background_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_button_background_color"
                            )}
                          />
                          <TextField
                            id="colorpickerr"
                            type="color"
                            value="#aeb2cb"
                            className="colorPicker color-box"
                            fullWidth
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_button_background_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_button_background_color"
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{ ...ColorPickerInput }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          Bot Message Background
                        </Typography>
                        <Box className="more_colors">
                          <TextField
                            fullWidth
                            className={
                              "color-name-field " +
                              isTooDark(
                                formik?.values?.color_schema
                                  ?.chatbot_message_background_color
                              )
                            }
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_message_background_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_message_background_color"
                            )}
                          />
                          <TextField
                            id="colorpickerr"
                            type="color"
                            value="#aeb2cb"
                            className="colorPicker color-box"
                            fullWidth
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_message_background_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_message_background_color"
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{ ...ColorPickerInput }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          User Message Background
                        </Typography>
                        <Box className="more_colors">
                          <TextField
                            fullWidth
                            className={
                              "color-name-field " +
                              isTooDark(
                                formik?.values?.color_schema
                                  ?.user_message_background_color
                              )
                            }
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            name="color_schema.user_message_background_color"
                            {...formik.getFieldProps(
                              "color_schema.user_message_background_color"
                            )}
                          />
                          <TextField
                            id="colorpickerr"
                            type="color"
                            value="#aeb2cb"
                            className="colorPicker color-box"
                            fullWidth
                            label=""
                            variant="outlined"
                            name="color_schema.user_message_background_color"
                            {...formik.getFieldProps(
                              "color_schema.user_message_background_color"
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{ ...ColorPickerInput }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          Bot Message Text color
                        </Typography>
                        <Box className="more_colors">
                          <TextField
                            fullWidth
                            className={
                              "color-name-field " +
                              isTooDark(
                                formik?.values?.color_schema
                                  ?.chatbot_message_text_color
                              )
                            }
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_message_text_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_message_text_color"
                            )}
                          />
                          <TextField
                            id="colorpickerr"
                            type="color"
                            value="#aeb2cb"
                            className="colorPicker color-box"
                            fullWidth
                            label=""
                            variant="outlined"
                            name="color_schema.chatbot_message_text_color"
                            {...formik.getFieldProps(
                              "color_schema.chatbot_message_text_color"
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6} xl={4}>
                      <Box
                        variant="div"
                        component="div"
                        sx={{ ...ColorPickerInput }}
                      >
                        <Typography
                          variant="body1"
                          component="label"
                          sx={{ textAlign: "center", paddingBottom: "10px" }}
                        >
                          User Message Text color
                        </Typography>
                        <Box className="more_colors">
                          <TextField
                            fullWidth
                            className={
                              "color-name-field " +
                              isTooDark(
                                formik?.values?.color_schema
                                  ?.user_message_text_color
                              )
                            }
                            id="outlined-basic"
                            label=""
                            variant="outlined"
                            name="color_schema.user_message_text_color"
                            {...formik.getFieldProps(
                              "color_schema.user_message_text_color"
                            )}
                          />
                          <TextField
                            id="colorpickerr"
                            type="color"
                            value="#aeb2cb"
                            className="colorPicker color-box"
                            fullWidth
                            label=""
                            variant="outlined"
                            name="color_schema.user_message_text_color"
                            {...formik.getFieldProps(
                              "color_schema.user_message_text_color"
                            )}
                          />
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="media-padding"
              >
                <Paper elevation={0} className="fileUrl-section">
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h3">Files & URL</Typography>
                    <Box variant="div" component="div" marginTop={"15px"}>
                      <Button
                        variant="outlined"
                        sx={{ mr: 1 }}
                        className="upload-button"
                        onClick={() => {
                          setFileUploadDialog("file");
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={0.5}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img
                            alt="uploadfile"
                            src={Uploadfile}
                            width={24}
                            height={24}
                          />
                          <Typography variant="label" color={"#036185"}>
                            Upload file
                          </Typography>
                        </Stack>
                      </Button>
                      <Button
                        variant="outlined"
                        className="upload-button"
                        onClick={() => {
                          setFileUploadDialog("url");
                        }}
                      >
                        <Stack
                          direction="row"
                          spacing={0.5}
                          justifyContent="center"
                          alignItems="center"
                        >
                          <img
                            alt="uploadUrl"
                            src={UploadUrl}
                            width={24}
                            height={24}
                          />

                          <Typography variant="label" color={"#036185"}>
                            Upload URL
                          </Typography>
                        </Stack>
                      </Button>
                    </Box>
                  </Box>
                  <Box variant="div" component="div" sx={BootstrapInput}>
                    {chatbotFile.length > 0 && (
                      <Box variant="div" component="div" sx={{ mt: 1 }}>
                        <Stack
                          key={"header-1"}
                          className="table-header"
                          sx={{ mt: 1 }}
                          direction="row"
                          alignItems="center"
                          justifyContent="space-between"
                        >
                          <Typography variant="p" sx={{ width: "350px" }}>
                            Name
                          </Typography>
                          <Typography variant="p" sx={{ width: "150px" }}>
                            Type
                          </Typography>
                          <Typography variant="p" sx={{ width: "75px" }}>
                            Size
                          </Typography>
                          <Box className="icon-wrap" sx={{ width: "1.5rem" }}>
                            {" "}
                          </Box>
                        </Stack>
                        <Box
                          variant="div"
                          component="div"
                          className="fileUrl-listing scrolling-area"
                        >
                          {chatbotFile.map((file, index) => (
                            <Stack
                              key={index}
                              sx={{
                                p: "10px",
                                minHeight: "10px",
                                backgroundColor: `${
                                  index % 2 === 0 ? "#2731673b" : "inherit"
                                }`,
                              }}
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Typography variant="p" sx={{ width: "350px" }}>
                                <a href={file.file_location}>
                                  {file.file_name}
                                </a>
                              </Typography>
                              <Typography variant="p" sx={{ width: "150px" }}>
                                {file.file_type}
                              </Typography>
                              <Typography variant="p" sx={{ width: "75px" }}>
                                {(file.file_size / 1024 / 1024).toFixed(2)} Mb
                              </Typography>
                              <Box
                                className="icon-wrap"
                                sx={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleRemoveDoc(file.file_location)
                                }
                              >
                                {" "}
                                <img
                                  alt="Delete"
                                  src={Delete}
                                  width={24}
                                  height={24}
                                />
                              </Box>
                            </Stack>
                          ))}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </form>
        </Box>
        <Box className="content-header justify-content-center kb-list-header chatbot">
          {/* <Typography variant="h2" component="h2">Chatbot</Typography> */}
          <Box
            variant="div"
            component="div"
            className="content-header-right-chat"
          >
            <Button
              variant="contained"
              className="primary-button"
              onClick={() => {
                setDownloadModal(true);
              }}
            >
              <Stack
                direction="row"
                spacing={0.5}
                justifyContent="center"
                alignItems="center"
              >
                <img
                  alt="uploadFile"
                  src={UploadfileWhite}
                  width={24}
                  height={24}
                />
                <Typography
                  // style={{ fontSize: "18px", padding: "5px" }}
                  variant="label"
                  ml="10px !important"
                >
                  Download Script
                </Typography>
              </Stack>
            </Button>
            <Button
              variant="contained"
              className="primary-button"
              onClick={formik.handleSubmit}
            >
              <img
                alt="UploadUrl"
                src={UploadUrlWhite}
                width={24}
                height={24}
              />
              {isLoading && (
                <CircularProgress
                  size="1rem"
                  sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                />
              )}
              <Typography
                // style={{ fontSize: "18px", padding: "5px" }}
                variant="label"
                ml="10px !important"
              >
                Save
              </Typography>
            </Button>
          </Box>
        </Box>
        {fileUploadModal()}
        <div className="chatbot-logo">
          {!isChatBotVisible && (
            <Box className="chat-avatar_wrapper">
              <Box className="avatar-wrapper">
                <ChatBotAvatar
                  sx={{ cursor: "pointer" }}
                  onClick={handleChatBotToggle}
                  chatbotDetail={chatbotDetail}
                />
              </Box>
            </Box>
          )}
          {isChatBotVisible && (
            <ChatBot
              chatbotDetail={chatbotDetail}
              handleChatBotToggle={handleChatBotToggle}
            />
          )}
        </div>
      </div>

      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        title={"Download Script"}
        open={downloadModal}
        handleClose={() => {
          setDownloadModal(false);
        }}
      >
        <DialogContent sx={ScrollBars}>
          <Box sx={{ mt: 2, mb: 2 }}>
            <Paper
              variant="outlined"
              sx={{ p: 2, overflowX: "auto", whiteSpace: "pre-wrap" }}
            >
              {scriptContent}
            </Paper>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            onClick={handleDownloadClick}
            sx={{
              display: "flex",
              cursor: "pointer",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
              border: "solid 1px",
              borderRadius: "8px",
            }}
            // className="modal-footers"
          >
            <IconButton>
              <FileDownloadIcon />
            </IconButton>
            <Typography variant="p" sx={{ marginX: 1 }}>
              Download Script
            </Typography>
          </Box>
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};
const ChatBotAvatar = ({ onClick, chatbotDetail }) => (
  <Box onClick={onClick}>
    {/* Customize your ChatBot avatar here */}
    <Avatar
      src={chatbotDetail.logo}
      sx={{ width: "4rem", height: "4rem" }}
      alt="ChatBot Avatar"
    />
  </Box>
);

export default ConfigChatBase;
