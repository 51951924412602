import React from "react";
import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  Stack,
  Typography,
} from "@mui/material";
import Download from "../../../utils/icon/Download";
import RightArrow from "../../../utils/icon/RightArrow";
import "../../../styles/editWorkflow.scss";
import "../../../styles/runworkflow.scss";
import { HandleDownload } from "./utils/DownloadExcel";
import ChildAccordian from "./NewWorkflow/ChildAccordian";

const ResultWorkflow = ({ selectedHistory }) => {
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack flexDirection="column">
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" className="title-result-workflow">
              Results
            </Typography>
            <Button className="workflow-finished-btn">Workflow Finished</Button>
          </Stack>
          <Box sx={{ paddingBottom: "20px" }}>
            <Typography className="workflow-schedule" variant="subtitle">
              Learn how to use: Run the workflow on a schedulee
            </Typography>
          </Box>
        </Stack>

        <Button
          variant="contained"
          className="primary-button mt-2 workflow-finished-btn"
          disabled={selectedHistory?.response.length === 0}
          sx={{
            padding: "8px",
          }}
          onClick={() => HandleDownload(selectedHistory?.response)}
        >
          <Download />
        </Button>
      </Stack>

      <Box display="flex" flexDirection="column" gap={"15px"}>
        {selectedHistory?.response.map((item, index) => (
          <div
            key={`${index}-${index + 1}`}
            style={{ display: "flex", position: "relative", gap: "15px" }}
          >
            {index !== selectedHistory.response.length - 1 && (
              <div className="circle-line-mian-div">
                <div className="line-div" />
              </div>
            )}
            <div className="outer-circle">
              <div className="inner-circle"></div>
            </div>
            <Accordion key={index} className="main-accordian">
              <AccordionSummary
                expandIcon={<RightArrow />}
                aria-controls={`panel${index + 1}-content`}
                id={`panel${index + 1}-header`}
                className="accordian-workflow-history main-accordian-summury"
              >
                <Typography
                  variant="h6"
                  sx={{ margin: "0px", fontSize: "18px", fontWeight: "600" }}
                >
                  {item?.action_response
                    ? item.action_name
                    : item[0]?.term ?? item.action_name}
                </Typography>
              </AccordionSummary>
              <ChildAccordian item={item} index={index} />
            </Accordion>
          </div>
        ))}
      </Box>
    </>
  );
};

export default ResultWorkflow;
