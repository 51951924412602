import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants/apiUrl.constant";

export const loginThunk = createAsyncThunk("login", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_URL.LOGIN}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const signupThunk = createAsyncThunk("signup", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_URL.SIGNUP}`,
      payload
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const forgotPasswordThunk = createAsyncThunk(
  "forgotPassword",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.FORGOT_PASSWORD}`,
        payload
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const newPasswordThunk = createAsyncThunk(
  "newPassword",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.NEW_PASSWORD}`,
        payload
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const changePasswordThunk = createAsyncThunk(
  "changePassword",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CHANGE_PASSWORD}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const currentUserDetailsThunk = createAsyncThunk(
  "currentUserDetails",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.CURRENT_USER_DETAILS}`
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Logout async thunk
export const logout = createAsyncThunk("logout", async () => {
  try {
    localStorage.removeItem("user");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
});

// User slice
const userSlice = createSlice({
  name: "user",
  initialState: {
    isLoading: false,
    data:
      localStorage?.getItem("user") !== "undefined"
        ? JSON.parse(localStorage?.getItem("user"))
        : null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(loginThunk.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(loginThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.data = action.payload.data;
      localStorage.setItem("user", JSON.stringify(action.payload.data));
    });
    builder.addCase(loginThunk.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.error = action.error.message;
    });
    builder.addCase(currentUserDetailsThunk.fulfilled, (state, action) => {
      // Reset user state on successful logout
      // state.data = null;
    });
    builder.addCase(logout.fulfilled, (state, action) => {
      // Reset user state on successful logout
      state.data = null;
    });
  },
});

export default userSlice.reducer;
