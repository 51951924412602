import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import "../../styles/auth.scss";
import LoginLogo from "../../assets/logo/PrismViewLogoWhite.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { forgotPasswordThunk } from "../../redux/slices/user.slice";
import { PAGE_URL } from "../../constants/pageUrl.constant";
import { setSnackbar } from "../../redux/slices/common.slice";
// import ChatBot from '../../assets/landing_page/Inteligenta-artificiala-aiminded.png';

const BootstrapInput = {
  "& label": {
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#273167 !important",
      borderWidth: "1px !important",
    },
  },
};
const ForgotPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: yup.object({
      email: yup
        .string()
        .trim()
        .email("Please enter a valid email address")
        .required("Email is required"),
    }),
    onSubmit: async (values) => {
      setisLoading(true);
      try {
        let response = await dispatch(
          forgotPasswordThunk({
            email: values.email,
          })
        );
        const { payload } = response;
        if (payload && payload?.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "4 digit verification code sent to email Successfully",
            })
          );
          navigate(PAGE_URL.NEW_PASSWORD, {
            state: { email: values.email },
          });
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
        console.error("Error during login:", error);
      }
      setisLoading(false);
    },
  });

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    if (savedEmail && savedPassword) {
      formik.setFieldValue("email", savedEmail);
      formik.setFieldValue("password", savedPassword);
    }
    // eslint-disable-next-line
  }, [formik.setFieldValue]);

  return (
    <Box className="login-wrapper p-5">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8} md={5} lg={4}>
          <Box className="text-center logo-wrap mb-5">
            <Link to="/">
              <img src={LoginLogo} alt="Logo" />
            </Link>
          </Box>
          <Paper component="form" elevation={1} className="p-4">
            <Typography variant="h3" className="mb-4 text-center">
              Forgot Password
            </Typography>

            <Stack spacing={3}>
              <FormControl variant="outlined" sx={BootstrapInput}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Email
                </InputLabel>
                <OutlinedInput
                  id="outlined-email"
                  type="text"
                  label="Email"
                  {...formik.getFieldProps("email")}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="form-error-message">
                    {formik.errors.email}
                  </div>
                )}
              </FormControl>
              <Button
                variant="contained"
                className="primary-button mt-4"
                disabled={isLoading}
                size="large"
                onClick={formik.handleSubmit}
              >
                {isLoading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Submit
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ForgotPassword;
