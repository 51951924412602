import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
  Stack,
  Typography,
  InputLabel,
  FormControl,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import "../../styles/auth.scss";
import LoginLogo from "../../assets/logo/PrismViewLogoWhite.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { newPasswordThunk } from "../../redux/slices/user.slice";
import { PAGE_URL } from "../../constants/pageUrl.constant";
import { setSnackbar } from "../../redux/slices/common.slice";
import { useSearchParams } from "react-router-dom";

const BootstrapInput = {
  "& label": {
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .Mui-focused": {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#273167 !important",
      borderWidth: "1px !important",
    },
  },
};
const NewPassword = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [rememberMe] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const formik = useFormik({
    initialValues: {
      OTP: "",
      password: "",
    },
    validationSchema: yup.object({
      OTP: yup
        .string()
        .required("Please specify verification code")
        .max(4, "Verification code Must be exactly 4 digits")
        .min(4, "Verification code Must be exactly 4 digits"),
      password: yup
        .string()
        .required("Please specify new password")
        .min(6, "The password should have at least 6 characters"),
    }),
    onSubmit: async (values) => {
      setisLoading(true);
      try {
        let response = await dispatch(
          newPasswordThunk({
            otp: values.OTP,
            password: values.password,
            ...(token ? { token } : { email: location.state.email }),
          })
        );
        const { payload } = response;
        if (payload && payload?.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message: "New Password Updated Successfully",
            })
          );
          navigate(PAGE_URL.LOGIN);
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
        console.error("Error during login:", error);
      }
      setisLoading(false);
    },
  });

  useEffect(() => {
    if (rememberMe) {
      localStorage.setItem("password", formik.values.password);
    } else {
      localStorage.removeItem("password");
    }
  }, [rememberMe, formik.values.password]);

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");

    if (savedEmail && savedPassword) {
      formik.setFieldValue("password", savedPassword);
    }
    // eslint-disable-next-line
  }, [formik.setFieldValue]);

  return (
    <Box className="login-wrapper p-5">
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={8} md={5} lg={4}>
          <Box className="text-center logo-wrap mb-5">
            <Link to="/">
              <img src={LoginLogo} alt="Logo" />
            </Link>
          </Box>
          <Paper component="form" elevation={1} className="p-4">
            <Typography variant="h3" className="mb-4 text-center">
              Set New Password
            </Typography>

            <Stack spacing={3}>
              <FormControl variant="outlined" sx={BootstrapInput}>
                <InputLabel htmlFor="outlined-adornment-password">
                  Verification code
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type="text"
                  {...formik.getFieldProps("OTP")}
                  label="Verification code"
                />
                {formik.touched.OTP && formik.errors.OTP && (
                  <div className="form-error-message">{formik.errors.OTP}</div>
                )}
              </FormControl>
              <FormControl variant="outlined" sx={BootstrapInput}>
                <InputLabel htmlFor="outlined-adornment-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={showPassword ? "text" : "password"}
                  {...formik.getFieldProps("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility sx={{ fill: "#273167" }} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="New Password"
                />
                {formik.touched.password && formik.errors.password && (
                  <div className="form-error-message">
                    {formik.errors.password}
                  </div>
                )}
              </FormControl>
              <Button
                variant="contained"
                className="primary-button mt-4"
                disabled={isLoading}
                size="large"
                onClick={formik.handleSubmit}
              >
                {isLoading && (
                  <CircularProgress
                    size="1rem"
                    sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                  />
                )}
                Submit
              </Button>
            </Stack>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewPassword;
