import React, { useState } from "react";
import {
  Box,
  Button,
  Paper,
  InputBase,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { updateThredThunk } from "../../../redux/slices/assistants.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import CustomizedDialog from "../Dialog";

const ScrollBars = {
  "&::-webkit-scrollbar": {
    width: " 4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cccccc",
  },
};

const EditChatName = ({
  isEdit,
  setIsEdit,
  threadDetails,
  setThreadDetails,
}) => {
  const [name, setName] = useState(threadDetails?.thread_name);
  const dispatch = useDispatch();

  const handleSave = async () => {
    let response = await dispatch(
      updateThredThunk({
        thread_id: threadDetails.thread_id,
        thread_name: name,
      })
    );
    const { payload } = response;
    if (payload && payload?.status) {
      setThreadDetails((pre) => ({
        ...pre,
        thread_name: name,
      }));
      setIsEdit(false);
      await dispatch(
        setSnackbar({
          open: true,
          severity: "success",
          message: "Name updated successfully",
        })
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  return (
    <CustomizedDialog
      className="modal-scroll"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={"Edit Chat Name"}
      open={isEdit}
      handleClose={() => {
        setIsEdit(false);
      }}
    >
      <DialogContent sx={ScrollBars}>
        <Box sx={{ mt: 2, mb: 2 }}>
          <Paper
            sx={{
              overflowX: "auto",
              whiteSpace: "pre-wrap",
              boxShadow: "none",
            }}
          >
            <InputBase
              className="edit-name-input"
              sx={{ flex: 1 }}
              inputProps={{ "aria-label": "search" }}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </Paper>
        </Box>
      </DialogContent>
      <DialogActions>
        <Box
          className="modal-footers end"
          sx={{
            flexDirection: "row",
            cursor: "pointer",
            mt: 2,
          }}
        >
          <Button
            sx={{ marginRight: "15px" }}
            variant="contained"
            className="primary-button"
            onClick={handleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            className="secondary-button"
            onClick={() => setIsEdit(false)}
          >
            Cancel
          </Button>
        </Box>
      </DialogActions>
    </CustomizedDialog>
  );
};

export default EditChatName;
