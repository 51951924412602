import { RichTreeView, TreeItem2, useTreeItem2Utils } from "@mui/x-tree-view";
import React, { useState, forwardRef, useEffect } from "react";

const EditActionKB = ({ configureAction, KbFiles, actions }) => {
  const [selectedKbFiles, setSelectedKbFile] = useState([]);

  const fetchAssistantDetails = async () => {
    const kbfile = configureAction?.filter(
      (ids, i) => ids?.id === actions.id
    )[0]?.payload?.data_source?.kb;

    let value = [];
    kbfile &&
      Object.keys(kbfile)?.length !== 0 &&
      Object.keys(kbfile)?.forEach((item) => {
        const findedKB = KbFiles?.find((data) => data?.id === Number(item));
        kbfile[item]?.length !== findedKB?.children?.length
          ? kbfile[item]?.forEach((ele) => {
              const fileData = findedKB?.children?.filter(
                (data) => data?.id === ele?.fileurl
              );
              if (value.find((valueData) => valueData?.id === Number(item))) {
                value = value.map((valueEle) => {
                  return {
                    ...valueEle,
                    children: [
                      ...valueEle?.children,
                      ...(Number(item) === valueEle?.id ? fileData : []),
                    ],
                  };
                });
              } else {
                value.push({
                  ...findedKB,
                  children: [...(fileData ?? [])],
                });
              }
            })
          : value.push(findedKB);
      });
    setSelectedKbFile(value);
  };

  useEffect(() => {
    fetchAssistantDetails();
    // eslint-disable-next-line
  }, [configureAction]);

  const SelectedCustomTreeItem = forwardRef(function MyTreeItem(props, ref) {
    const { interactions } = useTreeItem2Utils({
      itemId: props?.itemId,
      children: props?.children,
    });

    const handleContentClick = (event) => {
      event.defaultMuiPrevented = true;
      interactions.handleSelection(event);
    };

    const handleIconContainerClick = (event) => {
      event.stopPropagation();
      interactions.handleExpansion(event);
    };

    return (
      <TreeItem2
        {...props}
        ref={ref}
        slotProps={{
          content: { onClick: handleContentClick },
          iconContainer: { onClick: handleIconContainerClick },
        }}
      />
    );
  });

  return (
    <RichTreeView
      aria-label="icon expansion"
      slots={{ item: SelectedCustomTreeItem }}
      items={selectedKbFiles}
    />
  );
};

export default EditActionKB;
