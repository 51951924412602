import React from "react";

const EmailIcon = () => {
  return (
    <svg
      width="90"
      height="90"
      //   viewBox="0 0 38 38"
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      //   width="214.000000pt"
      //   height="148.000000pt"
      viewBox="0 0 214.000000 148.000000"
      //   preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,148.000000) scale(0.100000,-0.100000)"
        fill="#036185"
        stroke="none"
      >
        <path
          d="M322 1300 l-44 -20 139 -112 c76 -61 203 -166 283 -233 289 -241 335
-275 370 -275 38 0 44 4 256 180 83 69 168 139 190 155 35 28 133 108 179 147
11 9 53 44 93 77 l74 61 -44 20 c-41 19 -68 20 -748 20 -680 0 -707 -1 -748
-20z"
        />
        <path
          d="M228 1208 c-19 -34 -24 -858 -6 -913 7 -21 18 -40 24 -42 6 -2 103
72 216 164 113 93 246 201 297 242 50 41 91 78 91 81 0 4 -53 49 -117 101 -65
52 -129 103 -143 115 -92 79 -334 274 -341 274 -4 0 -14 -10 -21 -22z"
        />
        <path
          d="M1868 1217 c-14 -13 -413 -340 -515 -422 -35 -27 -63 -53 -63 -56 0
-3 28 -28 63 -55 34 -27 150 -121 257 -209 108 -88 216 -177 242 -197 l46 -38
16 40 c23 57 24 861 2 915 -16 37 -27 42 -48 22z"
        />
        <path
          d="M825 648 c-38 -32 -175 -145 -302 -250 -128 -105 -233 -194 -233
-198 0 -4 18 -15 40 -24 36 -14 120 -16 743 -16 677 0 704 1 745 20 l44 20
-74 61 c-40 33 -90 75 -112 92 -21 18 -44 37 -50 42 -6 6 -38 31 -71 57 -33
25 -116 93 -185 151 l-125 104 -52 -45 c-41 -34 -63 -46 -97 -49 -63 -7 -86 1
-148 50 l-53 44 -70 -59z"
        />
      </g>
    </svg>
  );
};

export default EmailIcon;
