import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { API_URL } from "../../constants/apiUrl.constant";

export const getAllKnowledgeBaseThunk = createAsyncThunk(
  "getAllKnowledgeBase",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_KNOWLEDGE}`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOneKnowledgeBaseThunk = createAsyncThunk(
  "getOneKnowledgeBase",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ONE_KNOWLEDGE}?id=${id.kbId}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addKnowledgeBaseThunk = createAsyncThunk(
  "addAllKnowledgeBase",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_KNOWLEDGE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const uploadKnowledgeBaseFileThunk = createAsyncThunk(
  "uploadKnowledgeBaseFile",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPLOAD_KNOWLEDGE_FILE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getSuggetionsThunk = createAsyncThunk(
  "getSuggetions",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_SUGGETIONS}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createKnowledgeBaseWithFileThunk = createAsyncThunk(
  "createKnowledgeBaseWithFile",
  async (data) => {
    const payload = new FormData();
    payload.append("name", data.name);
    payload.append("description", data.description);
    payload.append("flag", data.flag);
    payload.append("configuration_id", data.configuration_id);
    payload.append("file_ids", JSON.stringify(data.file_ids));
    if (data.file.length > 0)
      data.file.forEach((element) => {
        payload.append("file", element);
      });
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_KNOWLEDGE_WITH_FILE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateKnowledgeBaseWithFileThunk = createAsyncThunk(
  "updateKnowledgeBaseWithFile",
  async (data) => {
    const payload = new FormData();
    payload.append("name", data.values.name);
    payload.append("description", data.values.description);
    payload.append("flag", data.values.flag);
    payload.append("configuration_id", data.values.configuration_id);
    payload.append("file_ids", JSON.stringify(data.values.file_ids));
    if (data.values.file.length > 0)
      data.values.file.forEach((element) => {
        payload.append("file", element);
      });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_KNOWLEDGE_WITH_FILE}/${data.id}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
            "Content-Type": "multipart/form-data", // Set content type for FormData
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const searchKnowledgeBaseDocThunk = createAsyncThunk(
  "searchKnowledgeBaseDoc",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SEARCH_KNOWLEDGE_DOC}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);
export const fetchAllPublicKBThunk = createAsyncThunk(
  "fetchAllPublicKB",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.ALL_PUBLIC_KB}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeKbFileThunk = createAsyncThunk(
  "removeKbFile",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_KB_FILE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removekbThunk = createAsyncThunk(
  "removekb",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_KNOWLEDGE_WITH_FILE}/${payload.kbId}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeProjecThunk = createAsyncThunk(
  "removeProject",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_PROJECT}/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
)

export const removeReportThunk = createAsyncThunk(
  "removeReport",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_REPORT}/${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
)

export const fetchAllConfigurationThunk = createAsyncThunk(
  "fetchAllConfiguration",
  async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.ALL_CONFIGURATION}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const addIntegrationThunk = createAsyncThunk(
  "addIntegration",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_INTEGRATION}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeIntegrationThunk = createAsyncThunk(
  "removeIntegration",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_INTEGRATION}?id=${payload.id}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateIntegrationThunk = createAsyncThunk(
  "updateIntegration",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_INTEGRATION}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const authURLThunk = createAsyncThunk("authURL", async (payload) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}${API_URL.AUTH_URL}`,
      payload,
      {
        headers: {
          Authorization: `Bearer ${
            JSON.parse(localStorage?.getItem("user")).access_token
          }`,
        },
      }
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
});

export const googleFolderListThunk = createAsyncThunk(
  "googleFolderList",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.GOOGLE_FOLDER_LIST}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const oneDriveFolderListThunk = createAsyncThunk(
  "oneDriveFolderList",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.ONE_DRIVE_FOLDER_LIST}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const notionFolderListThunk = createAsyncThunk(
  "notionFolderList",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.NOTION_FOLDER_LIST}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const googleFolderDetailListThunk = createAsyncThunk(
  "googleFolderDetailList",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.GOOGLE_FOLDER_DETAIL_LIST}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const oneDriveFolderDetailListThunk = createAsyncThunk(
  "oneDriveFolderDetailList",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.ONE_DRIVE_FOLDER_DETAIL_LIST}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Knowledge slice
const knowledgeSlice = createSlice({
  name: "knowledge",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default knowledgeSlice.reducer;
