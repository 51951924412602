import { Box, CircularProgress, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import Verification from "../../../utils/icon/Verification";
import { useDispatch } from "react-redux";
import { getWorkflowRunHistoryThunk } from "../../../redux/slices/workflow.slice";
import { setSnackbar } from "../../../redux/slices/common.slice";
import moment from "moment";
import { IoIosArrowBack } from "react-icons/io";
import ResultWorkflow from "./ResultWorkflow";
import "../../../styles/editWorkflow.scss";

const RunWorkflowHistory = ({
  workflow_id,
  setIsExpanded,
  setHistoryDrawer,
  workflow,
}) => {
  const dispatch = useDispatch();
  const [workflowHistoryList, setWorkflowHistoryList] = useState([]);
  const [selectedHistory, setSelectedHistory] = useState({});
  const [isLoading, setisLoading] = useState(true);
  const handleCloseDrawer = () => {
    if (workflow) {
      setHistoryDrawer(false);
    } else {
      setIsExpanded(false);
    }
  };

  const getWorkflowHistory = async () => {
    setisLoading(true);
    let response = await dispatch(getWorkflowRunHistoryThunk(workflow_id));
    const { payload } = response;
    if (payload && payload?.status) {
      setWorkflowHistoryList(payload?.data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
    setisLoading(false);
  };

  useEffect(() => {
    getWorkflowHistory();
    // eslint-disable-next-line
  }, []);

  return (
    <Paper
      elevation={0}
      className={`${workflow ? "" : "workflow-history-paper"}`}
      sx={{
        borderRadius: "12px",
        padding: "20px",
        height: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box display="flex" alignItems="center" gap={1}>
          {Object.keys(selectedHistory).length > 0 && (
            <IoIosArrowBack
              cursor="pointer"
              onClick={() => setSelectedHistory({})}
            />
          )}
          <Typography variant="h6" fontWeight="600" color="#036185">
            Workflow Run History
          </Typography>
        </Box>

        <Box
          className="edit-wf-close-btn"
          onClick={handleCloseDrawer}
          sx={{ cursor: "pointer" }}
        >
          <RiCloseLine fill="#000" />
        </Box>
      </Stack>
      <hr></hr>

      {Object.keys(selectedHistory).length ? (
        <ResultWorkflow selectedHistory={selectedHistory} />
      ) : isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : workflowHistoryList.length ? (
        workflowHistoryList?.map((workflowHistory, i) => (
          <Box
            key={i}
            className="run-history"
            id={workflowHistory.id}
            onClick={() => setSelectedHistory(workflowHistory)}
          >
            <Typography variant="subtitle">
              {moment(workflowHistory?.created_at).format("MMMM DD YYYY")} |{" "}
              {moment(workflowHistory?.created_at).format("HH:mm a")}
            </Typography>
            <Verification />
          </Box>
        ))
      ) : (
        <>
          There are no previous runs for this workflowThere are no previous runs
          for this workflow
        </>
      )}
    </Paper>
  );
};

export default RunWorkflowHistory;
