import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";

const TableStyle = {
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-columnHeadersInner": {
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: "#64bcd2",
        color: "#FFFFFF",
        fontSize: "16px",
        "& .MuiDataGrid-columnHeaderTitleContainer": {
          "& .MuiDataGrid-iconButtonContainer": {
            "& svg": {
              fill: "#FFFFFF",
            },
          },
        },
        "& .MuiDataGrid-columnSeparator": {
          display: "none",
        },
      },
    },
  },
  "& .MuiDataGrid-cell": {
    py: 2,
    "& .MuiDataGrid-cellContent": {
      // textOverflow: 'unset',
      // whiteSpace: 'pre-wrap',
    },
    "& .public-status": {
      backgroundColor: "#6EC531",
    },
    "& .private-status": {
      backgroundColor: "#273167",
    },
    "&:focus": {
      outline: "none !important",
    },
  },
  "& .MuiDataGrid-virtualScroller": {
    "&::-webkit-scrollbar": {
      width: " 4px",
      height: "3px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
      // height: "3px",
    },
    "& .MuiDataGrid-row": {
      "&.Mui-hovered": {
        backgroundColor: "rgb(255 115 100 / 5%)",
      },
    },
  },
};

const DataGridTable = ({
  data,
  columns,
  totalRow,
  paginationModel,
  setPaginationModel,
  isLoading,
}) => {
  return (
    <div style={{ width: "100%", backgroundColor: "#FFFFFF" }}>
      <DataGrid
        autoHeight
        sx={TableStyle}
        rows={data}
        rowCount={totalRow}
        getRowId={(row) => row.kbId || row.id}
        columns={columns}
        hideFooter={paginationModel === undefined ? true : false}
        pageSizeOptions={[5, 10, 20, 50]}
        loading={isLoading}
        paginationModel={paginationModel}
        paginationMode="server"
        onPaginationModelChange={setPaginationModel}
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        getRowHeight={() => "auto"}
      />
    </div>
  );
};

export default DataGridTable;
