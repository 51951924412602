import { Box, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { RiCloseLine } from "react-icons/ri";
import Select from "react-select";
import { setSnackbar } from "../../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import SelecteKb from "../Assistants/SelectKb";
import "../../../styles/editWorkflow.scss";
import { getActionSelectionThunk } from "../../../redux/slices/workflow.slice";
import {
  getAllAssistantsThunk,
  getAllKBFilesThunk,
} from "../../../redux/slices/assistants.slice";

const customStyles = {
  control: (base, { isDisabled }) => {
    return {
      ...base,
      background: "#FFFFFF",
      color: "#000",
      borderRadius: "8px",
      boxShadow: "none !important",
      minHeight: "44px",
      border: "1px solid #0D333F54",
      cursor: isDisabled ? "not-allowed" : "default",
    };
  },
  menu: (base) => ({
    ...base,
    background: "#FFFFFF",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    marginTop: 0,
    overflow: "auto",
    maxHeight: "280px",
  }),
  menuList: (base) => ({
    ...base,
    padding: "0",
    color: "#030F1C",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    overflow: "auto",
    maxHeight: "280px",
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb ": {
      backgroundColor: " #cccccc",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#000",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#273167",
    "&:hover": {
      color: "#273167",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "12px 16px",
    backgroundColor: state.isSelected ? "#F2F4F7" : "#FEFEFF",
    color: state.isSelected ? "#0075FF" : "#000",
    borderBottom: "1px solid #EAECF0",
    borderRadius: "8px 8px 0px 0px",
    "&:hover": {
      backgroundColor: state.isSelected ? "#F2F4F7" : "#F2F4F7",
      color: state.isSelected ? "#0075FF" : "#005ECC",
    },
  }),
  placeholder: (base) => ({
    ...base,
    fontSize: "1em",
    color: "#000",
    fontWeight: 400,
  }),
};

const ExtractWorkflow = ({
  setIsExpanded,
  actions,
  globalFormik,
  kbFormik,
  setPublicTemplateList,
  publicTemplateList,
  setActionSelectionList,
  actionSelectionList,
}) => {
  const [selectedActionData, setSelectedActionData] = useState({});
  const [CSVKbFiles, setCSVKbFile] = useState([]);
  const dispatch = useDispatch();

  const handleCloseDrawer = () => {
    setIsExpanded(false);
  };

  useEffect(() => {
    const data = globalFormik.values.configureActions.find(
      (item) => item.id === actions.id
    );
    setSelectedActionData(data);
    // eslint-disable-next-line
  }, [globalFormik.values.configureActions, actions.id]);

  const fetchAssistantDetails = async () => {
    let kbFilesresponse = await dispatch(getAllKBFilesThunk("isCsv"));
    if (kbFilesresponse.payload) {
      const kbs = (kbFilesresponse?.payload?.data || [])
        .map((item) =>
          item?.kbfiles?.files.length
            ? item?.kbfiles?.files?.map((ele) => ({
                value: ele.fileurl,
                label: ele.filename,
                kbId: item.kbId,
              }))
            : []
        )
        .flat();

      setCSVKbFile(kbs);
    }
  };

  const fetchActionSelectionList = async () => {
    let response = await dispatch(
      getActionSelectionThunk(
        actions.type === "Extract"
          ? 1
          : actions.type === "Criteria Check"
          ? 2
          : 3
      )
    );
    const { payload } = response;
    if (payload && payload?.status) {
      setActionSelectionList(
        payload?.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
      const data = globalFormik.values.configureActions.find(
        (item) => item.id === actions.id
      );
      setSelectedActionData(data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  const fetchActionList = async () => {
    let response = await dispatch(getAllAssistantsThunk({ search: "" }));
    const { payload } = response;
    if (payload && payload?.status) {
      setPublicTemplateList(
        payload?.data.map((item) => ({
          label: item.name,
          value: item.id,
        }))
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    fetchActionList();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    actions.type === "Extraction from CSV file" && fetchAssistantDetails();
    fetchActionSelectionList();
    // eslint-disable-next-line
  }, [actions.id]);

  useEffect(() => {
    const newData = globalFormik.values.configureActions.map((item) => {
      if (item.id === actions.id) {
        return {
          ...item,
          payload: {
            ...item.payload,
            data_source: { is_kb: true, kb: kbFormik.values.kb, url: "" },
          },
        };
      } else {
        return { ...item };
      }
    });
    globalFormik.setFieldValue("configureActions", newData);
    // eslint-disable-next-line
  }, [kbFormik.values.kb]);

  return (
    <Paper
      elevation={0}
      sx={{
        border: "1px solid #D8D7D7",
        borderRadius: "12px",
        padding: "20px",
        height: "100%",
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Typography variant="h6" fontWeight="600" color="#036185">
            {actions.type}
          </Typography>
          <Typography variant="subtitle" color="#777777">
            Learn how to use: Run the workflow on a scheduled
          </Typography>
        </Box>

        <Box
          className="edit-wf-close-btn"
          onClick={handleCloseDrawer}
          sx={{ cursor: "pointer" }}
        >
          <RiCloseLine fill="#000" />
        </Box>
      </Stack>
      <hr></hr>
      <Typography variant="h6" className="Workflow-Template-text mt-4">
        Workflow Template
      </Typography>
      <Typography
        variant="subtitle"
        className="Workflow-Template-subtitle-text"
        color="#777777"
      >
        Limit the parts of the knowledge base that will be searched. If nothing
        is selected, everything will be accessible.
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <Select
            value={
              actionSelectionList.find(
                (item) => item.value === selectedActionData?.payload?.template
              ) ?? null
            }
            onChange={(e) => {
              const newData = globalFormik.values.configureActions.map(
                (item) => {
                  if (item.id === actions.id) {
                    return {
                      ...item,
                      payload: { ...item.payload, template: e.value },
                    };
                  } else {
                    return item;
                  }
                }
              );
              globalFormik.setFieldValue("configureActions", newData);
            }}
            styles={customStyles}
            name="template"
            options={actionSelectionList}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder={"Select Workflow Template"}
            components={{
              IndicatorSeparator: () => null,
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="h6" className="Workflow-Template-text mt-2">
        Knowledge Base
      </Typography>
      <Typography
        variant="subtitle"
        className="Workflow-Template-subtitle-text mb-2"
      >
        Create a reusable workflow with fine-grained control over the actions
        that are executed.
      </Typography>
      <Box className="mt-2">
        {actions.type !== "Extraction from CSV file" ? (
          <SelecteKb id={actions.id} formik={kbFormik} />
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Select
                value={
                  CSVKbFiles?.find((item) =>
                    selectedActionData?.payload?.data_source.kb
                      ? item.value ===
                        Object.values(
                          selectedActionData?.payload?.data_source.kb
                        )?.[0]?.fileurl
                      : false
                  ) ?? null
                }
                onChange={(e) => {
                  const newData = globalFormik.values.configureActions.map(
                    (item) => {
                      if (item.id === actions.id) {
                        return {
                          ...item,
                          payload: {
                            ...item.payload,
                            data_source: {
                              ...item.payload.data_source,
                              kb: {
                                [e.kbId]: {
                                  filename: e.label,
                                  fileurl: e.value,
                                },
                              },
                            },
                          },
                        };
                      } else {
                        return item;
                      }
                    }
                  );
                  globalFormik.setFieldValue("configureActions", newData);
                }}
                styles={customStyles}
                name="kb"
                options={CSVKbFiles}
                className="basic-multi-select"
                classNamePrefix="select"
                placeholder={"Select Workflow Template"}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
      {actions.type !== "Extraction from CSV file" && (
        <>
          <Typography variant="h6" className="Workflow-Template-text mt-4">
            Assistant
          </Typography>
          <Typography
            variant="subtitle"
            className="Workflow-Template-subtitle-text"
          >
            You are marketing assistant and need to provide user marketing
            related question.
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Select
                styles={customStyles}
                name="assistnat_id"
                options={publicTemplateList}
                value={
                  publicTemplateList.find(
                    (item) =>
                      item.value ===
                      selectedActionData?.payload?.prompt?.assistnat_id
                  ) ?? null
                }
                className="basic-multi-select"
                onChange={(e) => {
                  const newData = globalFormik.values.configureActions.map(
                    (item) => {
                      if (item.id === actions.id) {
                        return {
                          ...item,
                          payload: {
                            ...item.payload,
                            prompt: {
                              ...item.payload.prompt,
                              is_assistant: true,
                              user_prompt: "",
                              assistnat_id: e.value,
                            },
                          },
                        };
                      } else {
                        return item;
                      }
                    }
                  );
                  globalFormik.setFieldValue("configureActions", newData);
                }}
                classNamePrefix="select"
                placeholder={"Select Marketing Assitant"}
                components={{
                  IndicatorSeparator: () => null,
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default ExtractWorkflow;
