import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  IconButton,
  InputBase,
  Paper,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CircularProgress,
  Pagination,
  Drawer,
  Stack,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import CancelIcon from "@mui/icons-material/Cancel";
import Select from "react-select";
import { useDispatch } from "react-redux";
import {
  fetchAllPublicKBThunk,
  searchKnowledgeBaseDocThunk,
  // getSuggetionsThunk,
} from "../../redux/slices/knowledgeBase.slice";
import { setSnackbar } from "../../redux/slices/common.slice";
import { MdRemoveRedEye } from "react-icons/md";
import debounce from "lodash.debounce";
import Chat from "./chat";
import { DiDatabase } from "react-icons/di";
import { CONFIG_IMG } from "../../constants/common.constant";
import { HiMiniChatBubbleLeftRight } from "react-icons/hi2";

const ITEMS_PER_PAGE = 5;

const customStyles = {
  control: (base) => ({
    ...base,
    background: "#FFFFFF",
    borderRadius: "8px",
    boxShadow: "none !important",
    minHeight: "44px",
    border: "1px solid #0D333F54",
    cursor: "pointer",
  }),
  menu: (base) => ({
    ...base,
    background: "#FFFFFF",
    boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.08)",
    borderRadius: "8px",
    marginTop: 0,
    overflow: "auto",
    maxHeight: "280px",
  }),
  menuList: (base) => ({
    ...base,
    padding: "0",
    color: "#030F1C",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "22px",
    whiteSpace: "nowrap",
    overflow: "auto",
    maxHeight: "280px",
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb ": {
      backgroundColor: " #cccccc",
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    color: "#FFFFFF",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "22px",
  }),
  dropdownIndicator: (base) => ({
    ...base,
    color: "#273167",
    "&:hover": {
      color: "#273167",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: "12px 16px",
    backgroundColor: state.isSelected ? "#0075FF" : "#FEFEFF",
    borderBottom: "1px solid #EAECF0",
    borderRadius: "8px 8px 0px 0px",
    "&:hover": {
      backgroundColor: state.isSelected ? "#0075FF" : "#F2F4F7",
      color: state.isSelected ? "#FFFFFF" : "#005ECC",
    },
  }),
};

const Search = () => {
  const [isLoading, setisLoading] = useState(false);
  const [publicKb, setPublicKb] = useState([]);
  const [token, setToken] = useState([]);
  const [docs, setDocs] = useState([]);
  const [selectedKb, setSelectedKb] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedDoc, setSelectedDoc] = useState([]);
  const [selectedPreview, setSelectedPreview] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedDocPage] = useState(1);

  const dispatch = useDispatch();

  const searchDocument = async () => {
    if (selectedKb.length <= 0 || searchTerm.length <= 0) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Need a Kb and a search term",
        })
      );
    } else {
      setSelectedDoc([]);
      setDocs([]);
      setisLoading(true);
      let payload = {
        search: searchTerm,
        knowledgebase_ids: selectedKb.map((kb) => kb.value),
        // "page_number": page,
        // "page_size": 5
      };
      let response = await dispatch(searchKnowledgeBaseDocThunk(payload));
      if (response.payload && response.payload.status) {
        setDocs(response.payload.data);
        setToken(response.payload.token);
      } else {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: response.payload.message || "Internal server error",
          })
        );
      }
      setisLoading(false);
    }
  };

  const debouncedSearch = debounce(searchDocument, 1000);

  const handleInputChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  useEffect(() => {
    fetchAllKB();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedKb.length > 0 && searchTerm.length > 0) {
      debouncedSearch();
      return () => {
        debouncedSearch.cancel();
      };
    }
    // eslint-disable-next-line
  }, [selectedKb, searchTerm]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchDocument();
      e.preventDefault();
    }
  };

  const fetchAllKB = async () => {
    let response = await dispatch(fetchAllPublicKBThunk());
    const { payload } = response;
    if (payload && payload?.status) {
      setPublicKb(
        payload?.data.map((item) => ({
          label: item.kbName,
          value: item.kbId,
        }))
      );
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  // const fetchSuggetions = async () => {
  //   let response = await dispatch(getSuggetionsThunk());
  //   if (response.payload && response.payload.status) {
  //     // setPublicKb(
  //     //   response.payload.data.map((item) => ({
  //     //     label: item.kbName,
  //     //     value: item.kbId,
  //     //   }))
  //     // );
  //   } else {
  //     await dispatch(
  //       setSnackbar({
  //         open: true,
  //         severity: "error",
  //         message: response.payload.message || "Internal server error",
  //       })
  //     );
  //   }
  // };

  const resetSearch = () => {
    setSearchTerm("");
    setSelectedDoc([]);
    setDocs([]);
  };
  const toggleDoc = (value) => {
    // Check if the value is already in the array
    const index = selectedDoc.indexOf(value);

    if (index !== -1) {
      // If value exists, remove it
      const newArray = [...selectedDoc];
      newArray.splice(index, 1);
      setSelectedDoc(newArray);
    } else {
      // If value doesn't exist, add it
      setSelectedDoc([...selectedDoc, value]);
    }
  };

  return (
    <>
      <Box className="content-header mb-4 search-page">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Select
              styles={customStyles}
              isMulti
              name="kbs"
              options={publicKb}
              className="basic-multi-select"
              onChange={(e) => {
                setSelectedKb(e);
              }}
              classNamePrefix="select"
              placeholder={"Select Knowledge Base(s)..."}
              components={{
                IndicatorSeparator: () => null,
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Paper
              elevation={0}
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                maxHeight: "44px",
                alignItems: "center",
                width: "auto",
                borderRadius: "8px",
                border: "1px solid #0D333F54",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1 }}
                placeholder="Search Here..."
                inputProps={{ "aria-label": "search" }}
                value={searchTerm}
                disabled={selectedKb.length < 1}
                onChange={handleInputChange}
                onKeyDown={handleKeyPress}
              />
              {searchTerm.length > 0 && (
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={() => resetSearch()}
                >
                  <CancelIcon />
                </IconButton>
              )}
              <IconButton
                disabled={selectedKb.length < 1}
                type="button"
                sx={{ p: "10px" }}
                aria-label="search"
                onClick={() => searchDocument()}
              >
                <SearchIcon />
              </IconButton>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <hr />
      {isLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid
            item
            xs={selectedDoc.length > 0 ? 12 : 12}
            sm={selectedDoc.length > 0 ? 12 : 12}
            md={selectedDoc.length > 0 ? 6 : 12}
            lg={selectedDoc.length > 0 ? 6 : 12}
            sx={{ mt: 1 }}
          >
            <Box className="document-listing-wrapper">
              <Box sx={{ flexGrow: 1 }}>
                {docs.length > 0 ? (
                  docs
                    .slice((page - 1) * ITEMS_PER_PAGE, page * ITEMS_PER_PAGE)
                    .map((doc, index) => (
                      <Grid container spacing={2} key={index}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                          <Card
                            sx={{
                              my: 1,
                              cursor: "pointer",
                              // border: selectedDoc.some(obj => obj.id === doc?.id) ? `#273168 solid 4px` : 'inherit',
                              backgroundColor: selectedDoc.some(
                                (obj) => obj.id === doc?.id
                              )
                                ? "#273167"
                                : "#ffffff",
                              color: selectedDoc.some(
                                (obj) => obj.id === doc?.id
                              )
                                ? "white"
                                : "inherit",
                              boxShadow: "none",
                            }}
                            onClick={() => toggleDoc(doc)}
                          >
                            <CardMedia
                              component="iframe"
                              src={`${doc.location}?${
                                token[doc?.kb_id || doc?.kb]
                              }`}
                              // src="https://icseindia.org/document/sample.pdf"
                              title={doc.filename}
                              width="100%"
                              height="200px"
                              className="search-scroll"
                              sx={{ cursor: "pointer" }}
                              onClick={() => toggleDoc(doc)}
                            />
                            <CardContent
                              className="d-flex justify-content-between align-items-center"
                              sx={{ p: "10px !important" }}
                            >
                              <Typography variant="h6" className="mb-0">
                                {(doc.filename.split("/")[0] ===
                                  "Google drive" ||
                                  doc.filename.split("/")[0] === "One drive" ||
                                  doc.filename.split("/")[0] === "Notion") && (
                                  <img
                                    src={
                                      CONFIG_IMG[doc?.filename?.split("/")[0]]
                                    }
                                    alt={doc.filename.split("/")[0]}
                                    style={{
                                      width: "15px",
                                      marginRight: "5px",
                                    }}
                                  />
                                )}

                                {doc?.filename.split("/").length === 1
                                  ? doc?.filename
                                  : doc?.filename.split("/")[1]}
                              </Typography>
                              <Stack sx={{ flexDirection: "row" }}>
                                <Box sx={{ px: 2 }}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    title="Document Chat"
                                  >
                                    <HiMiniChatBubbleLeftRight
                                      size={24}
                                      color="#273167"
                                    />
                                    <Typography variant="span" className="mb-0">
                                      Document Chat
                                    </Typography>
                                  </Stack>
                                </Box>
                                <Box sx={{ px: 2 }}>
                                  <Stack
                                    direction="row"
                                    alignItems="center"
                                    spacing={1}
                                    title="Document Preview"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      setSelectedPreview(doc);
                                    }}
                                  >
                                    <MdRemoveRedEye size={24} color="#273167" />
                                    <Typography variant="span" className="mb-0">
                                      View
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Stack>
                            </CardContent>
                            {/* <Box sx={{ px: 2, mb: 2, maxHeight: '120px', overflow: 'auto' }}>
                                    {doc.keyPhrases.map(key => <Chip label={key} sx={{ mt: 1, mr: 1 }} />)}
                                </Box> */}
                          </Card>
                        </Grid>
                      </Grid>
                    ))
                ) : selectedKb.length > 0 && docs.length === 0 ? (
                  <Box
                    className="no-data-found"
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                      mt: 1,
                    }}
                  >
                    <Stack
                      spacing={0}
                      justifyContent="center"
                      alignItems="center"
                    >
                      <DiDatabase size={50} />
                      <span>No data found</span>
                    </Stack>
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              {docs.length > 0 && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Pagination
                    count={Math.ceil(docs.length / ITEMS_PER_PAGE)}
                    variant="outlined"
                    shape="rounded"
                    page={page}
                    onChange={handlePageChange}
                  />
                </Box>
              )}
            </Box>
          </Grid>
          {selectedDoc.length > 0 && (
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <Box className="document-chat-wrapper">
                <Chat
                  searchModule={true}
                  selectedDocument={selectedDoc}
                  selectedKnowledge={selectedKb}
                />
              </Box>
            </Grid>
          )}
          <Drawer
            anchor={"right"}
            className="side-drawer"
            open={selectedPreview}
            onClose={() => {
              setSelectedPreview();
            }}
          >
            <iframe
              src={`${selectedPreview?.location}?${
                token[selectedPreview?.kb_id || selectedPreview?.kb]
              }#page=${selectedDocPage}`}
              title={selectedPreview?.filename}
              height="100%"
              width="100%"
            />
          </Drawer>
        </Grid>
      )}
    </>
  );
};

export default Search;
