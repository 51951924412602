import React, { useState, useEffect } from "react";
import {
  Select,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
  MenuItem,
  DialogActions,
  DialogContent,
  Box,
} from "@mui/material";
import "../../../../styles/dashboard.scss";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import {
  createConfigureActionThunk,
  getOneConfigureActionThunk,
  updateConfigureActionThunk,
} from "../../../../redux/slices/tenant.slice";
import CustomizedDialog from "../../Dialog";
import Delete from "../../../../assets/icons/delete.svg";
import Add from "../../../../assets/icons/addRed.svg";

const BootstrapInput = {
  "& label": {
    width: "100%",
    "&.Mui-focused": {
      color: "#273167",
    },
  },
  "& .MuiOutlinedInput-root": {
    "& .Mui-disabled": {
      backgroundColor: "rgba(39, 49, 103, 0.1)",
    },
    "&.Mui-focused": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
    "&:hover": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#273167 !important",
        borderWidth: "1px !important",
      },
    },
  },
};

const btnStyle = {
  padding: "0.6rem",
  cursor: "pointer",
  backgroundColor: "none !important",
  boxShadow: "none  !important",
};

const inputStyles = {
  marginTop: "1.6rem",
  display: "flex",
};

const ScrollBars = {
  "&::-webkit-scrollbar": {
    width: " 4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cccccc",
  },
};

const CreateConfigureAction = ({
  fetchAllConfigureAction,
  setIsOpenDrawer,
  setDrawerState,
  drawerState,
  isOpenDrawer,
  selectedRowData,
  setSelectedRowData,
  ...props
}) => {
  const [initialValues, setInitialValues] = useState({
    name: "",
    action: "1",
    description: "",
    additional_instruction: "",
    terms: [
      {
        id: 1,
        term: "",
      },
    ],
  });
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const handleCloseDrawer = () => {
    formik.resetForm();
    setIsOpenDrawer(false);
    setDrawerState("create");
    setSelectedRowData(null);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: yup.object({
      name: yup.string().trim().required("Name is required"),
      description: yup.string().optional(),
      additional_instruction: yup.string().optional(),
      action: yup.string().required("action is required"),
    }),
    onSubmit: async (values) => {
      setIsLoading(true);
      try {
        let response = await dispatch(
          drawerState === "edit"
            ? updateConfigureActionThunk(values)
            : createConfigureActionThunk(values)
        );
        const { payload } = response;
        if (payload && payload?.status) {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "success",
              message:
                drawerState === "edit"
                  ? "Configure Action Successfully Updated"
                  : "Configure Action Successfully Added",
            })
          );
          handleCloseDrawer();
          fetchAllConfigureAction();
        } else {
          await dispatch(
            setSnackbar({
              open: true,
              severity: "error",
              message: payload?.message || "Internal server error",
            })
          );
        }
      } catch (error) {
        await dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: error || "Internal server error",
          })
        );
      }
      setIsLoading(false);
    },
  });

  const handleListAdd = () => {
    const newValue = [
      ...formik.values.terms,
      { id: formik.values.terms.length + 1, term: "" },
    ];
    formik.setFieldValue("terms", newValue);
  };

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    const newInputList = [...formik.values.terms];
    newInputList[index].term = value;
    formik.setFieldValue("terms", newInputList);
  };

  const handleRemoveItem = (id) => {
    const newList = [...formik.values.terms].filter((item) => item.id !== id);
    formik.setFieldValue(
      "terms",
      newList.map((item, i) => ({ ...item, id: i + 1 }))
    );
  };

  const getConfigureActionValues = async () => {
    let response = await dispatch(
      getOneConfigureActionThunk(selectedRowData.id)
    );
    const { payload } = response;
    if (payload) {
      const data = {
        id: payload?.data?.id,
        name: payload?.data?.name,
        action: payload?.data?.action,
        description: payload?.data?.description,
        additional_instruction: payload?.data?.additional_instruction,
        terms: payload?.data?.terms,
      };
      setInitialValues(data);
      formik.setValues(data);
    } else {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    if (drawerState !== "create") {
      getConfigureActionValues();
    } // eslint-disable-next-line
  }, [isOpenDrawer, drawerState]);

  return (
    <>
      <CustomizedDialog
        className="modal-scroll"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        // slots={{ backdrop: Backdrop }}
        title={
          drawerState === "view"
            ? "Configure Action Detail"
            : drawerState === "create"
            ? "Create Configure Action"
            : "Edit Configure Action"
        }
        open={isOpenDrawer}
        handleClose={() => {
          handleCloseDrawer();
        }}
      >
        {" "}
        <DialogContent sx={ScrollBars}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Name
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    label=""
                    variant="outlined"
                    placeholder="Enter Name"
                    size="small"
                    name="name"
                    disabled={drawerState === "view"}
                    {...formik.getFieldProps("name")}
                  />
                  {formik.touched.name && formik.errors.name && (
                    <div className="form-error-message">
                      {formik.errors.name}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Description
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Description"
                    size="small"
                    name="description"
                    disabled={drawerState === "view"}
                    {...formik.getFieldProps("description")}
                    rows={2}
                    multiline
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className="form-error-message">
                      {formik.errors.description}
                    </div>
                  )}
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Action
                  </Typography>
                  <Select
                    id="type"
                    labelId="demo-simple-select-label"
                    value={formik.values.action}
                    label=""
                    size="small"
                    disabled={drawerState === "view"}
                    placeholder={"action"}
                    onChange={(event) =>
                      formik.setFieldValue("action", event.target.value)
                    }
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="1" key="2">
                      Extract
                    </MenuItem>
                    <MenuItem value="2" key="1">
                      Criteria Check
                    </MenuItem>
                    <MenuItem value="3" key="1">
                      Extraction from CSV file
                    </MenuItem>
                  </Select>

                  {formik.touched.action && formik.errors.action && (
                    <div className="form-error-message">
                      {formik.errors.action}
                    </div>
                  )}

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    {formik?.values?.terms?.length > 0
                      ? formik.values.terms.map((input, index) => (
                          <div key={index} style={inputStyles}>
                            <TextField
                              fullWidth
                              id="outlined-basic"
                              label={`Question ${index + 1}`}
                              variant="outlined"
                              value={input.term}
                              placeholder=""
                              size="small"
                              name="terms"
                              disabled={drawerState === "view"}
                              onChange={(event) =>
                                handleInputChange(event, index)
                              }
                            />
                            {drawerState !== "view" && (
                              <Button
                                sx={{ color: "#273167" }}
                                disabled={drawerState === "view"}
                                onClick={() => handleRemoveItem(input.id)}
                              >
                                <img
                                  alt="Delete Details"
                                  src={Delete}
                                  width={24}
                                  height={24}
                                  className="actionIcon"
                                  cursor={"pointer"}
                                />
                              </Button>
                            )}
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                                marginRight: "20px",
                              }}
                            >
                              {drawerState !== "view" &&
                                formik?.values?.terms?.length === index + 1 && (
                                  <Button
                                    style={btnStyle}
                                    onClick={handleListAdd}
                                    disabled={drawerState === "view"}
                                  >
                                    <img
                                      alt="Add"
                                      src={Add}
                                      width={24}
                                      height={24}
                                      cursor={"pointer"}
                                    />
                                  </Button>
                                )}
                            </div>
                          </div>
                        ))
                      : "No item in the list "}
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Box variant="div" component="div" sx={BootstrapInput}>
                  <Typography variant="body1" component="label">
                    Additional instructions
                  </Typography>
                  <TextField
                    fullWidth
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Enter Additional instructions"
                    size="small"
                    name="additional_instruction"
                    disabled={drawerState === "view"}
                    {...formik.getFieldProps("additional_instruction")}
                    rows={2}
                    multiline
                  />
                  {formik.touched.additional_instruction &&
                    formik.errors.additional_instruction && (
                      <div className="form-error-message">
                        {formik.errors.additional_instruction}
                      </div>
                    )}
                </Box>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          {drawerState !== "view" && (
            <Box className="modal-footers">
              <Stack spacing={2} direction="row">
                <Button
                  variant="contained"
                  className="primary-button"
                  disabled={isLoading}
                  onClick={formik.handleSubmit}
                >
                  {isLoading && (
                    <CircularProgress
                      size="1rem"
                      sx={{ mr: 1, zIndex: 1, position: "absolute" }}
                    />
                  )}
                  Save
                </Button>
                <Button
                  variant="outlined"
                  className="secondary-button"
                  disabled={isLoading}
                  onClick={handleCloseDrawer}
                >
                  Cancel
                </Button>
              </Stack>
            </Box>
          )}
        </DialogActions>
      </CustomizedDialog>
    </>
  );
};

export default CreateConfigureAction;
