import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import {
  Button,
  DialogContent,
  Stepper,
  Step,
  StepLabel,
  TextField,
  Select,
  Box,
  Typography,
  MenuItem,
  Stack,
  DialogActions,
} from "@mui/material";
import {
  addIntegrationThunk,
  authURLThunk,
} from "../../../../redux/slices/knowledgeBase.slice";
import { setSnackbar } from "../../../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import "../../../../styles/intigration.scss";
import CustomizedDialog from "../../Dialog";

const CreateIntegration = ({
  editOpen,
  setEditOpen,
  fetchAllConfiguration,
}) => {
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useDispatch();
  const steps = ["Define Integration", "Define Parameters"];
  const [searchParams, setSearchParams] = useSearchParams();

  const handleFinish = async (payload) => {
    if (!payload.name || !payload.type) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Name and type of integration are needed",
        })
      );
      return;
    }
    if (activeStep === 0) {
      localStorage.setItem("integration", JSON.stringify(payload));
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 1) {
      let response = await dispatch(addIntegrationThunk(payload));
      if (response.payload && response.payload.status) {
        dispatch(
          setSnackbar({
            open: true,
            severity: "success",
            message: "Successfully Added",
          })
        );
        localStorage.removeItem("integration");
        fetchAllConfiguration();
        handleClose();
        setActiveStep(0);
      } else {
        dispatch(
          setSnackbar({
            open: true,
            severity: "error",
            message: response.payload.message || "Internal server error",
          })
        );
      }
    }
  };

  const ScrollBars = {
    "&::-webkit-scrollbar": {
      width: " 4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#cccccc",
    },
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      type: "",
      token: undefined,
    },
    onSubmit: handleFinish,
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get("code");

    if (accessToken && JSON.parse(localStorage.getItem("integration"))) {
      setActiveStep(1);
      // Call your method here with the access token
      let integrationValue = JSON.parse(localStorage.getItem("integration"));
      formik.setValues({ ...integrationValue, token: accessToken });
    }
    // eslint-disable-next-line
  }, []);

  const handleBack = (values) => {
    if (activeStep === 1 && values.token) {
      handleClose();
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleConnect = async (values) => {
    if (!values.name || !values.type) {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: "Name and type of integration are needed",
        })
      );
    }
    let response = await dispatch(
      authURLThunk({ name: values.name, type: values.type })
    );
    if (response.payload && response.payload.status) {
      window.location.href = response.payload.data;
    } else {
      dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response.payload.message || "Internal server error",
        })
      );
    }
  };

  const handleClose = () => {
    if (activeStep === 1) {
      searchParams.delete("code");
      searchParams.delete("state");
      searchParams.delete("scope");
      setSearchParams(searchParams);
      localStorage.removeItem("integration");
      formik.resetForm();
      setActiveStep(0);
    }
    setEditOpen(false);
    setActiveStep(0);
  };

  return (
    <CustomizedDialog
      className="modal-scroll"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={"Add Integration"}
      open={editOpen}
      handleClose={() => {
        handleClose();
      }}
    >
      <DialogContent sx={ScrollBars}>
        <form>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step className="active-steps" key={label}>
                <StepLabel className="lable-step">
                  <div
                    className={`step-lable-numbers ${
                      activeStep === index
                        ? "step-lable-numbers-active"
                        : "step-lable-numbers-inactive"
                    }`}
                  >
                    <Typography
                      fontWeight="600"
                      fontSize="16px"
                      className={`${
                        activeStep === index
                          ? "step-lable-label-active"
                          : "step-lable-label-inactive"
                      }`}
                    >
                      step
                    </Typography>
                    <Typography
                      fontWeight="600"
                      className={`${
                        activeStep === index
                          ? "step-lable-label-active"
                          : "step-lable-label-inactive"
                      }`}
                    >
                      {index + 1}
                    </Typography>
                  </div>
                  <Typography
                    fontSize="14px"
                    className={`${
                      activeStep === index
                        ? "step-lable-label-active"
                        : "step-lable-label-inactive"
                    }`}
                    variant="h6"
                  >
                    {label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <Stack sx={{ gap: "10px" }}>
              <>
                <Typography variant="body1" component="label">
                  Name
                </Typography>
                <TextField
                  id="Name"
                  label=""
                  variant="outlined"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  fullWidth
                  placeholder="Name"
                />
              </>
              <>
                <Typography variant="body1" component="label">
                  Description
                </Typography>
                <TextField
                  id="description"
                  label=""
                  variant="outlined"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  fullWidth
                  placeholder="Description"
                />
              </>
              <Stack>
                <Typography variant="body1" component="label">
                  Type
                </Typography>
                <Select
                  id="type"
                  labelId="demo-simple-select-label"
                  value={formik.values.type}
                  label=""
                  placeholder={"Type"}
                  onChange={(event) =>
                    formik.setFieldValue("type", event.target.value)
                  }
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="Google drive" key="1">
                    Google Drive
                  </MenuItem>
                  <MenuItem value="One drive" key="2">
                    One Drive
                  </MenuItem>
                  <MenuItem value="Notion" key="3">
                    Notion
                  </MenuItem>
                </Select>
              </Stack>
            </Stack>
          ) : (
            <Box display="flex" justifyContent="center" mt={2}>
              <Button
                onClick={() => {
                  handleConnect(formik.values);
                }}
                variant={formik.values.token ? "contained" : "outlined"}
                disabled={formik.values.token}
                size="large"
              >
                {formik.values.token ? "Connected" : "connect"}
              </Button>
            </Box>
          )}
        </form>
      </DialogContent>
      <DialogActions>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
          }}
          className="modal-footers"
        >
          <Stack spacing={2} direction="row">
            <Button
              className="secondary-button"
              variant="outlined"
              disabled={activeStep === 0}
              onClick={() => {
                handleBack(formik?.values);
              }}
            >
              {activeStep === steps.length - 1 && formik?.values?.token
                ? "Cancel"
                : "Back"}
            </Button>
            <Button
              onClick={formik.handleSubmit}
              className={`primary-button  ${
                activeStep === steps.length - 1 ? "disabled-button" : ""
              }`}
              disabled={
                activeStep === steps.length - 1 ? !formik.values.token : false
              }
            >
              {activeStep === steps.length - 1 ? "Finish" : "Next"}
            </Button>
          </Stack>
        </Box>
      </DialogActions>
    </CustomizedDialog>
  );
};

export default CreateIntegration;
