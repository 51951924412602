import React from "react";
import CustomizedDialog from "../Dialog";
import { Box, DialogContent } from "@mui/material";

const ScrollBars = {
  "&::-webkit-scrollbar": {
    width: " 4px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#cccccc",
  },
};

const ActionSelection = ({
  setIsOpenDrawer,
  isOpenDrawer,
  defaultActionList,
  setIsExpanded,
  selectedButtonIndex,
  setActions,
  formik,
  kbFormik,
  setWorkflowHistory,
}) => {
  const handleCloseDrawer = () => {
    setIsOpenDrawer(false);
  };

  const handleActionSelect = (id, selectedActions) => {
    setIsExpanded(true);
    setIsOpenDrawer(false);
    const newValue = [...formik.values.configureActions];

    const newData = {
      id: formik.values.configureActions.length + 1,
      configureAction: selectedActions,
      IsLocal: true,
    };

    newValue.splice(selectedButtonIndex + 1, 0, newData);
    kbFormik.setFieldValue("kb", {});
    setActions({
      type:
        selectedActions === "Extract action"
          ? "Extract"
          : selectedActions === "Criteria Check"
          ? "Criteria Check"
          : "Extraction from CSV file",
      id: formik.values.configureActions.length + 1,
    });
    formik.setFieldValue("configureActions", newValue);
    setWorkflowHistory(false);
  };

  return (
    <CustomizedDialog
      className="modal-scroll"
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      title={"All Actions"}
      open={isOpenDrawer}
      handleClose={() => {
        handleCloseDrawer();
      }}
    >
      <DialogContent sx={ScrollBars}>
        {defaultActionList?.map((actions, i) => {
          return (
            <Box
              key={i + 1}
              className="all-actions-modal"
              id={actions.id}
              onClick={() => handleActionSelect(actions.id, actions.name)}
            >
              {actions.name}
            </Box>
          );
        })}
      </DialogContent>
    </CustomizedDialog>
  );
};

export default ActionSelection;
