import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const RouteProtection = ({ children, pageAccess }) => {
  let isAutheticated;
  try {
    const user = useSelector((state) => state.user);
    // let verifyCurrentUser;
    // (async () => {
    //   verifyCurrentUser = await dispatch(currentUserDetailsThunk());
    // })();
    if (user?.data && user?.data?.access_token) {
      isAutheticated = true;
    } else {
      isAutheticated = false;
    }
  } catch (error) {
    isAutheticated = false;
  }

  return (
    <React.Fragment>
      {isAutheticated ? children : <Navigate to={"/login"} />}
    </React.Fragment>
  );
};

export default RouteProtection;
