import { API_URL } from "../../constants/apiUrl.constant";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const getAllworkflowThunk = createAsyncThunk(
  "Allworkflow",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ALL_WORKFLOW}?version=1` +
          (payload.status !== "all" ? `&status=${payload.status}` : ""),
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response?.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const createWorkflowThunk = createAsyncThunk(
  "createworkflow",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_WORKFLOW}`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getOneWorkflowThunk = createAsyncThunk(
  "getOneWorkflow",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ONE_WORKFLOW}/${id}/`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateWorkflowThunk = createAsyncThunk(
  "updateWorkflow",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_WORKFLOW}/${payload.id}/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const statusUpdatethunkWorkflow = createAsyncThunk(
  "statusUpdateWorkflow",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.WORKFLOW_STATUS_UPDATE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const saveWorkflowThunk = createAsyncThunk(
  "saveWorkflow",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.SAVE_WORKFLOW_RESPONSE}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchAllWorkflowKBfileThunk = createAsyncThunk(
  "fetchAllWorkflowKB",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.WORKFLOW_KB_FILE_LIST}?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchWorkflowTemplateThunk = createAsyncThunk(
  "fetchWorkflowTemplate",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.RUN_WORKFLOW_TEMPLATE_LIST}?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const fetchAllWorkTemplateThunk = createAsyncThunk(
  "AllWorkTemplate",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.WORKFLOW_TEMPLATE_LIST}?id=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getDefaultActionThunk = createAsyncThunk(
  "getDefaultAction",
  async (id) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_DEFAULT_WORKFLOW_ACTION}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getActionSelectionThunk = createAsyncThunk(
  "getActionSelection",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_SELECT_ACTION_LIST}?action=${payload}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const creatlatesteWorkflowThunk = createAsyncThunk(
  "createworkflow",
  async (page) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.CREATE_WORKFLOW}?version=1`,
        page,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const LatestRunWorkflowThunk = createAsyncThunk(
  "LatestRunWorkflow",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.RUN_WORKFLOW_LATEST}/${payload}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );

      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getParticularWorkflowThunk = createAsyncThunk(
  "getParticularWorkflow",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_ONE_WORKFLOW}/${payload}/?version=1`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const getWorkflowRunHistoryThunk = createAsyncThunk(
  "getWorkflowRunHistory",
  async (payload) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}${API_URL.GET_WORKFLOW_RUN_HISTORY}/${payload}`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const LatestStoreWorkflowThunk = createAsyncThunk(
  "LatestStoreWorkflow",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.STORE_WORKFLOW_ACTION}/${payload.id}`,
        payload.values,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const removeActionsThunk = createAsyncThunk(
  "removeActions",
  async (payload) => {
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_API_URL}${API_URL.DELETE_PARTICULAR_ACTION}/${payload}?version=1`,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const updateLatestWorkflowThunk = createAsyncThunk(
  "updateLatestWorkflow",
  async (payload) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}${API_URL.UPDATE_WORKFLOW}/${payload.id}/?version=1` +
          (payload.is_run ? `&is_run=true` : ""),
        payload.values,
        {
          headers: {
            Authorization: `Bearer ${
              JSON.parse(localStorage?.getItem("user")).access_token
            }`,
          },
        }
      );
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  }
);

// Workflow slice
const workflowSlice = createSlice({
  name: "workflow",
  initialState: {
    isLoading: false,
    data: null,
    isError: false,
    error: null,
  },
  extraReducers: (builder) => {},
});

export default workflowSlice.reducer;
