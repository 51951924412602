import { useEffect } from "react";
import {
  userActivityThunk,
  setSnackbar,
} from "../../redux/slices/common.slice";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const UserActivity = () => {
  let location = useLocation();
  const dispatch = useDispatch();

  const FetchDeviceIP = async () => {
    const responseIP = await fetch("https://api.ipify.org?format=json").then(
      (response) => response.json()
    );
    let response = await dispatch(
      userActivityThunk({
        ip_address: responseIP.ip,
        page: location.pathname.split("/").slice(-1).toString(),
      })
    );
    if (!(response.payload && response.payload.status)) {
      await dispatch(
        setSnackbar({
          open: true,
          severity: "error",
          message: response?.payload?.message || "Internal server error",
        })
      );
    }
  };

  useEffect(() => {
    if (
      location.pathname !== "/" &&
      location.pathname !== "/login" &&
      location.pathname !== "/forgot-password" &&
      location.pathname !== "/new-password" &&
      location.pathname !== "/pricing" &&
      location.pathname !== "/subscription"
    ) {
      FetchDeviceIP();
    }
    return;
    // eslint-disable-next-line
  }, [location]);

  return null;
};

export default UserActivity;
