export const REPORT_OPTION = [
    { label: "All Report", value: "all" },
    { label: "Hazard", value: "hazard" },
    { label: "Incident", value: "incident" },
    { label: "Maintenance", value: "maintenance" },
    { label: "General observation", value: "general_observation" },
    { label: "Near Miss", value: "near_miss" },
];

export const CLASSIFICATION_OPTION = {
    death: "Death",
    days_away_from_work: "Days away from work",
    job_transfer_or_restriction: "Job transfer or restriction",
    other_recordable_case: "Other recordable case",
};

export const INJURY_OPTION = {
    injury: "Injury",
    skin_disorder_skin_disorder: "Skin disorder Skin disorder",
    respiratory_condition: "Respiratory condition",
    poisoning: "Poisoning",
    hearing_loss_hearing_loss: "Hearing loss Hearing loss",
    all_other_illness: "All other illness",
};