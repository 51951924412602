import React from "react";
import { Box } from "@mui/material";

const PrivacyPolicy = () => {
  return (
    <Box className="privacy-policy container pt-5">
      <h2>Introduction</h2>
      <p>Welcome to PrismViewAI Inc.</p>
      <p>
        PrismViewAI Inc. ("we," "us," or "our company") manages the website
        found at:
        <a href="https://prismview.ai"> https://prismview.ai </a>, along with
        our exclusive PrismViewAI software accessible via the Site (called the
        "Service"). Together, the Site and the Service are known as the
        "Services."
      </p>
      <br />
      <p>
        This Privacy Policy outlines our practices for gathering, using,
        protecting, and sharing information when you utilize the Services. By
        accessing and using the Services, you consent to our collection and use
        of information as described in this Privacy Policy. Any terms not
        defined in this Privacy Policy will have the meanings ascribed to them
        in our Terms and Conditions.
      </p>
      <br />
      <h3>Information Collection and Use</h3>
      <p>
        Gathering and Utilizing Information To deliver and improve our Services,
        we collect a variety of information types tailored to these purposes.
      </p>
      <br />
      <p>
        Information Collection Personal Information As you engage with our
        Services, we might request specific identifiable information from you,
        which can be used for contact or identification purposes ("Personal
        Information"). This information could encompass, but is not limited to:
      </p>
      <ul>
        <li>Your email address</li>
        <li>Your first and last names</li>
        <li>Cookies and information on your usage of the Services</li>
      </ul>
      <p>
        Your Personal Information may be used by us to send newsletters,
        marketing communications, promotional materials, and information that
        you might find interesting. Should you choose not to receive any or all
        of these communications from us, you can opt-out at any time either by
        clicking on the unsubscribe link provided in our emails or by getting in
        touch with us at support@prismview.ai. Collection of Usage Information
        We gather data sent by your browser or mobile device when you access our
        Services, referred to as "Usage Information."
      </p>
      <br />
      <p>
        This Usage Information might cover details like your computer's or
        mobile device's Internet Protocol (IP) address, the type of browser,
        browser version, the specific pages of our Services you have visited,
        the date and time of your visit, the amount of time spent on those
        pages, and various diagnostic information. For mobile device access, it
        additionally includes the device type, unique device ID, mobile device's
        IP address, mobile operating system, the mobile browser used, and
        similar diagnostic details.
      </p>
      <br />
      <p>
        Tracking Technologies and Cookies To monitor activities on our Services
        and retain certain types of information, we utilize cookies and
        analogous tracking technologies.
      </p>
      <br />
      <p>
        Cookies are small data files that may carry an anonymous unique
        identifier and are sent from a website to your browser, then stored on
        your device. We also employ other tracking technologies like beacons,
        tags, and scripts to gather and track information, enhancing and
        analyzing our Services.
      </p>
      <br />
      <p>
        You have the control to set your browser to block all cookies or to
        signal when a cookie is set. Note, however, that some parts of the
        Services might not function properly if you disable cookies.
      </p>
      <br />
      <p>Types of Cookies Utilized:</p>
      <ul>
        <li>
          <b>Session Cookies:</b> Essential for operating our Services.
        </li>
        <li>
          <b>Preference Cookies:</b> Save your settings and preferences.
        </li>
        <li>
          <b>Security Cookies:</b> Crucial for ensuring the security of our
          Services.
        </li>
        <li>
          <b>Advertising Cookies:</b> Selected to present advertisements
          relevant to your interests.
        </li>
      </ul>
      <br />
      <h6>
        Data Usage by the Company The company utilizes the data it collects for
        a multitude of purposes:
      </h6>
      <ul>
        <li>Offering and maintaining the Services;</li>
        <li>Informing you of updates to the Services;</li>
        <li>
          Enabling your engagement with interactive features of the Services at
          your discretion;
        </li>
        <li>Delivering customer service;</li>
        <li>Collecting insights to enhance the Services;</li>
        <li>Tracking the Services' utilization;</li>
        <li>Identifying and resolving technical problems;</li>
        <li>
          Serving any specific purposes at the time you provided the
          information;
        </li>
        <li>
          Executing our duties and exercising our rights under contracts made
          with you, which includes billing and collections;
        </li>
        <li>
          Sending notices related to your account or subscription, such as
          expiration and renewal alerts, and email instructions;
        </li>
        <li>
          Sharing news, promotions, and information about our goods, services,
          and events similar to those you've previously engaged with, provided
          you haven't opted out of receiving such communications;
        </li>
        <li>
          Any other purposes disclosed when the information was submitted;
        </li>
        <li>With your agreement, for any additional purposes.</li>
      </ul>
      <br />
      <p>
        Data Sharing Practices at Our Company Our commitment at the Company is
        to protect and handle your personal information with the utmost care. In
        specific scenarios, we might share the personal information you've
        provided or that we've collected, including:
      </p>
      <br />
      <p>
        Third-Party Service Providers To support our service delivery,
        administration, and business operations, we share personal information
        with external service providers. These third parties assist us in
        various aspects such as managing and improving the Services.
      </p>
      <br />
      <p>
        Google Integration Our application uses Google User Data solely to
        enhance its functionality. For example, we integrate with Google Drive
        to synchronize Google Docs, Google Slides, PDFs, and plain text files
        from selected folders for a better user experience with PrismViewAI. You
        retain the freedom to modify your folder selections or request the
        deletion of your data at any time. Google Workspace APIs are not used to
        develop, improve, or train generalized AI and/or ML models
      </p>
      <br />
      <p>
        Information for Users Outside the U.S. Our operations and data storage
        are primarily based in the United States, meaning personal data
        collected is processed and stored on U.S. servers. For users accessing
        our Services from outside the U.S., be aware that your information will
        be transferred to and processed in the U.S. and possibly other countries
        with different privacy regulations. By using our Services, you consent
        to this transfer and processing of information.
      </p>
      <br />
      <p>
        Legal and Law Enforcement Requirements We may need to disclose your
        Personal Data to comply with legal obligations or respond to lawful
        requests from public authorities.
      </p>
      <br />
      <p>
        Business Changes In the event of a sale, merger, acquisition, or
        transfer of our business assets, personal data may be shared or
        transferred as part of the transaction. Should our company undergo such
        changes, the new proprietors will adhere to the same privacy practices
        as detailed in this document.
      </p>
      <br />
      <p>
        Internal Sharing Within Affiliates Your personal information might be
        accessed by employees, contractors, and agents within our Company and
        our affiliates for the purpose of delivering and enhancing the Services.
        We ensure that all parties involved uphold the privacy and security of
        your data in line with this policy.
      </p>
      <br />
      <p>
        Other Circumstances Your information may be disclosed for specific
        reasons you've provided it for, such as responding to your requests or
        inquiries. With your permission, we may also display your company's logo
        on our website or use your data for any other agreed-upon purpose
      </p>
      <br />
      <p>
        Payment Information and Procedures When you purchase our services or
        products, third-party payment services like Stripe or others we may
        adopt in the future are used to process payments. These services operate
        under their distinct privacy policies, which you should examine
        carefully before entering your payment details.
      </p>
      <br />
      <p>
        It's important to note that your payment card information is not held or
        collected by us but is directly managed by our chosen third-party
        payment processors in compliance with their privacy policies, adhering
        to the PCI-DSS guidelines established by the PCI Security Standards
        Council.
      </p>
      <br />
      <p>
        Marketing Communications and Your Choices With your explicit consent (as
        required by applicable laws) or unless you have chosen to opt out, we
        are allowed to use the personal details you provided to send promotional
        content regarding our company, updates on services, announcements of new
        products or features, and similar information we believe may interest
        you via email.
      </p>
      <br />
      <p>
        To stop receiving our marketing emails, you can easily unsubscribe by
        clicking the provided link at the bottom of the emails. To opt out of
        receiving marketing materials via postal mail, please send your request
        to support@prismview.ai.
      </p>
      <br />
      <p>
        We aim to process any requests to opt out swiftly. However, this does
        not extend to essential transactional communications related to the
        services, such as support requests, which are not considered marketing
        communications.
      </p>
      <br />
      <p>
        Links to Third-Party Sites and Security Practices Our Services may
        contain links to thirdparty websites over which we have no control. We
        encourage you to read the privacy policies of any third-party sites you
        visit, as we cannot be responsible for their practices or content.
      </p>
      <br />
      <p>
        To safeguard your personal information, we employ appropriate security
        measures, including encryption, SSL technology, and password protection,
        aimed at preventing unauthorized access, modification, or disclosure.
        Access to your information is limited to those with a legitimate
        business need.
      </p>
      <br />
      <p>
        We retain your personal data as long as necessary for the purposes
        outlined in this Privacy Policy, to comply with our legal obligations,
        resolve disputes, and enforce our agreements. Usage data is kept for
        internal purposes for a limited period, unless it is necessary to
        enhance security, service functionality, or comply with legal
        requirements.
      </p>
      <br />
      <p>
        Updates to the Privacy Policy We reserve the right to amend this Privacy
        Policy at any time. Changes will be effective immediately upon posting
        for non-material updates, while significant changes will be announced
        via email and on our website before they take effect. By continuing to
        use the Services after these changes, you accept the revised Privacy
        Policy.
      </p>
      <br />
      <p>
        For any inquiries about this Privacy Policy, feel free to reach out to
        us at support@prismview.ai.
      </p>
    </Box>
  );
};

export default PrivacyPolicy;
